import React from 'react';
import { baseURL } from 'config/api.js';
import { useState } from 'react';
//import './workingHoursNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import DatePicker from "react-datepicker";

export const WorkingHoursNew = () => {
  const [message, setMessage] = useState(null);

  const [shiftTitle, setShiftTitle] = useState("");
  const [openingTime, setOpeningTime] = useState(new Date());
  const [closingTime, setClosingTime] = useState(new Date());

  const [lateTracking, setLateTracking] = useState(false);
  const [onTime, setOnTime] = useState(new Date());
  const [halfDay, setHalfDay] = useState(new Date());
  const [offDay, setOffDay] = useState(new Date());

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/workinghours/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateWorkingHoursForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!shiftTitle) {
      errors.push(<div>Working shift title cannot be empty</div>);
    }

    const openingHourInt = openingTime.getHours();
    const openingMinInt = openingTime.getMinutes();

    const closingHourInt = closingTime.getHours();
    const closingMinInt = closingTime.getMinutes();

    if (closingTime.getTime() <= openingTime.getTime()) {
      errors.push(<div>Shift ending time can't be earlier than starting time.</div>);
    }

    let onTimeHourInt, onTimeMinInt, halfDayHourInt, halfDayMinInt, offDayHourInt, offDayMinInt;
    if (lateTracking) {
      onTimeHourInt = onTime.getHours();
      onTimeMinInt = onTime.getMinutes();

      halfDayHourInt = halfDay.getHours();
      halfDayMinInt = halfDay.getMinutes();

      offDayHourInt = offDay.getHours();
      offDayMinInt = offDay.getMinutes();

      if (onTime.getTime() <= openingTime.getTime()) {
        errors.push(<div>On time can't be earlier than starting time.</div>);
      }
      if (
        (halfDay.getTime() <= openingTime.getTime()) ||
        (halfDay.getTime() <= onTime.getTime())
      ) {
        errors.push(<div>Half day time can't be earlier than either starting time or on time.</div>);
      }
      if (
        (offDay.getTime() <= openingTime.getTime()) ||
        (offDay.getTime() <= onTime.getTime()) ||
        (offDay.getTime() <= halfDay.getTime())
      ) {
        errors.push(<div>Off day time can't be earlier than either starting time or on time or half day time.</div>);
      }
    }

    let payload = {
      shiftTitle,
      isLatePolicy: lateTracking,
      shiftStart: { hour: openingHourInt, minute: openingMinInt },
      shiftEnd: { hour: closingHourInt, minute: closingMinInt },
    };

    if (lateTracking) {
      payload = {
        ...payload,
        onTime: { hour: onTimeHourInt, minute: onTimeMinInt },
        halfDay: { hour: halfDayHourInt, minute: halfDayMinInt },
        offDay: { hour: offDayHourInt, minute: offDayMinInt },
      }
    }

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  console.log("Before WorkingHoursNew() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create office timings</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form onSubmit={handleCreateWorkingHoursForm}>

          <div className='rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Working Shift Title <span className='required'>*</span>
                  </label>
                </div>
                <div>
                  <input
                    name="shiftTitle"
                    type="text"
                    value={shiftTitle}
                    onChange={(e) => setShiftTitle(e.target.value)}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Starting Time <span className='required'>*</span></label>
                </div>
                <div>
                  <DatePicker
                    selected={openingTime}
                    onChange={(date) => setOpeningTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Ending Time <span className='required'>*</span></label>
                </div>
                <div>
                  <DatePicker
                    selected={closingTime}
                    onChange={(date) => setClosingTime(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeIntervals={15}
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div className='w-full md:w-11/12'>
                  <input
                    type="checkbox"
                    name="lateTracking"
                    onChange={(e) => setLateTracking(e.target.checked)}
                  />{" "}
                  <label className='font-normal'>
                    Implement Late Coming Policy <span className='required'>*</span>
                  </label>
                </div>
              </div>

              {
                lateTracking && (
                  <React.Fragment>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>
                          On time until <span className='required'>*</span>
                        </label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={onTime}
                          onChange={(date) => setOnTime(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>
                          Half day after <span className='required'>*</span>
                        </label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={halfDay}
                          onChange={(date) => setHalfDay(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>
                    </div>

                    <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                      <div>
                        <label className='font-normal'>
                          Off day after <span className='required'>*</span>
                        </label>
                      </div>
                      <div className='w-full md:w-11/12'>
                        <DatePicker
                          selected={offDay}
                          onChange={(date) => setOffDay(date)}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={5}
                          timeCaption="Time"
                          dateFormat="h:mm aa"
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )
              }

            </div>

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div>
    </Layout>
  );
}