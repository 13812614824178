import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Pagination from 'components/global/pagination';
//import './stockCategories.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

export const StockCategories = () => {
  const [message, setMessage] = useState(null);
  const [stocksCategories, setstocksCategories] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userData } = useContext(AuthContext);

  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    setIsLoading(true);

    let url = `${baseURL}/stockcategories?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();

    const stocksCategories = jsonData.data.docs;
    setstocksCategories(stocksCategories);
    setTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("stockcategories")
    ) &&
    <Link to="/stockcategories/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>New Stock Category</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Stock Categories</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let stocksCategoriesList = [];

  if (stocksCategories && stocksCategories.length !== 0) {
    stocksCategoriesList = stocksCategories.map((projectCategory, index) => {
      let editLink = "/stockcategories/edit/" + projectCategory._id;
      return (
        <tr key={projectCategory._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{index + 1}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{projectCategory.stockCategoryName}</td>
          <td className="px-7 py-4 whitespace-nowrap"><Link to={editLink}><CiEdit className='text-2xl text-primary' /></Link></td>
          <td className="text-primary px-9 py-4 whitespace-nowrap ">
            <button onClick={handleDelete}><RiDeleteBin6Line className='text-lg text-[#E30411]' /></button>
          </td>
        </tr>
      );
    });
  }

  console.log("Before stocksCategories() rendering");

  return (
    <Layout>

      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Stock Categories</h2>
          {
            NewDocumentLink
          }
        </div>

        <div className='flex flex-row justify-between'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto  rounded-lg  border border-[#6843BE] ' >

          <table className="w-full table-auto overflow-x-auto ">
            <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Sr. No.</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Stock Category</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {stocksCategoriesList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='12'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

      </div>
    </Layout>

  );
}
