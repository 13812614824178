import React from 'react';
//import './dashboardSuperAdmin.css';
import Layout from 'components/global/Layout';
import { MainNumbersCards } from 'components/mainNumbersCards/MainNumbersCards.jsx';
import { VendorsTableMini } from 'components/vendorsTableMini/VendorsTableMini.jsx';
import { AbsencesGraph } from 'components/absencesGraph/AbsencesGraph.jsx';
import { TaskStatistics } from 'components/taskStatistics/TaskStatistics';
import { SalariesChart } from 'components/salariesChart/SalariesChart';
import { ProjectsDemandsGraphs } from 'components/projectsDemandsGraphs/ProjectsDemandsGraphs';
import { POPaymentVouchersGraphs } from 'components/poPaymentVouchersGraphs/POPaymentVouchersGraphs';
import { LowStockTablesMini } from 'components/lowStockTablesMini/LowStockTablesMini';
import { ComplaintsLineChart } from 'components/complaintsLineChart/ComplaintsLineChart';

export const DashboardSuperAdmin = () => {

  console.log("Before DashboardSuperAdmin() rendering");

  return (
    <Layout isDashboard={true}>
      <div className="space-y-4">
        <h2 className='text-2xl text-black font-bold'>Dashboard</h2>
        <div>Welcome Super Admin!</div>
        <MainNumbersCards />

        <div className="flex flex-row flex-wrap content-stretch">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <VendorsTableMini />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <AbsencesGraph />
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <TaskStatistics />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <SalariesChart />
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <ProjectsDemandsGraphs />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <POPaymentVouchersGraphs />
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <LowStockTablesMini />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <ComplaintsLineChart />
          </div>
        </div>

      </div>
    </Layout>
  );
}
