import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './vendorsEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const VendorsEdit = () => {
  const [message, setMessage] = useState(null);
  const [vendor, setVendor] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let getVendorURL = baseURL + "/vendors/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getVendorResponse = await fetch(getVendorURL, {
      method: "GET",
      headers: headers
    });
    const getVendorJSONData = await getVendorResponse.json();
    if (!getVendorResponse.ok) {
      toast.error(getVendorJSONData.data.message);
      setMessage(getVendorJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const vendor = getVendorJSONData.data.doc;

    setVendor(vendor);
    setIsLoading(false)
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/vendors/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditVendorForm(event) {
    event.preventDefault();

    setIsSubmitting(true);
    setIsDisabled(true);

    let errors = [];
    let editVendorForm = event.target;
    let vendorCompanyName = editVendorForm.elements.namedItem("vendorCompanyName").value;
    let vendorEmail = editVendorForm.elements.namedItem("vendorEmail").value;
    let vendorAddress = editVendorForm.elements.namedItem("vendorAddress").value;
    let vendorPhone = editVendorForm.elements.namedItem("vendorPhone").value;
    let focalPersonName = editVendorForm.elements.namedItem("focalPersonName").value;
    let focalPersonPhone = editVendorForm.elements.namedItem("focalPersonPhone").value;

    if (!vendorCompanyName) {
      errors.push(<div>Company name is required</div>)
    }

    if (!vendorAddress) {
      errors.push(<div>Address is required</div>)
    }

    if (!vendorPhone) {
      errors.push(<div>Vendor phone is required</div>)
    }

    let payload = {
      vendorCompanyName,
      vendorEmail,
      vendorAddress,
      vendorPhone,
      focalPersonName,
      focalPersonPhone
    };

    if (errors.length > 0) {
      setMessage(errors);
      toast.error("Please fix the errors in the form.");
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit vendor</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before VendorsEdit() rendering");

  return (
    <Layout>
      <div className='space-y-4 text-sm'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit vendor</h2>
        </div>

        <div className='flex flex-row'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-primary p-8'>

          <form id="editVendorForm" onSubmit={handleEditVendorForm}>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label>Vendor Company Name <span className='required'>*</span></label>
                <input
                  type="text"
                  name="vendorCompanyName"
                  defaultValue={vendor.vendorCompanyName}
                  className='md:w-11/12 border border-[#8E8EA1] py-1 rounded-[10px] px-3 outline-none'
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label>Email</label>
                <input
                  type="text"
                  name="vendorEmail"
                  defaultValue={vendor.vendorEmail}
                  className='md:w-11/12 border border-[#8E8EA1] py-1 rounded-[10px] px-3 outline-none'
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label>Address <span className='required'>*</span></label>
                <input
                  type="text"
                  name="vendorAddress"
                  defaultValue={vendor.vendorAddress}
                  className='md:w-11/12 border border-[#8E8EA1] py-1 rounded-[10px] px-3 outline-none'
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label>Phone <span className='required'>*</span></label>
                <input
                  type="text"
                  name="vendorPhone"
                  defaultValue={vendor.vendorPhone}
                  className='md:w-11/12 border border-[#8E8EA1] py-1 rounded-[10px] px-3 outline-none'
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label>Focal Person Name</label>
                <input
                  type="text"
                  name="focalPersonName"
                  defaultValue={vendor.focalPersonName}
                  className='md:w-11/12 border border-[#8E8EA1] py-1 rounded-[10px] px-3 outline-none'
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label>Focal Person Phone</label>
                <input
                  type="text"
                  name="focalPersonPhone"
                  defaultValue={vendor.focalPersonPhone}
                  className='md:w-11/12 border border-[#8E8EA1] py-1 rounded-[10px] px-3 outline-none'
                />
              </div>

            </div>

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </form>

        </div>
      </div>
    </Layout>

  )
}
