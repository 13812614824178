import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const MeetingsToday = () => {
  const [events, setEvents] = useState(null);
  const [eventsCount, setEventsCount] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getEventsURL = baseURL + `/events/meetings-today?employeeID=${userData.doc.employee_id._id}`;
      const getEventsResponse = await fetch(getEventsURL, {
        method: "GET",
        headers: headers
      });
      const getEventsJSONData = await getEventsResponse.json();
      if (!getEventsResponse.ok) {
        toast.error(getEventsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      setEvents(getEventsJSONData.data.docs);
      setEventsCount(getEventsJSONData.data.docsCount);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='h-40 p-4 mb-4 xl:mb-0 rounded-md shadow-md text-white bg-gradient-to-r from-[#F2A359] to-[#ff7b01]'>
        <div className='font-extrabold text-lg'>Welcome {userData.doc.employee_id?.full_name}!</div>
        <ClipLoader size={15} color='#fff' />
      </div>
    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='h-40 p-4 mb-4 xl:mb-0 rounded-md shadow-md text-white bg-gradient-to-r from-[#F2A359] to-[#ff7b01]'>
        <div>An error occurred.</div>
      </div>
    );
  }

  return (
    <div className='h-40 p-4 mb-4 xl:mb-0 rounded-md shadow-md text-white bg-gradient-to-r from-[#F2A359] to-[#ff7b01]'>
      <div className='font-extrabold text-lg'>Welcome {userData.doc.employee_id?.full_name}!</div>
      <div>You have {eventsCount} meetings today</div>
    </div>
  );
}