import React, { useState, useEffect } from 'react';
import { baseURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { ClipLoader } from 'react-spinners';
import { BsX } from "react-icons/bs";
import { BsFloppy } from "react-icons/bs";

export const EventsEditModal = ({ eventStoredInDB, closeModal, events, setEvents }) => {
  let peopleIDs = []

  for (let obj of eventStoredInDB.people) {
    peopleIDs.push(obj._id);
  }

  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);

  const [title, setTitle] = useState(eventStoredInDB.title);
  const [people, setPeople] = useState(peopleIDs);
  const [allDay, setAllDay] = useState(eventStoredInDB.allDay);
  const [startDate, setStartDate] = useState(new Date(eventStoredInDB.startDate));
  const [endDate, setEndDate] = useState(new Date(eventStoredInDB.endDate));
  const [status, setStatus] = useState(eventStoredInDB.status);
  const [description, setDescription] = useState(eventStoredInDB.description);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/events/update/" + eventStoredInDB._id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);

      const index = events.findIndex((element) => element._id === eventStoredInDB._id);
      events[index] = jsonData.data.doc;
      setEvents([...events]);

      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditEventForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);

    if (!title) {
      errors.push(<div>Event title cannot be empty</div>);
    }

    if (!status) {
      errors.push(<div>Event status is required</div>);
    }

    if (endDate.getTime() < startDate.getTime()) {
      errors.push(<div>Event start date, time can't be earlier than end date, time.</div>);
    }

    let allDayStart, allDayEnd;

    if (allDay) {
      allDayStart = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
      allDayEnd = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59);
    }
    else {
      if (endDate.getTime() === startDate.getTime()) {
        errors.push(<div>Event start date, time can't be equal to end date, time.</div>);
      }
    }

    let payload = {
      people: people ? people : [],
      title,
      allDay,
      startDate: allDay ? allDayStart : startDate,
      endDate: allDay ? allDayEnd : endDate,
      status,
      description: description ? description : ""
    }

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <div className='text-sm'>
        <div className="flex flex-row justify-between mb-6">
          <div>Edit event</div>
          <button onClick={closeModal}>X</button>
        </div>
        <div className="flex flex-col gap-y-4">
          <div>
            {message && message}
          </div>
        </div>
        {
          isLoading && (
            <div className="text-center mb-6">
              <ClipLoader size={50} color="#000" />
            </div>
          )
        }
      </div>
    );
  }

  let peopleDefaultList = [];

  if (employees && employees.length !== 0 && people.length !== 0) {
    let employeesOptionsList = employees.map((employee, index) => {
      return {
        value: employee._id,
        label: `${employee.full_name} ${employee.custom_id}`
      }
    });

    //peopleDefaultList = employeesOptionsList.filter( option => people.find((el) => option.value === el._id) );
    peopleDefaultList = employeesOptionsList.filter(option => people.includes(option.value));
  }

  return (
    <div className='text-sm'>
      <div className="flex flex-row justify-between mb-6">
        <div>Edit event</div>
        <button onClick={closeModal}>X</button>
      </div>

      <div className="flex flex-col gap-y-4">

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            Title <span className='required'>*</span>
          </div>
          <div className='w-full md:w-8/12'>
            <input
              type="text"
              name="title"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            People
          </div>
          <div className='w-full md:w-8/12'>
            <Select
              isMulti
              name="people"
              defaultValue={peopleDefaultList}
              onChange={(selectedOptions) => {
                let peopleList = [];
                if (selectedOptions && selectedOptions.length > 0) {
                  peopleList = selectedOptions.map((option) => {
                    return option.value; //an ObjectId
                  });
                }
                setPeople(peopleList);
              }}
              options={
                employees && employees.length !== 0 && employees.map((employee, index) => {
                  return {
                    value: employee._id,
                    label: `${employee.full_name} ${employee.custom_id}`
                  }
                })
              }
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  borderColor: '#8E8EA1',
                }),
              }}
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            All day <span className='required'>*</span>
          </div>
          <div className='w-full md:w-8/12'>
            <input
              type="checkbox"
              name="allDay"
              defaultChecked={allDay}
              onChange={(e) => setAllDay(e.target.checked)}
            />
          </div>
        </div>

        {
          allDay ? (
            <React.Fragment>
              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  Start Date <span className='required'>*</span>
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              </div>

              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  End Date <span className='required'>*</span>
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    dateFormat="MMMM d, yyyy"
                  />
                </div>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  Start Date <span className='required'>*</span>
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    showTimeInput
                    timeInputLabel="Time:"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </div>

              <div className='flex flex-row justify-between items-center'>
                <div className='w-full md:w-4/12'>
                  End Date <span className='required'>*</span>
                </div>
                <div className='w-full md:w-8/12'>
                  <DatePicker
                    selected={endDate}
                    onChange={(date) => setEndDate(date)}
                    showTimeInput
                    timeInputLabel="Time:"
                    dateFormat="MMMM d, yyyy h:mm aa"
                  />
                </div>
              </div>
            </React.Fragment>
          )
        }

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            Status <span className='required'>*</span>
          </div>
          <div className='w-full md:w-8/12'>
            <SelectBox
              name="status"
              defaultValue={status}
              options={
                [
                  { value: 'onSchedule', label: 'On Schedule' },
                  { value: 'cancelled', label: 'Cancelled' },
                ].map((type) => {
                  return {
                    value: type.value,
                    label: type.label
                  }
                })
              }
              handleChange={(selectedOption) => setStatus(selectedOption.value)}
            />
          </div>
        </div>

        <div className='flex flex-row justify-between items-center'>
          <div className='w-full md:w-4/12'>
            Description
          </div>
          <div className='w-full md:w-8/12'>
            <textarea
              rows="4"
              name="description"
              value={description}
              onChange={(e) => {
                e.target.value ? setDescription(e.target.value) : setDescription('');
              }}
              className='mb-4 border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
            >
            </textarea>
          </div>
        </div>

      </div>

      <div className="flex flex-row gap-2 justify-end">
        <button
          disabled={isSubmitting}
          onClick={handleEditEventForm}
          className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md bg-primary text-white'
        >
          <BsFloppy />
          {isSubmitting ? <ClipLoader size={14} color="#fff" /> : "Save event"}
        </button>
        <button
          onClick={(e) => closeModal()}
          className='flex flex-row gap-x-2 items-center py-1 px-2 border rounded-md'
        >
          <BsX />
          Close
        </button>
      </div>
    </div>
  );
}