import React from 'react'

export const UserRoundedSVG = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_501)">
        <path d="M12.0001 23C9.67702 23 7.4137 22.2646 5.53437 20.8991C3.65504 19.5338 2.25622 17.6085 1.53838 15.3991C0.820538 13.1899 0.820538 10.8101 1.53838 8.60081C2.25622 6.39154 3.65504 4.46622 5.53437 3.10081C7.4137 1.73541 9.67702 1 12.0001 1C14.323 1 16.5863 1.73541 18.4656 3.10082C20.345 4.46622 21.7438 6.39154 22.4616 8.60082C23.1795 10.8101 23.1795 13.1899 22.4616 15.3991M14.4444 8.33333C14.4444 9.6834 13.35 10.7778 12.0001 10.7778C10.65 10.7778 9.55561 9.6834 9.55561 8.33333C9.55561 6.9833 10.65 5.8889 12.0001 5.8889C13.35 5.8889 14.4444 6.9833 14.4444 8.33333ZM9.81442 13.2222H14.1856C14.5788 13.2222 14.7754 13.2222 14.9512 13.2504C15.5598 13.3479 16.0861 13.6904 16.3809 14.181C16.466 14.3228 16.5283 14.4912 16.6526 14.8281C16.8019 15.2329 16.8767 15.4353 16.8868 15.5985C16.9224 16.1744 16.5074 16.6944 15.8912 16.8459C15.7165 16.8889 15.4804 16.8889 15.008 16.8889H8.99207C8.51965 16.8889 8.28343 16.8889 8.10879 16.8459C7.4926 16.6944 7.0776 16.1744 7.11324 15.5985C7.12335 15.4353 7.19805 15.2329 7.34744 14.8281C7.47177 14.4912 7.53393 14.3228 7.61913 14.181C7.91396 13.6904 8.44021 13.3479 9.04879 13.2504C9.22464 13.2222 9.42117 13.2222 9.81442 13.2222Z" stroke="white" strokeWidth="1.3" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_501">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}