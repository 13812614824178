import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import styles from './projectsCategoriesEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { ClipLoader } from 'react-spinners';

export const StocksCategoriesEdit = () => {
  const [message, setMessage] = useState(null);
  const [stockCategory, setStockCategory] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true)
    const getStockCategoryURL = baseURL + "/stockcategories/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getStockCategoryResponse = await fetch(getStockCategoryURL, {
      method: "GET",
      headers: headers
    });
    const getStockCategoryJSONData = await getStockCategoryResponse.json();
    const pc = getStockCategoryJSONData.data.doc;

    setStockCategory(pc);
    setLoading(false)

  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/stockcategories/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setMessage(jsonData.data.message);
      setIsSubmitting(false);
    }
    else {
      //setMessage("An error occurred.");
      setMessage(jsonData.data.message);
      setIsSubmitting(false);
    }
  }

  async function handleEditStockCategoryForm(event) {
    event.preventDefault();

    setIsSubmitting(true);

    let editStockCategoryForm = event.target;
    let payload = {}; //empty object
    let stockCategoryName = editStockCategoryForm.elements.namedItem("stockCategoryName").value;
    payload.stockCategoryName = stockCategoryName;
    sendAPIRequest(payload);

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Edit Stock Category</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        {loading ? <Loader /> : <form id="editStockCategoryForm" onSubmit={handleEditStockCategoryForm}>
          <div className='flex flex-col space-y-3 w-full md:w-2/5'>
            <label className='text-base font-normal text-left'>Stock Category Name <span className='required'>*</span></label>
            <input
              type="text"
              name="stockCategoryName"
              defaultValue={stockCategory?.stockCategoryName}
              className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
            />
          </div>
          <div className='flex flex-row justify-start my-5'>
            {
              isSubmitting ? (
                <button className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9'>
                  <ClipLoader size={17} color="#fff" />
                </button>

              ) : (
                <input
                  type="submit"
                  name="submit"
                  value="Submit"
                  className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9'
                />
              )
            }

          </div>
        </form>}
      </div>
    </Layout>

  )
}
