import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import PurpleTarget from 'assets/svgs/PurpleTarget.svg';
import YellowTarget from 'assets/svgs/YellowTarget.svg';
import GreenTarget from 'assets/svgs/GreenTarget.svg';
import BrownTarget from 'assets/svgs/BrownTarget.svg';

export const TaskStatistics = () => {
  const [taskStatistics, setTaskStatistics] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getTaskStatisticsURL = baseURL + "/reports/taskStatistics";
      const getTaskStatisticsResponse = await fetch(getTaskStatisticsURL, {
        method: "GET",
        headers: headers
      });
      const getTaskStatisticsJSONData = await getTaskStatisticsResponse.json();
      if (!getTaskStatisticsResponse.ok) {
        toast.error(getTaskStatisticsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      setTaskStatistics(getTaskStatisticsJSONData.data.doc);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Task Statistics
          </div>

          <div className='text-sm font-semibold'>
            
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Task Statistics
          </div>

          <div className='text-sm font-semibold'>
            
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  let rangeStartDate = new Date(Date.parse(taskStatistics.dtNumberOfMonthsBehindStart));
  let rangeEndDate = new Date(Date.parse(taskStatistics.dtCurrentMonthEnd));

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md space-y-4'>
      <div className='flex flex-row justify-between items-center mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>
          Task Statistics
        </div>

        <div className='text-sm font-semibold'>
          {rangeStartDate.toDateString()} - {rangeEndDate.toDateString()}
        </div>

      </div>

      <div className="flex flex-row">
        <div className="flex flex-col w-1/2 p-4 me-4 bg-[#FF7D00] text-white justify-center items-center rounded-md">
          <div className='mb-1 text-center'>
            Tasks Started
          </div>
          <div className='font-semibold'>
            {
              taskStatistics ? taskStatistics.lastNMonthsTasks : <ClipLoader size={12} color="#000000" />
            }
          </div>
        </div>
        <div className="flex flex-col w-1/2 p-4 bg-[#55286F] text-white justify-center items-center rounded-md">
          <div className='mb-1 text-center'>
            Tasks due this month
          </div>
          <div className='font-semibold'>
            {
              taskStatistics ? taskStatistics.lastNMonthsTasksEndingThisMonth : <ClipLoader size={12} color="#000000" />
            }
          </div>
        </div>
      </div>

      <div className='flex flex-col gap-5'>
        <div className='flex flex-row mx-2 justify-between'>

          <div className='flex flex-row gap-1 items-center'>

            <div>
              <img src={PurpleTarget} alt="" />
            </div>

            <div className='-mt-1.5'>Finished Tasks</div>

          </div>

          <div className='font-semibold'>
            {
              taskStatistics ? taskStatistics.lastNMonthsFinishedTasks : <ClipLoader size={12} color="#000000" />
            }
          </div>

        </div>

        <div className="flex flex-row mx-2 justify-between">

          <div className='flex flex-row gap-1 items-center'>

            <div>
              <img src={YellowTarget} alt="" />
            </div>

            <div className='-mt-1.5'>Pending Tasks</div>

          </div>

          <div className='font-semibold'>
            {
              taskStatistics ? taskStatistics.lastNMonthsUnfinishedTasks : <ClipLoader size={12} color="#000000" />
            }
          </div>

        </div>

        <div className="flex flex-row mx-2 justify-between">

          <div className='flex flex-row gap-1 items-center'>

            <div>
              <img src={GreenTarget} alt="" />
            </div>

            <div className='-mt-1.5'>Pending High Priority Tasks</div>

          </div>

          <div className='font-semibold'>
            {
              taskStatistics ? taskStatistics.lastNMonthsHighPriorityTasks : <ClipLoader size={12} color="#000000" />
            }
          </div>

        </div>

        <div className="flex flex-row mx-2 justify-between">

          <div className='flex flex-row gap-1 items-center'>

            <div>
              <img src={BrownTarget} alt="" />
            </div>

            <div className='-mt-1.5'>Pending Urgent Priority Tasks</div>

          </div>

          <div className='font-semibold'>
            {
              taskStatistics ? taskStatistics.lastNMonthsUrgentPriorityTasks : <ClipLoader size={12} color="#000000" />
            }
          </div>

        </div>

      </div>

    </div>
  );
}
