import React from 'react';
import { baseURL } from 'config/api.js';
import { homeTimeZone } from 'config/time.js';
import { useState, useEffect } from 'react';
import { DateTime } from "luxon";
import { useSearchParams } from 'react-router-dom';
//import './myAttendance.css'
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import { ClipLoader } from 'react-spinners';

export const MyAttendance = () => {
  const [message, setMessage] = useState(null);

  const [attendances, setAttendances] = useState(null);
  const [attendancesTotalCount, setAttendancesTotalCount] = useState(null);

  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isCheckInSubmitting, setIsCheckInSubmitting] = useState(false);
  const [isCheckInDisabled, setIsCheckInDisabled] = useState(false);
  const [isCheckOutSubmitting, setIsCheckOutSubmitting] = useState(false);
  const [isCheckOutDisabled, setIsCheckOutDisabled] = useState(false);
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/employeesattendances/my-attendances?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const attendances = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    setAttendances(attendances);
    setAttendancesTotalCount(docsCount);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeesattendances/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  let shiftStart = DateTime.fromObject({
    hour: userData.doc.employee_id.workingShift.shiftStart.hour,
    minute: userData.doc.employee_id.workingShift.shiftStart.minute,
  }, { zone: homeTimeZone });

  let shiftEnd = DateTime.fromObject({
    hour: userData.doc.employee_id.workingShift.shiftEnd.hour,
    minute: userData.doc.employee_id.workingShift.shiftEnd.minute,
  }, { zone: homeTimeZone });

  let nowMoment = DateTime.now().setZone(homeTimeZone);

  const handleCheckIn = async (e) => {
    setIsCheckInSubmitting(true);

    //To protect against tempering, we are using setZone
    let now = DateTime.now().setZone(homeTimeZone);

    if ((now < shiftStart) || (now > shiftEnd)) {
      toast.error("Attendance can't be marked outside shift timing.");
      setMessage("Attendance can't be marked outside shift timing.");
      return; //early return
    }

    let payload = {
      employeeID: userData.doc.employee_id._id,
      checkInTime: now.toISO(),
    }

    if (userData.doc.employee_id.workingShift.isLatePolicy) {

      let onTime = DateTime.fromObject(
        {
          hour: userData.doc.employee_id.workingShift.onTime.hour,
          minute: userData.doc.employee_id.workingShift.onTime.minute,
        },
        {
          zone: homeTimeZone
        });

      let halfDay = DateTime.fromObject({
        hour: userData.doc.employee_id.workingShift.halfDay.hour,
        minute: userData.doc.employee_id.workingShift.halfDay.minute,
      },
        {
          zone: homeTimeZone
        });

      let offDay = DateTime.fromObject({
        hour: userData.doc.employee_id.workingShift.offDay.hour,
        minute: userData.doc.employee_id.workingShift.offDay.minute,
      },
        {
          zone: homeTimeZone
        });

      if (now < onTime) {
        payload.status = "On Time";
      }
      else if (now < halfDay) {
        payload.status = "Late";
      }
      else if (now < offDay) {
        payload.status = "Half Day";
      }
      else {
        payload.status = "Off Day";
      }
    }
    else {
      payload.status = "On Time";
    }

    try {
      await sendAPIRequest(payload);
      setIsCheckInSubmitting(false);
      fetchData();
    }
    catch (error) {
      setIsCheckInSubmitting(false);
      setIsCheckInDisabled(true);
    }
  }

  const handleCheckOut = async (e) => {
    setIsCheckOutSubmitting(true);
    setIsCheckOutDisabled(true);

    //To protect against tempering, we are using setZone
    let now = DateTime.now().setZone(homeTimeZone);

    if ((now < shiftStart) || (now > shiftEnd)) {
      toast.error("Check out can't be marked outside shift timing.");
      setMessage("Check out can't be marked outside shift timing.");
      return; //early return
    }

    let payload = {
      employeeID: userData.doc.employee_id._id,
      checkOutTime: now.toISO(),
    }

    try {
      let url = baseURL + "/employeesattendances/check-out";
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };
      const response = await fetch(url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(payload),
      });
      const jsonData = await response.json();
      if (jsonData.success) {
        toast.success(jsonData.data.message);
        setMessage(jsonData.data.message);
        setIsCheckOutSubmitting(false);
        fetchData();
      }
      else {
        toast.error(jsonData.data.message);
        setMessage(jsonData.data.message);
        setIsCheckOutSubmitting(false);
        setIsCheckOutDisabled(true);
      }
    }
    catch (error) {
      setIsCheckOutSubmitting(false);
      setIsCheckOutDisabled(false);
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>My Attendance Report</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let attendancesList = [];

  if (attendances && attendances.length !== 0) {
    attendancesList = attendances.map((att, index) => {
      let co = DateTime.fromISO(att.checkOutTime);
      return (
        <tr key={att._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{index + 1}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{att.employeeID.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{att.status}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{DateTime.fromISO(att.checkInTime).toLocaleString(DateTime.DATETIME_FULL)}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{co.isValid ? co.toLocaleString(DateTime.DATETIME_FULL) : ""}</td>
        </tr>
      );
    });
  }
  else if (attendances && attendances.length === 0) {
    attendancesList =
      <tr>
        <td className="text-gray-900 px-6 py-4 whitespace-nowrap">No attendance found!</td>
      </tr>
  }

  console.log("Before MyAttendance() rendering");

  return (
    <Layout>
      <div className="space-y-5">

        <div className='flex flex-row justify-between items-center px-3 my-2'>
          <h2 className='text-2xl text-primary font-bold'>My Attendance Report</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        {
          (nowMoment >= shiftStart) && (nowMoment <= shiftEnd) && (
            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <button
                    onClick={handleCheckIn}
                    disabled={isCheckInSubmitting || isCheckInDisabled}
                    className='text-white bg-primary rounded-[40px] w-full md:w-11/12 h-9'
                  >
                    {isCheckInSubmitting ? <ClipLoader size={17} color="#fff" /> : "Check In"}
                  </button>
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <button
                    onClick={handleCheckOut}
                    disabled={isCheckOutSubmitting || isCheckOutDisabled}
                    className='text-white bg-primary rounded-[40px] w-full md:w-11/12 h-9'
                  >
                    {isCheckOutSubmitting ? <ClipLoader size={17} color="#fff" /> : "Check Out"}
                  </button>
                </div>
              </div>

            </div>
          )
        }

        <div className='flex'>
          <div className='md:basis-1/2 lg:basis-1/3'>Shift Start: {shiftStart.toLocaleString(DateTime.DATETIME_FULL)}</div>
          <div className='md:basis-1/2 lg:basis-1/3'>Shift End: {shiftEnd.toLocaleString(DateTime.DATETIME_FULL)}</div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE] mt-8'>

          <table className="w-full table-auto overflow-x-auto">

            <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Sr. No.</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Check In Time</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Check Out Time</th>
              </tr>
            </thead>

            <tbody className='text-[13px] p-4'>
              {attendancesList}
            </tbody>

            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(attendancesTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </Layout >
  );
}
