import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

export const VendorsTableMini = () => {
  const [vendors, setVendors] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getVendorsURL = baseURL + "/vendors?skip=no";
      const getVendorsResponse = await fetch(getVendorsURL, {
        method: "GET",
        headers: headers
      });
      const getVendorsJSONData = await getVendorsResponse.json();
      if (!getVendorsResponse.ok) {
        toast.error(getVendorsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      let numberOfRecords = Math.min(getVendorsJSONData.data.docsCount, 6);
      let vendors = [];

      for (let i = 0; i < numberOfRecords; i++) {
        vendors.push(
          <div key={i} className='flex text-xs border-b border-buttonBackground px-3 py-2'>
            <div className='w-[10%]'>
              <span className='flex justify-center w-4 h-4 bg-buttonBackground rounded-full'>
                {getVendorsJSONData.data.docs[i].vendorID}
              </span>
            </div>
            <div className='w-[40%]'>
              {getVendorsJSONData.data.docs[i].vendorCompanyName}
            </div>
            <div className='w-[25%] flex justify-center'>
              {getVendorsJSONData.data.docs[i].totalPurchased}
            </div>
            <div className='w-[25%] flex justify-center'>
              {getVendorsJSONData.data.docs[i].totalPaid}
            </div>
          </div>
        );
      }

      setVendors(vendors);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Vendors
          </div>

          <div>
            <Link
              to="/vendors"
              className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
            >
              View All
            </Link>
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Vendors
          </div>

          <div>
            <Link
              to="/vendors"
              className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
            >
              View All
            </Link>
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
      <div className='flex flex-row justify-between mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>Vendors</div>

        <div>
          <Link
            to="/vendors"
            className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
          >
            View All
          </Link>
        </div>

      </div>

      <div className='flex flex-row text-xs font-medium bg-buttonBackground rounded-md px-3 py-2'>
        <div className='w-[10%]'>
          ID
        </div>
        <div className='w-[40%]'>
          Vendor Company Name
        </div>
        <div className='w-[25%] flex justify-center'>
          Total Purchased
        </div>
        <div className='w-[25%] flex justify-center'>
          Total Paid
        </div>
      </div>

      {vendors}

    </div>
  );
}
