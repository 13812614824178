import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

export const ProjectsDemandsGraphs = () => {
  const [projectsByMonths, setProjectsByMonths] = useState(null);
  const [demandsByMonths, setDemandsByMonths] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getProjectsURL = baseURL + "/reports/projectsByMonths";
      const getProjectsResponse = await fetch(getProjectsURL, {
        method: "GET",
        headers: headers
      });
      const getProjectsJSONData = await getProjectsResponse.json();
      if (!getProjectsResponse.ok) {
        toast.error(getProjectsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      let getDemandsURL = baseURL + "/reports/demandsByMonths";
      const getDemandsResponse = await fetch(getDemandsURL, {
        method: "GET",
        headers: headers
      });
      const getDemandsJSONData = await getDemandsResponse.json();
      if (!getDemandsResponse.ok) {
        toast.error(getDemandsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      setProjectsByMonths(getProjectsJSONData.data.doc);
      setDemandsByMonths(getDemandsJSONData.data.doc);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Projects, Demands
          </div>

          <div className='text-sm font-semibold'>
            
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Projects, Demands
          </div>

          <div className='text-sm font-semibold'>
            
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
  };

  const data = {
    labels: projectsByMonths.labels,
    datasets: [
      {
        label: 'Projects',
        data: projectsByMonths.dataset,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      {
        label: 'Demands',
        data: demandsByMonths.dataset,
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
      },
    ],
  };

  let rangeStartDate = new Date(Date.parse(projectsByMonths.dtNumberOfMonthsBehindStart));
  let rangeEndDate = new Date(Date.parse(projectsByMonths.dtEndOfToday));

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
      <div className='flex flex-row justify-between items-center mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>
          Projects, Demands
        </div>

        <div className='text-sm font-semibold'>
          {rangeStartDate.toDateString()} - {rangeEndDate.toDateString()}
        </div>

      </div>

      <div>
        <Line options={options} data={data} />
      </div>

    </div>
  );
}
