import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { NavLink } from 'react-router-dom';

export const LowStockTablesMini = () => {
  const [stockItems, setStockItems] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getStockItemsURL = baseURL + "/reports/low-stocks";
      const getStockItemsResponse = await fetch(getStockItemsURL, {
        method: "GET",
        headers: headers
      });
      const getStockItemsJSONData = await getStockItemsResponse.json();
      if (!getStockItemsResponse.ok) {
        toast.error(getStockItemsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      let numberOfRecords = Math.min(getStockItemsJSONData.data.docsCount, 8);
      let stockItems = [];

      for (let i = 0; i < numberOfRecords; i++) {
        stockItems.push(
          <div key={i} className='flex text-xs border-b border-buttonBackground px-3 py-2'>
            <div className='w-[10%]'>
              <span className='flex justify-center w-4 h-4 bg-buttonBackground rounded-full'>
                {getStockItemsJSONData.data.docs[i].stockItemID}
              </span>
            </div>
            <div className='w-[40%]'>
              {getStockItemsJSONData.data.docs[i].stockItemName}
            </div>
            <div className='w-[25%] flex justify-center'>
              {getStockItemsJSONData.data.docs[i].stockItemUnitOfMeasure}
            </div>
            <div className='w-[25%] flex justify-center'>
              {getStockItemsJSONData.data.docs[i].currentQuantity}
            </div>
          </div>
        );
      }

      setStockItems(stockItems);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Low Stocks
          </div>

          <div>
            <NavLink
              to="/stockItems"
              className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
            >
              View All
            </NavLink>
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Low Stocks
          </div>

          <div>
            <NavLink
              to="/stockItems"
              className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
            >
              View All
            </NavLink>
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
      <div className='flex flex-row justify-between mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>Low Stocks</div>

        <div>
          <NavLink
            to="/stockItems"
            className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
          >
            View All
          </NavLink>
        </div>

      </div>

      <div className='flex flex-row text-xs font-medium bg-buttonBackground rounded-md px-3 py-2'>
        <div className='w-[10%]'>
          ID
        </div>
        <div className='w-[40%]'>
          Stock Item
        </div>
        <div className='w-[25%] flex justify-center'>
          Unit
        </div>
        <div className='w-[25%] flex justify-center'>
          Quantity
        </div>
      </div>

      {stockItems}

    </div>
  );
}
