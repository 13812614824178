import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './stockItemsNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

export const StockItemsNew = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [stockCategories, setStockCategories] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    let getStockCategoriesURL = baseURL + "/stockcategories/";
    const getStockCategoriesResponse = await fetch(getStockCategoriesURL, {
      method: "GET",
      headers: headers
    });
    const getStockCategoriesJSONData = await getStockCategoriesResponse.json();
    if (!getStockCategoriesResponse.ok) {
      toast.error(getStockCategoriesJSONData.data.message);
      setMessage(getStockCategoriesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const stockCategories = getStockCategoriesJSONData.data.docs;

    setEmployees(employees);
    setStockCategories(stockCategories);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    console.log("Inside sendAPIRequest");
    let url = baseURL + "/stockitems/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
    }
  }

  async function handleCreateStockItemsForm(event) {
    event.preventDefault();
    let errors = [];
    let createStockItemsForm = event.target;
    let stockItemSKU = createStockItemsForm.elements.namedItem("stockItemSKU").value;
    let stockItemCategoryObjectId = createStockItemsForm.elements.namedItem("stockItemCategoryObjectId").value;
    let stockItemName = createStockItemsForm.elements.namedItem("stockItemName").value;
    let currentQuantity = createStockItemsForm.elements.namedItem("currentQuantity").value;
    let currentPrice = createStockItemsForm.elements.namedItem("currentPrice").value;
    let stockItemUnitOfMeasure = createStockItemsForm.elements.namedItem("stockItemUnitOfMeasure").value;

    let stockItemCreator = createStockItemsForm.elements.namedItem("stockItemCreator");
    if (stockItemCreator) {
      if (!stockItemCreator.value) {
        errors.push("Stock item creator is required");
      }
      else {
        stockItemCreator = stockItemCreator.value;
      }
    }

    if (!stockItemCategoryObjectId) {
      errors.push("Stock item category is required");
    }
    if (!stockItemUnitOfMeasure) {
      errors.push("Stock item unit of measure is required");
    }

    let payload = {
      stockItemSKU,
      stockItemCreator: stockItemCreator ? stockItemCreator : undefined,
      stockItemCategoryObjectId,
      stockItemName,
      currentQuantity,
      currentPrice,
      stockItemUnitOfMeasure
    };

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      setMessage(errorMessage);
    }
    else {
      sendAPIRequest(payload);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new stock item</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          { isLoading && <Loader /> }
        </div>
      </Layout>
    );
  }

  let employeesList = [];
  let stockCategoriesList = [];
  let unitsList = [];
  /*let units = [ ['kg', 'kg'],
                ['meter', 'meter'],
                ['squareFeet', 'square feet'],
                ['unitLess', 'unit less'],
                ['litre', 'litre']
              ];*/
  let units = [ 'kg', 'meter', 'square feet', 'litre', 'unit less' ];  

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (stockCategories && stockCategories.length !== 0) {
    stockCategoriesList = stockCategories.map((stockCategory, index) => {
      return (
        <option key={stockCategory._id} value={stockCategory._id}>{stockCategory.stockCategoryName}</option>
      );
    });
  }

  unitsList = units.map((unit, index) => {
    return (
      <option key={index} value={unit}>{unit}</option>
    );
  });

  console.log("Before StockItemsNew() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create a new stock item</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <form id="createStockItemsForm" onSubmit={handleCreateStockItemsForm}>

          <div className='overflow-x-auto rounded-lg border border-primary p-8'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-base font-normal text-left'>SKU <span className='required'>*</span></label>
                <input type="text" name="stockItemSKU" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' placeholder='Stock Item SKU' />
              </div>
              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                  &&  
                  <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                    <label className='text-base font-normal text-left'>Stock Item Creator <span className='required'>*</span></label>
                    <select name="stockItemCreator" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                      <option value={""}>Select an employee</option>
                      {employeesList}
                    </select>
                  </div>
              }
              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-base font-normal text-left'>Category <span className='required'>*</span></label>
                <select name="stockItemCategoryObjectId" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                  <option value={""}>Select a category</option>
                  {stockCategoriesList}
                </select>
              </div>
            
              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-base font-normal text-left'>Name <span className='required'>*</span></label>
                <input type="text" name="stockItemName" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' placeholder='Stock Item Name' />
              </div>
            
              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-base font-normal text-left'>Unit of Measure <span className='required'>*</span></label>
                <select name="stockItemUnitOfMeasure" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                  <option value={""}>Select a unit</option>
                  {unitsList}
                </select>
              </div>
            
              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-base font-normal text-left'>Current Quantity <span className='required'>*</span></label>
                <input type="number" min={0} name="currentQuantity" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' placeholder='Current quantity in stock' />
              </div>
            
              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-base font-normal text-left'>Current Unit Price <span className='required'>*</span></label>
                <input type="number" min={0} name="currentPrice" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' placeholder='Current unit price' />
              </div>
            </div>

            <div className='flex flex-row my-5'>
              <input 
                disabled={isSubmitting || isDisabled}
                type="submit" 
                name="submit" 
                value="Submit" 
                className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9' 
              />
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
}
