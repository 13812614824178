import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import './departments.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const Departments = () => {
  const [message, setMessage] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [activeCompany, setActiveCompany] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(false);

  async function fetchData() {
    let url = baseURL + "/companies/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const companies = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;
    if (docsCount > 0) {
      //setCompanies(companies);
      fetchDepartments(companies[0]._id, true, companies); //to prevent infinite loop
    }
    else {
      setMessage("No companies found! Please create a new company and its departments.")
    }
  }

  async function fetchDepartments(companyObjectId, firstRender = false, companies = null) {
    setIsDepartmentsLoading(true);
    setActiveCompany(companyObjectId);

    let url = baseURL + "/departments/of-company/" + companyObjectId;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();

    const departments = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    setIsDepartmentsLoading(false);
    setIsLoading(false);

    if (docsCount > 0) {
      if (firstRender) {
        setCompanies(companies);
        setDepartments(departments);
        setMessage(null);
      }
      else {
        setDepartments(departments);
        setMessage(null);
      }
    }
    else {
      setDepartments([]);
      setMessage(`The selected company has no departments.`);
    }
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("departments")
    ) &&
    <Link to="/departments/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>New Department</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Departments</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let companiesList = [];

  if (companies && companies.length !== 0) { //After calling companies/ endpoint
    companiesList = companies.map((company, index) => {
      return (
        <option key={company._id} value={company._id} className='bg-[#4B778E]'>{company.name}</option>
      );
    });
  }

  let departmentsList = [];

  if (departments && departments.length !== 0) { //After calling departments/ endpoint
    departmentsList = departments.map((deptt, index) => {
      let editLink = "/departments/edit/" + deptt._id;
      return (
        <tr key={index}>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{index + 1}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{deptt.department_name}</td>
          <td className="text-primary px-6 py-3 whitespace-nowrap"><Link to={editLink}><CiEdit className='text-2xl ' /></Link></td>
          <td className="whitespace-nowrap">
            <button
              data-id={deptt._id}
              onClick={handleDelete}
              className='px-7 py-3'
            >
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }
  else if (departments && departments.length === 0) {
    departmentsList = (
      <tr key={1}>
        <td>
          No departments found!
        </td>
      </tr>
    )
  }

  console.log("Before Departments() rendering");

  return (
    <Layout>
      <div className="space-y-4">
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Departments</h2>
          {NewDocumentLink}
        </div>

        <div className='flex flex-row items-center justify-between px-3 '>
          <div>
            {message && message}
          </div>
          <div className='text-white'>
            {
              isDepartmentsLoading ? (
                <div className='w-[187px] flex justify-center bg-[#90288F] px-3 py-2 rounded-3xl'>
                  <ClipLoader size={15} color='#ffffff' />
                </div>
              ) : (
                <select
                  id="company_id"
                  value={activeCompany}
                  onChange={(e) => {
                    fetchDepartments(e.target.value);
                  }

                  }
                  className='bg-[#90288F] px-3 py-2 rounded-3xl'
                >
                  {companiesList}
                </select>
              )
            }
          </div>
        </div>

        <div className='overflow-hidden rounded-lg  border border-[#6843BE]'>
          <table className="w-full">
            <thead className="   text-[14px] border border-b-[#6843BE]   ">
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Sr. No.</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Department</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {departmentsList}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>

  );
}
