import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './employeesTasksNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';
import Select from 'react-select';

export const EmployeesTasksNew = () => {
  const [message, setMessage] = useState(null);

  const [employees, setEmployees] = useState(null);
  const [title, setTitle] = useState("");
  const [supervisor, setSupervisor] = useState("");
  const [employeeIDs, setEmployeeIDs] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");
  const [actualFinishDate, setActualFinishDate] = useState("");
  const [status, setStatus] = useState("");
  const [priority, setPriority] = useState("");
  const [description, setDescription] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getEmployeesURL = baseURL + "/employees";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const jsonData2 = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(jsonData2.data.message);
      setMessage(jsonData2.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = jsonData2.data.docs;

    setEmployees(employees);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeestasks/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateEmployeeTaskForm(event) {
    event.preventDefault();
    const errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!title) {
      errors.push(<div>Title cannot be empty</div>);
    }

    if (employeeIDs.length < 1) {
      errors.push(<div>Task should be assigned to at least one employee</div>);
    }

    if (!supervisor) {
      errors.push(<div>Supervisor should be selected</div>);
    }

    if (!startDate) {
      errors.push(<div>Start date should be selected</div>);
    }

    if (!finishDate) {
      errors.push(<div>Finish date should be selected</div>);
    }

    if ( (status !== 0) && (status !== 1) ) {
      errors.push(<div>Status should be selected</div>);
    }

    if (priority !== 0 && priority !== 1 && priority !== 2 && priority !== 3) {
      errors.push(<div>Priority should be selected</div>);
    }

    const payload = {
      employeeIDs,
      supervisor,
      taskTitle: title,
      taskDescription: description,
      taskStartByDate: startDate,
      taskFinishByDate: finishDate,
      taskActualFinishDate: actualFinishDate ? actualFinishDate : undefined,
      taskStatus: status,
      taskPriority: priority,
    }

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(true);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new task</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before EmployeesTasksNew() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5 text-gray-900 text-sm'>

        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Create a new task</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-primary p-8 space-y-3'>

          <div>Create the task and then edit the task to attach files and create subtasks.</div>

          <form onSubmit={handleCreateEmployeeTaskForm} className='space-y-3'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Title <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="text"
                    name="title"
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Employees <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <Select
                    isMulti
                    name="employeeIDs"
                    onChange={(selectedOptions) => {
                      let peopleList = [];
                      if (selectedOptions && selectedOptions.length > 0) {
                        peopleList = selectedOptions.map((option) => {
                          return option.value; //an ObjectId
                        });
                      }
                      setEmployeeIDs(peopleList);
                    }}
                    options={
                      employees && employees.length !== 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: `${employee.full_name} ${employee.custom_id}`
                        }
                      })
                    }
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#8E8EA1',
                      }),
                    }}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Supervisor <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="supervisor"
                    options={
                      employees && employees.length !== 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: `${employee.full_name} ${employee.custom_id}`
                        }
                      })
                    }
                    handleChange={(selectedOption) => setSupervisor(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Start Date <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="date"
                    name="startDate"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Finish Date <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="date"
                    name="finishDate"
                    value={finishDate}
                    onChange={(e) => {
                      setFinishDate(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Actual Finish Date</label>
                </div>
                <div>
                  <input
                    type="date"
                    name="actualFinishDate"
                    value={actualFinishDate}
                    onChange={(e) => {
                      setActualFinishDate(e.target.value);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Status <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="status"
                    options={
                      [
                        { value: 0, label: 'Pending' },
                        { value: 1, label: 'Finished' },
                      ].map((type) => {
                        return {
                          value: type.value,
                          label: type.label
                        }
                      })
                    }
                    handleChange={(selectedOption) => setStatus(selectedOption.value)}
                  />
                </div>
                
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                  <div>
                    <label className='font-normal'>
                      Priority <span className='required'>*</span>
                    </label>
                  </div>
                  <div className='w-full md:w-11/12'>
                    <SelectBox
                      name="priority"
                      options={
                        [
                          { value: 0, label: 'Low' },
                          { value: 1, label: 'Normal' },
                          { value: 2, label: 'High' },
                          { value: 3, label: 'Urgent' }
                        ].map((type) => {
                          return {
                            value: type.value,
                            label: type.label
                          }
                        })
                      }
                      handleChange={(selectedOption) => setPriority(selectedOption.value)}
                    />
                  </div>

                </div>
              
            </div>


              <div className='flex flex-col gap-y-3'>
                <div>
                  <label className='font-normal'>Description</label>
                </div>
                <div>
                  <textarea
                    rows="4"
                    cols="50"
                    name="description"
                    value={description}
                    onChange={(e) => {
                      e.target.value ? setDescription(e.target.value) : setDescription('');
                    }}
                    className='border border-[#8E8EA1] w-full py-2 rounded-md px-3 outline-none'
                  >
                  </textarea>
                </div>
              </div>


              <div className='flex flex-row my-5'>
                <button
                  disabled={isSubmitting || isDisabled}
                  type="submit"
                  className='text-white bg-primary rounded w-[299px] h-9'
                >
                  {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
                </button>
              </div>

          </form>

        </div>

      </div >

    </Layout >
  );
}
