import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './employeesProfile.css';
import { Link, useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';

export const EmployeesProfile = () => {
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [employeeProfile, setEmployeeProfile] = useState(null);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let getEmployeeProfileURL = baseURL + "/employees/profile/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getEmployeeProfileResponse = await fetch(getEmployeeProfileURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeeProfileJSONData = await getEmployeeProfileResponse.json();
    if (!getEmployeeProfileResponse.ok) {
      toast.error(getEmployeeProfileJSONData.data.message);
      setMessage(getEmployeeProfileJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employeeProfile = getEmployeeProfileJSONData.data.doc;

    setEmployeeProfile(employeeProfile);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);


  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-semibold'>Employee Profile</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-semibold'>Employee Profile</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div id="employeeProfile" className='overflow-x-auto rounded-lg border border-[#6843BE] m-4 md:m-0 p-4 text-sm space-y-5'>

          <div className="flex flex-col xl:flex-row">
            <div className='w-1/2 flex flex-col gap-y-2'>

              <div className='flex flex-row'>
                <div className='font-semibold w-1/4'>Full Name:</div>
                <div className='w-1/2'>{employeeProfile.full_name}</div>
              </div>

              <div className='flex flex-row'>
                <div className='font-semibold w-1/4'>Father Name:</div>
                <div className='w-1/2'>{employeeProfile.father_name}</div>
              </div>

              <div className='flex flex-row'>
                <div className='font-semibold w-1/4'>Custom ID:</div>
                <div className='w-1/2'>{employeeProfile.custom_id}</div>
              </div>

            </div>
            <div className='w-1/2 flex justify-end'>
              {
                employeeProfile.picture ? (
                  <img
                    src={staticFilesURL + "/employees/" + employeeProfile.picture}
                    alt='photograph of employee'
                    className='w-[200px] h-[200px] rounded-full'
                  />
                ) : (
                  "No photo found!"
                )
              }
            </div>
          </div>



        </div>
      </div>
    </Layout>
  );
}
