import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';
import clsx from "clsx";

export const NotificationsBox = () => {
  const [notifications, setNotifications] = useState(null);
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getNotificationsURL = baseURL + "/notifications?employeeID=" + userData.doc.employee_id?._id;
      const getNotificationsResponse = await fetch(getNotificationsURL, {
        method: "GET",
        headers: headers
      });
      const getNotificationsJSONData = await getNotificationsResponse.json();
      if (!getNotificationsResponse.ok) {
        toast.error(getNotificationsJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      setNotifications(getNotificationsJSONData.data.docs);
      setTotalNotificationsCount(getNotificationsJSONData.data.docsCount);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='font-extrabold text-lg mb-4'>
          Notifications
        </div>
        <div className="flex justify-center items-center p-8">
          <ClipLoader size={18} />
        </div>
      </div>
    );
  }

  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='font-extrabold text-lg mb-4'>
          Notifications
        </div>
        <div className="flex justify-center items-center p-8">
          An error occurred
        </div>
      </div>
    );
  }

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
      <div className='font-extrabold text-lg mb-4'>
        Notifications
      </div>

      {
        notifications && notifications.length > 0 ? notifications.map((notification, index) => {
          let url = `/${notification.redirectPage}`;
          let creationDate = new Date(Date.parse(notification.createdAt));
          return (
            <Link
              to={url}
              className={clsx('block my-4 rounded-md shadow-md', notification.readStatus && 'text-gray-400')}
              onClick={
                async (e) => {
                  try {
                    let url = baseURL + "/notifications/mark-as-read?notificationid=" + notification._id;
                    const headers = {
                      'Authorization': 'Bearer ' + userData.token
                    };
                    const response = await fetch(url, {
                      method: "PATCH",
                      headers: headers
                    });
                    const jsonData = await response.json();
                    if (!response.ok) {
                      toast.error(jsonData.data.message);
                      return; //early return
                    }
                    const index = notifications.findIndex((element) => element._id === notification._id);
                    let notificationsCopy = [...notifications];
                    notificationsCopy[index] = { ...notification, readStatus: true };
                    setNotifications(notificationsCopy);
                  }
                  catch (error) {
                    toast.error("An error occurred while communicating with the server.");
                  }
                }
              }
            >
              <div className='flex gap-x-4 p-2'>
                <div className='w-8 h-8 rounded-full bg-[#fdd7b3] flex justify-center items-center'>
                  {index + 1}
                </div>
                <div className="flex flex-col">
                  <div className='font-semibold text-sm'>
                    {notification.message}
                  </div>
                  <div className='text-sm'>
                    {`${creationDate.toLocaleTimeString()} ${creationDate.toDateString()}`}
                  </div>
                </div>
              </div>
            </Link>
          )
        }) : (
          <div>No notifications found!</div>
        )
      }

      {
        totalNotificationsCount && totalNotificationsCount > 10 && (
          <Link
            to='/notifications'
            className='btn'
          >
            More
          </Link>
        )
      }


    </div>
  );
}
