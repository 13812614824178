import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './projects.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from 'components/global/pagination';
import { toast } from 'react-toastify';

export const Projects = () => {
  const [message, setMessage] = useState(null);
  const [projects, setProjects] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/projects?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }

    const projects = jsonData.data.docs;

    setProjects(projects);
    setTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("projects")
    ) && (
      <Link to="/projects/new">
        <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
          <span><FaPlus /></span>
          <span>Add Project</span>
        </button>
      </Link>
    )

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-bold'>Projects</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let projectsList = [];

  if (projects && projects.length !== 0) { //on second render
    projectsList = projects.map((project, index) => {
      let editLink = "/projects/edit/" + project._id;
      let viewLink = "/projects/" + project._id;
      return (
        <tr key={project._id}>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{project.projectID}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
            <Link to={viewLink} className='text-white bg-primary rounded-[40px] p-2'>{project.projectName}</Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{project.projectManager.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{project.projectCategory.projectCategoryName}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{project.coveredArea}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
          <td className="  px-6 py-4 whitespace-nowrap text-center">
            <button onClick={handleDelete}><RiDeleteBin6Line className='text-lg text-[#E30411]' /></button>
          </td>
        </tr>
      );
    });
  }

  console.log("Before Projects() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Projects</h2>
          {NewDocumentLink}
        </div>

        <div className='flex flex-row justify-between'>
          <div>
            {message && message}
          </div>
        </div>
        {isLoading ? <Loader /> :
          <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
            <table className="w-full table-auto overflow-x-auto ">
              <thead className="bg-lightSlate border-b text-[14px]  border border-b-[#6843BE] rounded-lg ">
                <tr>
                  <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                  <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Name</th>
                  <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Manager</th>
                  <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Category</th>
                  <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Covered Area (sq. ft.)</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                  <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
                </tr>
              </thead>
              <tbody className='text-[13px] p-4'>
                {projectsList}
              </tbody>
              <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
                <tr>
                  <td colSpan='12' >
                    <Pagination
                      currentPage={page}
                      pageCount={Math.ceil(totalCount / pageSize)}
                      setPage={handleCurrentPageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        }
      </div>
    </Layout>
  );
}
