import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './demands.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from 'components/global/pagination';
import { toast } from 'react-toastify';

export const Demands = () => {
  const [message, setMessage] = useState(null);
  const [demands, setDemands] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/demands?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const demands = jsonData.data.docs;

    setDemands(demands);
    setTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("demands")
    ) &&
    <Link to="/demands/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>Add Demand</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Demands</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let demandsList = [];

  if (demands && demands.length !== 0) { //on second render
    demandsList = demands.map((demand, index) => {
      const editLink = "/demands/edit/" + demand._id;
      const createQuotationLink = "/quotations/new/of-demand/" + demand._id;
      const compareQuotationsLink = "/comparequotations/of-demand/" + demand._id;
      let demandAcceptanceStatus = false;
      if (
        demand.cooConsent === "accept" &&
        demand.mdConsent === "accept" &&
        demand.projectManagerConsent === "accept"
      ) {
        demandAcceptanceStatus = true;
      }
      return (
        <tr key={demand._id}>
          <td className=" text-gray-900 px-6 py-3 whitespace-nowrap">{demand.demandID}</td>
          <td className=" text-gray-900 px-6 py-3 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/projects/${demand.projectObjectId._id}`}>
              {demand.projectObjectId.projectName}
            </Link>
          </td>
          <td className=" text-gray-900 px-6 py-3 whitespace-nowrap">{demand.demandCreator.full_name}</td>
          <td className=" text-gray-900 px-6 py-3 whitespace-nowrap">
            {demandAcceptanceStatus ? <Link to={createQuotationLink} className='text-white bg-primary rounded-[40px] p-2 h-9'>New Quotation</Link> : "Waiting for approval of demand"}
          </td>
          <td className=" text-gray-900  px-6 py-3 whitespace-nowrap">
            <Link to={compareQuotationsLink} className='text-white bg-primary rounded-[40px] p-2 h-9' >Compare Quotations</Link>
          </td>
          <td className="  px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
          <td className=" text-primary px-6 py-4 whitespace-nowrap text-center">
            <button onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }

  return (
    <Layout>
      <div className="space-y-4">
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Demands</h2>
          {NewDocumentLink}
        </div>
        <div className='flex flex-row justify-between'>
          {message && message}
        </div>
        <div className='overflow-x-auto rounded-lg  border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="text-[14px] border border-b-[#6843BE]">
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Project Name</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Demand Creator</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Create Quotation</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Compare Quotations</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {demandsList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
