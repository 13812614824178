import React from 'react';
//import './dashboardSuperAdmin.css';
import Layout from 'components/global/Layout';
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { AbsencesGraph } from 'components/absencesGraph/AbsencesGraph.jsx';
import { TaskStatistics } from 'components/taskStatistics/TaskStatistics';
import { SalariesChart } from 'components/salariesChart/SalariesChart';
import { ComplaintsLineChart } from 'components/complaintsLineChart/ComplaintsLineChart';
import { MeetingsToday } from 'components/meetingsToday/MeetingsToday';
import { AttendancesLeavesBox } from 'components/attendancesLeavesBox/AttendancesLeavesBox';
import { WorkDayInfoBox } from 'components/workDayInfoBox/WorkDayInfoBox';
import { MyTasksTableMini } from 'components/myTasksTableMini/MyTasksTableMini';
import { NotificationsBox } from 'components/notificationsBox/NotificationsBox';

export const DashboardHR = () => {
  const { userData } = useContext(AuthContext);

  return (
    <Layout isDashboard={true}>
      <div className="space-y-4">
        <h2 className='text-2xl text-black font-bold'>Dashboard</h2>
        <div>Welcome {userData?.role?.name}!</div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2 space-y-4">
            <div className='xl:me-4'>
              <MeetingsToday />
            </div>
            <div className='xl:me-4'>
              <MyTasksTableMini />
            </div>
          </div>
          <div className="w-full xl:w-1/2 flex flex-col gap-y-4">
            <div className="">
              <AttendancesLeavesBox />
            </div>
            <div className="">
              <WorkDayInfoBox />
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <AbsencesGraph />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <TaskStatistics />
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <SalariesChart />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <ComplaintsLineChart />
          </div>
        </div>

        <div>
          <NotificationsBox />
        </div>

      </div>
    </Layout>
  );
}
