import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './notifications.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { Link, useSearchParams } from 'react-router-dom';
import clsx from "clsx";
import Pagination from 'components/global/pagination';

export const NotificationsNew = () => {
  const [message, setMessage] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [totalNotificationsCount, setTotalNotificationsCount] = useState(null);
  const { userData } = useContext(AuthContext);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();


  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/notifications?employeeID=${userData.doc.employee_id?._id}&page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const notifications = jsonData.data.docs;

    setNotifications(notifications);
    setTotalNotificationsCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  console.log("Before Notifications() rendering");

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Notifications</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center'>
          <h2 className='text-2xl text-primary font-bold'>Notifications</h2>
        </div>

        <div className='flex flex-row justify-center'>
          {message && message}
        </div>

        <div className='overflow-auto bg-white'>

          {
            notifications && notifications.length > 0 ? notifications.map((notification, index) => {
              let url = `/${notification.redirectPage}`;
              let creationDate = new Date(Date.parse(notification.createdAt));
              return (
                <Link
                  to={url}
                  className={clsx('block my-4 rounded-md shadow-md', notification.readStatus && 'text-gray-400')}
                  onClick={
                    async (e) => {
                      try {
                        let url = baseURL + "/notifications/mark-as-read?notificationid=" + notification._id;
                        const headers = {
                          'Authorization': 'Bearer ' + userData.token
                        };
                        const response = await fetch(url, {
                          method: "PATCH",
                          headers: headers
                        });
                        const jsonData = await response.json();
                        if (!response.ok) {
                          toast.error(jsonData.data.message);
                          return; //early return
                        }
                        const index = notifications.findIndex((element) => element._id === notification._id);
                        let notificationsCopy = [...notifications];
                        notificationsCopy[index] = { ...notification, readStatus: true };
                        setNotifications(notificationsCopy);
                      }
                      catch (error) {
                        toast.error("An error occurred while communicating with the server.");
                      }
                    }
                  }
                >
                  <div className='flex gap-x-4 p-2'>
                    <div className='w-8 h-8 rounded-full bg-[#fdd7b3] flex justify-center items-center'>
                      {index + 1}
                    </div>
                    <div className="flex flex-col">
                      <div className='font-semibold text-sm'>
                        {notification.message}
                      </div>
                      <div className='text-sm'>
                        {`${creationDate.toLocaleTimeString()} ${creationDate.toDateString()}`}
                      </div>
                    </div>
                  </div>
                </Link>
              )
            }) : (
              <div>No notifications found!</div>
            )
          }


        </div>

        <Pagination
          currentPage={page}
          pageCount={Math.ceil(totalNotificationsCount / pageSize)}
          setPage={handleCurrentPageChange}
        />
        
      </div>
    </Layout>
  );
}
