import { baseURL } from 'config/api.js';
import { useEffect, useRef } from 'react';
import { NavLink } from "react-router-dom";
import './styles.css';
import { useDrawerContext } from 'context/DrawerContext';
import { useContext } from 'react';
import logo from "assets/logo.png";
import Hamburger from 'assets/svgs/hamburger.svg';
import { AuthContext } from 'context/AuthContext';
import { BsBell } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import useToggle from 'hooks/useToggle';
import useClickOutside from 'utils/clickOutside';

const Header = () => {
  const { showDrawer, setShowDrawer } = useDrawerContext();
  const menuRef = useRef(null);
  const [showMenu, toggleMenu] = useToggle(false);

  const { userData, unreadNotificationsCount, setUnreadNotificationsCount } = useContext(AuthContext);

  useClickOutside(menuRef, () => toggleMenu(false));

  async function fetchData() {
    let url = baseURL + "/notifications/get-unread-notifications?employeeID=" + userData.doc.employee_id?._id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      return; //early return
    }
    const notificationsCount = jsonData.data.docsCount;
    setUnreadNotificationsCount(notificationsCount);
  }

  useEffect(() => {
    if ((userData.doc.isSuperAdmin || userData.doc.isAdmin)) {
      setUnreadNotificationsCount(0);
    }
    else {
      fetchData().catch(e => {
        toast.error("An error occurred while communicating with the server.");
      });
    }
    // eslint-disable-next-line
  }, []);

  console.log("Before Header() rendering");

  let role = "";
  if (userData.doc.isSuperAdmin) {
    role = "Super Admin"
  }
  else if (userData.doc.isAdmin) {
    role = "Admin"
  }
  else {
    role = userData.role.name;
  }

  return (
    <div
      className='w-full py-3 border-b flex items-center justify-between xl:justify-end gap-8 md:px-12 sm:px-8 px-2 text-lg'
    >
      <div
        className='cursor-pointer w-fit block xl:hidden'
        onClick={() => setShowDrawer(true)}
      >
        <img
          src={Hamburger}
          alt="Hamburger"
          className='sm:w-[40px] w-[30px]'
        />
      </div>

      <div className='block xl:hidden'>
        <NavLink to="/">
          <img className="h-7 sm:h-8" src={logo} alt="technoproperties" />
        </NavLink>
      </div>

      <div className='relative'>
        <div className='block xl:hidden' onClick={() => toggleMenu()}>
          <BsThreeDotsVertical />
        </div>
        {
          showMenu && (
            <div
              className='absolute right-[30%] top-[110%] w-[240px] h-auto bg-pure shadow-lg rounded-lg border'
              ref={menuRef}
            >
              <div className='flex flex-row gap-x-4 items-center justify-center m-4'>
                <div className='text-sm'>
                  <p className='font-semibold'>
                    {userData.doc.email}
                  </p>
                  <p className='text-grayText'>
                    You are {role}
                  </p>
                </div>

                <Link to='/notifications'>
                  <div className='header-notifications'>
                    <BsBell />
                    {
                      unreadNotificationsCount > 0 && (
                        <span className='notifications-count'>{unreadNotificationsCount}</span>
                      )
                    }
                  </div>
                </Link>
              </div>
            </div>
          )
        }
      </div>

      <div className='hidden xl:flex flex-row gap-x-4 items-center'>
        <div className='text-sm'>
          <p className='font-semibold'>
            {userData.doc.email}
          </p>
          <p className='text-grayText'>
            You are {role}
          </p>
        </div>

        <Link to='/notifications'>
          <div className='header-notifications'>
            <BsBell />
            {
              unreadNotificationsCount > 0 && (
                <span className='notifications-count'>{unreadNotificationsCount}</span>
              )
            }
          </div>
        </Link>
      </div>

    </div>
  )
}

export default Header;