import React from 'react';
//import './dashboardStoreManager.css';
import Layout from 'components/global/Layout';
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { VendorsTableMini } from 'components/vendorsTableMini/VendorsTableMini.jsx';
import { ProjectsDemandsGraphs } from 'components/projectsDemandsGraphs/ProjectsDemandsGraphs';
import { LowStockTablesMini } from 'components/lowStockTablesMini/LowStockTablesMini';
import { MeetingsToday } from 'components/meetingsToday/MeetingsToday';
import { AttendancesLeavesBox } from 'components/attendancesLeavesBox/AttendancesLeavesBox';
import { WorkDayInfoBox } from 'components/workDayInfoBox/WorkDayInfoBox';
import { MyTasksTableMini } from 'components/myTasksTableMini/MyTasksTableMini';
import { NotificationsBox } from 'components/notificationsBox/NotificationsBox';

export const DashboardStoreManager = () => {
  const { userData } = useContext(AuthContext);

  console.log("Before DashboardStoreManager() rendering");

  return (
    <Layout isDashboard={true}>
      <div className="space-y-4">
        <h2 className='text-2xl text-black font-bold'>Dashboard</h2>
        <div>Welcome {userData?.role?.name}!</div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2 space-y-4">
            <div className='xl:me-4'>
              <MeetingsToday />
            </div>
            <div className='xl:me-4'>
              <MyTasksTableMini />
            </div>
          </div>
          <div className="w-full xl:w-1/2 flex flex-col gap-y-4">
            <div className="">
              <AttendancesLeavesBox />
            </div>
            <div className="">
              <WorkDayInfoBox />
            </div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap content-stretch">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <VendorsTableMini />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            <ProjectsDemandsGraphs />
          </div>
        </div>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2">
            <div className='xl:me-4'>
              <LowStockTablesMini />
            </div>
          </div>
          <div className="w-full xl:w-1/2">
            
          </div>
        </div>

        <div>
          <NotificationsBox />
        </div>

      </div>
    </Layout>
  );
}
