import React, { useState, useContext } from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { PDFViewer } from 'components/PDFViewer/PDFViewer.jsx';

export const TaskTabAttachments = ({ attachments, setTask, params }) => {

  const [attachedFile, setAttachedFile] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeestasks/add-attachment/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setTask(jsonData.data.doc);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleTaskFileAttachmentChange = async (event) => {
    const file = event.target.files[0];
    try {
      if (((file.size / 1024) / 1024) > 5) { //if greater than 5 megabytes
        toast.error(`File size too large: ${file.name} Limit is 5MB per file`);
        event.target.value = null;
      }
      else {
        const dataURL = await convertToBase64(file);
        setAttachedFile({
          clientFileName: file.name,
          fileAsDataURL: dataURL
        });
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const sendDeleteFileRequest = async (fileObjectId, fileNameOnServerDisk) => {
    setIsDeleting(true);
    let payload = {
      fileObjectId: fileObjectId,
      employeeTaskObjectId: params.id,
      fileNameOnServerDisk
    }
    let url = `${baseURL}/employeestasks/delete-attachment`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'DELETE',
      headers: headers,
      body: JSON.stringify(payload)
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setTask(jsonData.data.doc);
      setIsDeleting(false);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setIsDeleting(false);
      return Promise.reject("failure");
    }
  }

  async function handleAddNewFileAttachment(event) {
    event.stopPropagation();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (attachedFile === null) {
      errors.push("No file attached!");
    }

    let payload = {
      attachedFile
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(' ');
      toast.error(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  console.log("Before TaskTabAttachments() rendering");

  const openWithInWebApp = ["jpeg", "jpg", "png"];

  return (
    <React.Fragment>
      {
        attachments && attachments.length > 0 ? attachments.map(
          (attachment, index) => {
            let link = "#"
            if (attachment.fileNameOnServerDisk)
              link = staticFilesURL + "/employees/" + attachment.fileNameOnServerDisk;

            const tokens = attachment.fileNameOnServerDisk.split('.');

            let content = null;

            if ("pdf".localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
              content = <PDFViewer link={link} />
            }
            else {
              for (const ext of openWithInWebApp) {
                if (ext.localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
                  content = <img src={link} alt="attached photograph" />
                }
              }
            }

            if (!content) {
              content = "No preview available";
            }

            return (
              <div key={index} className='flex flex-col gap-y-3'>
                <div>
                  {`${index + 1}) ${attachment.clientFileName}`}
                </div>
                <div className='flex flex-row border border-[#8E8EA1] rounded-[10px]'>
                  <div className='border border-r-[#8E8EA1] p-3 md:w-5/6'>
                    {
                      content
                    }
                  </div>
                  <div className='border border-r-[#8E8EA1] p-3 md:w-1/6'>
                    <a
                      key={attachment._id}
                      className='btn-primary justify-center'
                      href={link}
                      download
                    >
                      Download
                    </a>
                    <button
                      className='btn-primary text-white rounded w-full justify-center mt-4'
                      onClick={
                        async (e) => {
                          e.preventDefault();
                          try {
                            await sendDeleteFileRequest(attachment._id, attachment.fileNameOnServerDisk);
                          }
                          catch (error) {
                            console.log(error);
                          }
                        }
                      }
                    >
                      {isDeleting ? <ClipLoader size={17} color="#fff" /> : "Delete"}
                    </button>
                  </div>
                </div>
              </div>
            );
          }
        ) : (
          <div>No files attached!</div>
        )
      }
      <div className="flex flex-col gap-y-2">
        <div>Attach File</div>
        <div>
          <input
            type="file"
            name="attachment"
            onChange={handleTaskFileAttachmentChange}
            className='border border-[#8E8EA1] text-[#8E8EA1] py-1 rounded px-2 outline-none text-sm'
          />
        </div>
        <div>
          <button
            disabled={isSubmitting || isDisabled}
            className='btn-primary justify-center'
            onClick={
              handleAddNewFileAttachment
            }
          >
            {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Upload"}
          </button>
        </div>
      </div>

    </React.Fragment>
  );
}