import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
//import './events.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import { DateTime } from 'luxon';

export const MyMeetings = () => {
  const [message, setMessage] = useState(null);
  const [events, setEvents] = useState(null);
  const [eventsTotalCount, setEventsTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/events/my-meetings?page=${page}&employeeID=${userData.doc.employee_id._id}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const events = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;

    setEvents(events);
    setEventsTotalCount(docsCount);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3 my-2'>
            <h2 className='text-2xl text-primary font-bold'>My Meetings</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let eventsList = [];

  if (events && events.length !== 0) {
    eventsList = events.map((event, index) => {

      const people = []
      for (const person of event.people) {
        people.push(person.full_name);
      }

      let status;
      if (event.status === 'onSchedule')
        status = "On Schedule";
      else if (event.status === 'cancelled')
        status = "Cancelled";

      let dtStart = DateTime.fromISO(new Date(event.startDate).toISOString());
      let dtEnd = DateTime.fromISO(new Date(event.endDate).toISOString());

      return (
        <tr key={event._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{event.eventID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{people.join(', ')}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{event.title}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{status}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{dtStart.toLocaleString(DateTime.DATETIME_MED)}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{dtEnd.toLocaleString(DateTime.DATETIME_MED)}</td>
        </tr>
      );
    });
  }
  else if (events && events.length === 0) {
    eventsList =
      <tr>
        <td className="text-gray-900 px-6 py-4 whitespace-nowrap">No meetings found!</td>
      </tr>
  }

  return (
    <Layout>
      <div className="space-y-5">

        <div className='flex flex-row justify-between items-center px-3 my-2'>

          <h2 className='text-2xl text-primary font-bold'>My Meetings</h2>
          
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE] mt-8'>

          <table className="w-full table-auto overflow-x-auto">

            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">People</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Title</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Start Date</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">End Date</th>
              </tr>
            </thead>

            <tbody className='text-[13px] p-4'>
              {eventsList}
            </tbody>

            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='12'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(eventsTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>

          </table>
        </div>
      </div>
    </Layout>
  );
}
