import React from 'react';
import Layout from 'components/global/Layout';
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { NotificationsBox } from 'components/notificationsBox/NotificationsBox';
import { AttendancesLeavesBox } from 'components/attendancesLeavesBox/AttendancesLeavesBox';
import { WorkDayInfoBox } from 'components/workDayInfoBox/WorkDayInfoBox';
import { MyTasksTableMini } from 'components/myTasksTableMini/MyTasksTableMini';
import { MeetingsToday } from 'components/meetingsToday/MeetingsToday';
import { TaskStatistics } from 'components/taskStatistics/TaskStatistics';

export const DashboardTasks = () => {
  const { userData } = useContext(AuthContext);

  console.log("Before DashboardEmployee() rendering");

  return (
    <Layout isDashboard={true}>
      <div className="space-y-4">
        <h2 className='text-2xl text-black font-bold'>Dashboard</h2>

        <div className="flex flex-row flex-wrap">
          <div className="w-full xl:w-1/2 space-y-4">
            <div className='xl:me-4'>
              <MeetingsToday />
            </div>
            <div className='xl:me-4'>
            <TaskStatistics />            </div>
          </div>
          <div className="w-full xl:w-1/2 flex flex-col gap-y-4">
            <div className="">
            <MyTasksTableMini />
            </div>
            <div className="">
              <AttendancesLeavesBox />
            </div>
            {/* <div className="">
              <WorkDayInfoBox />              
            </div> */}
          </div>
        </div>
        <div>
          <NotificationsBox />
        </div>

      </div>
    </Layout>
  );
}
