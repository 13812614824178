import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const AttendancesLeavesBox = () => {
  const [currentMonthData, setCurrentMonthData] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getAttendancesURL = baseURL + "/reports/attendancesAndLeaves";
      const getAttendancesResponse = await fetch(getAttendancesURL, {
        method: "GET",
        headers: headers
      });
      const getAttendancesJSONData = await getAttendancesResponse.json();
      if (!getAttendancesResponse.ok) {
        toast.error(getAttendancesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      setCurrentMonthData(getAttendancesJSONData.data.doc);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Attendances and Leaves
          </div>

          <div className='text-sm font-semibold'>

          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Attendances and Leaves
          </div>

          <div className='text-sm font-semibold'>

          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md space-y-4'>

      <div className='flex flex-row justify-between items-center mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>
          Attendances and Leaves
        </div>

        <div className='text-sm font-semibold'>
          {currentMonthData.currentMonthText}
        </div>

      </div>

      <div className="flex justify-between text-[12px] py-2 px-5 border-b">

        <div className="flex flex-col gap-y-4">
          <div>{currentMonthData.employeeWorkingDaysInAMonth}</div>
          <div>Working days</div>
        </div>

        <div className="flex flex-col gap-y-4">
          <div>{currentMonthData.attendancesInAMonth}</div>
          <div>Days worked</div>
        </div>

        <div className="flex flex-col gap-y-4">
          <div>{currentMonthData.grantedLeavesInAMonth}</div>
          <div>Leave Days Granted</div>
        </div>

      </div>

      <div className="flex justify-between text-[12px] py-2 px-5">

        <div className="flex flex-col gap-y-4">
          <div>{currentMonthData.pendingLeavesInAMonth}</div>
          <div>Leave Days Requested</div>
        </div>

      </div>

    </div>
  );
}
