import React from 'react';
import { useContext } from "react";
import { AuthContext } from "context/AuthContext";
import { DashboardSuperAdmin } from 'components/dashboardSuperAdmin/DashboardSuperAdmin.jsx';
import { DashboardHR } from 'components/dashboardHR/DashboardHR';
import { DashboardProjectManager } from 'components/dashboardProjectManager/DashboardProjectManager';
import { DashboardStoreManager } from 'components/dashboardStoreManager/DashboardStoreManager';
import { DashboardEmployee } from 'components/dashboardEmployee/DashboardEmployee';
import { DashboardStocks } from 'components/dashboardStocks/DashboardSuperAdmin';

export const StockDashboard = () => {
  const { userData } = useContext(AuthContext);

  console.log("Before Dashboard() rendering");

  let page;
  // if (userData.doc.isSuperAdmin || userData.doc.isAdmin)
  //   page = <DashboardSuperAdmin />;
  // else if (userData.role.create.includes("employees"))
  //   page = <DashboardHR />;
  // else if (userData.role.create.includes("projects"))
  //   page = <DashboardProjectManager />;
   if (userData.doc.isSuperAdmin || userData.doc.isAdmin||userData?.role?.read?.includes("stockitems"))
    page = <DashboardStocks />;
  else
    page = <DashboardEmployee />;

  return (
    <React.Fragment>
      {
        page
      }
    </React.Fragment>
  );
}
