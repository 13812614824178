import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './paymentVouchers.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { CiEdit } from "react-icons/ci";
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import Pagination from 'components/global/pagination';

export const PaymentVouchers = () => {
  const [message, setMessage] = useState(null);
  const [paymentVouchers, setPaymentVouchers] = useState(null);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/paymentvouchers?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const paymentVouchers = jsonData.data.docs;

    setPaymentVouchers(paymentVouchers);
    setTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [searchParams]);

  console.log("Before PaymentVouchers() rendering");

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Payment Vouchers</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let paymentVouchersList = [];

  if (paymentVouchers && paymentVouchers.length !== 0) { //on second render
    paymentVouchersList = paymentVouchers.map((paymentVoucher, index) => {
      let paymentMode;
      let paidAmount;
      if (paymentVoucher.paymentMode === 0) {
        paymentMode = "Online Bank Transfer";
        paidAmount = paymentVoucher.paidAmountOnlineTransfer;
      }
      else if (paymentVoucher.paymentMode === 1) {
        paymentMode = "Cheque";
        paidAmount = paymentVoucher.paidAmountCheque;
      }
      else if (paymentVoucher.paymentMode === 2) {
        paymentMode = "Cash";
        paidAmount = paymentVoucher.paidAmountCash;
      }
      let editLink = "/paymentvouchers/edit/" + paymentVoucher._id;
      let createMaterialReceiptSlip = "/materialreceiptslips/new/of-purchaseorder/" + paymentVoucher.purchaseOrderID._id;
      return (
        <tr key={paymentVoucher._id}>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{paymentVoucher.paymentVoucherID}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/vendors/${paymentVoucher.vendorID._id}`}>
              {paymentVoucher.vendorID.vendorCompanyName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{paymentVoucher.paymentVoucherCreator.full_name}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/projects/${paymentVoucher.projectID._id}`}>
              {paymentVoucher.projectID.projectName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">
            {paymentVoucher.selectedForMaterialReceiptSlip ?
              <Link to={createMaterialReceiptSlip} className='text-white bg-primary rounded-[40px] p-2 h-9'>New Material Receipt Slip</Link>
              : "Waiting for approval of payment voucher"}
          </td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{paymentMode}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{paidAmount}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link to={editLink} className='text-2xl text-primary'>
              <CiEdit />
            </Link>
          </td>
        </tr>
      );
    });
  }

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Payment Vouchers</h2>
        </div>
        <div className='flex flex-row justify-between'>
          {message && message}
        </div>
        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Vendor Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Creator</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Generate Material Receipt Slip</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Payment mode</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Amount paid</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Edit</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {paymentVouchersList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='12' >
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
