import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './employeesEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import Picture from '../../assets/Group 9998.png';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

// import Picture1 from '../../assets/Group 9999.png'

export const EmployeesEdit = () => {
  const [message, setMessage] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [departments, setDepartments] = useState(null);
  const [roles, setRoles] = useState(null);
  const [workingShifts, setWorkingShifts] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let url = baseURL + "/employees/" + params.id;
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employee = jsonData.data.doc;

    let getCompaniesURL = baseURL + "/companies/";
    console.log(getCompaniesURL)
    const getCompaniesResponse = await fetch(getCompaniesURL, {
      method: "GET",
      headers: headers
    });
    const getCompaniesJSONData = await getCompaniesResponse.json();
    if (!getCompaniesResponse.ok) {
      toast.error(getCompaniesJSONData.data.message);
      setMessage(getCompaniesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const companies = getCompaniesJSONData.data.docs;

    let getDepartmentsURL = baseURL + "/departments/of-company/" + employee.company._id; //get only those departments which belong to employee's company
    const getDepartmentsResponse = await fetch(getDepartmentsURL, {
      method: "GET",
      headers: headers
    });
    const getDepartmentsJSONData = await getDepartmentsResponse.json();
    if (!getDepartmentsResponse.ok) {
      toast.error(getDepartmentsJSONData.data.message);
      setMessage(getDepartmentsJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const departments = getDepartmentsJSONData.data.docs;

    let getRolesURL = baseURL + "/roles/";
    const getRolesResponse = await fetch(getRolesURL, {
      method: "GET",
      headers: headers
    });
    const getRolesJSONData = await getRolesResponse.json();
    if (!getRolesResponse.ok) {
      toast.error(getRolesJSONData.data.message);
      setMessage(getRolesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const roles = getRolesJSONData.data.docs;

    let getWorkingShiftsURL = baseURL + "/workinghours";
    const getWorkingShiftsResponse = await fetch(getWorkingShiftsURL, {
      method: "GET",
      headers: headers
    });
    const getWorkingShiftsJSONData = await getWorkingShiftsResponse.json();
    if (!getWorkingShiftsResponse.ok) {
      toast.error(getWorkingShiftsJSONData.data.message);
      setMessage(getWorkingShiftsJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const workingShifts = getWorkingShiftsJSONData.data.docs;

    setEmployee(employee);
    setCompanies(companies);
    setDepartments(departments);
    setRoles(roles);
    setWorkingShifts(workingShifts);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employees/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const convertManyFilesToBase64 = (fileHandles, numberOfFiles) => {
    console.log("Entering convertManyFilesToBase64");
    let promises = [];
    for (let i = 0; i < numberOfFiles; i++) {
      let fileHandle = fileHandles[i][1];
      promises.push(convertToBase64(fileHandle));
    }
    console.log("Exiting convertManyFilesToBase64");
    return Promise.all(promises);
  }

  const handleCompanyChange = async () => {
    setIsDepartmentsLoading(true);
    let companyObjectId = document.getElementsByName("company")[0].value;
    let url = baseURL + "/departments/of-company/" + companyObjectId;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    const departments = jsonData.data.docs;
    setDepartments(departments);
    setIsDepartmentsLoading(false);
  }

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImageSrc(imageUrl);
    }
  };

  async function handleEditEmployeeForm(event) {
    event.preventDefault();
    let errors = [];
    let createEmployeeForm = event.target;

    setIsSubmitting(true);
    setIsDisabled(true);

    let payload = {}; //empty object
    let full_name = createEmployeeForm.elements.namedItem("full_name")?.value;
    let father_name = createEmployeeForm.elements.namedItem("father_name")?.value;
    let email = createEmployeeForm.elements.namedItem("email")?.value;
    let employment_status = createEmployeeForm.elements.namedItem("employment_status")?.value;
    let gender = createEmployeeForm.elements.namedItem("gender")?.value;
    let birthdate = createEmployeeForm.elements.namedItem("birthdate")?.value;
    let joining_date = createEmployeeForm.elements.namedItem("joining_date")?.value;
    let cnic_number = createEmployeeForm.elements.namedItem("cnic_number")?.value;
    let mobile_phone_number = createEmployeeForm.elements.namedItem("mobile_phone_number")?.value;
    let referred_by = createEmployeeForm.elements.namedItem("referred_by")?.value;
    let permanent_address = createEmployeeForm.elements.namedItem("permanent_address")?.value;
    let mailing_address = createEmployeeForm.elements.namedItem("mailing_address")?.value;
    let salary = createEmployeeForm.elements.namedItem("salary")?.value;
    let company = createEmployeeForm.elements.namedItem("company")?.value;
    let department = createEmployeeForm.elements.namedItem("department")?.value;
    let role_slug = createEmployeeForm.elements.namedItem("role_slug")?.value;
    let workingShift = createEmployeeForm.elements.namedItem("workingShift").value;

    let ssc_board = createEmployeeForm.elements.namedItem("ssc_board")?.value;
    let ssc_passing_year = createEmployeeForm.elements.namedItem("ssc_passing_year")?.value;
    let ssc_obtained_marks = createEmployeeForm.elements.namedItem("ssc_obtained_marks")?.value;
    let ssc_total_marks = createEmployeeForm.elements.namedItem("ssc_total_marks")?.value;

    let hssc_board = createEmployeeForm.elements.namedItem("hssc_board")?.value;
    let hssc_passing_year = createEmployeeForm.elements.namedItem("hssc_passing_year")?.value;
    let hssc_obtained_marks = createEmployeeForm.elements.namedItem("hssc_obtained_marks")?.value;
    let hssc_total_marks = createEmployeeForm.elements.namedItem("hssc_total_marks")?.value;

    let bachelors_univ = createEmployeeForm.elements.namedItem("bachelors_univ")?.value;
    let bachelors_degree_title = createEmployeeForm.elements.namedItem("bachelors_degree_title")?.value;
    let bachelors_passing_year = createEmployeeForm.elements.namedItem("bachelors_passing_year")?.value;
    let bachelors_obtained_marks = createEmployeeForm.elements.namedItem("bachelors_obtained_marks")?.value;
    let bachelors_total_marks = createEmployeeForm.elements.namedItem("bachelors_total_marks")?.value;

    let masters_univ = createEmployeeForm.elements.namedItem("masters_univ")?.value;
    let masters_degree_title = createEmployeeForm.elements.namedItem("masters_degree_title")?.value;
    let masters_passing_year = createEmployeeForm.elements.namedItem("masters_passing_year")?.value;
    let masters_obtained_marks = createEmployeeForm.elements.namedItem("masters_obtained_marks")?.value;
    let masters_total_marks = createEmployeeForm.elements.namedItem("masters_total_marks")?.value;

    let ssc = {
      years_of_education: 10,
      degree_title: "Secondary School Certificate",
      awarding_org: ssc_board,
      passing_year: ssc_passing_year,
      obtained_marks: ssc_obtained_marks,
      total_marks: ssc_total_marks
    };
    let hssc = {
      years_of_education: 12,
      degree_title: "Higher Secondary School Certificate",
      awarding_org: hssc_board,
      passing_year: hssc_passing_year,
      obtained_marks: hssc_obtained_marks,
      total_marks: hssc_total_marks
    };
    let bachelors = {
      years_of_education: 16,
      degree_title: bachelors_degree_title,
      awarding_org: bachelors_univ,
      passing_year: bachelors_passing_year,
      obtained_marks: bachelors_obtained_marks,
      total_marks: bachelors_total_marks
    };
    let masters = {
      years_of_education: 18,
      degree_title: masters_degree_title,
      awarding_org: masters_univ,
      passing_year: masters_passing_year,
      obtained_marks: masters_obtained_marks,
      total_marks: masters_total_marks
    };

    let degrees = [];
    if (ssc.awarding_org.length > 0) {
      degrees.push(ssc);
    }
    if (hssc.awarding_org.length > 0) {
      degrees.push(hssc);
    }
    if (bachelors.awarding_org.length > 0) {
      degrees.push(bachelors);
    }
    if (masters.awarding_org.length > 0) {
      degrees.push(masters);
    }

    let pictures = [];
    let picture = createEmployeeForm.elements.namedItem("picture").files[0];
    let cnic_front = createEmployeeForm.elements.namedItem("cnic_front").files[0];
    let cnic_back = createEmployeeForm.elements.namedItem("cnic_back").files[0];
    let resume = createEmployeeForm.elements.namedItem("resume").files[0];
    let police_certificate = createEmployeeForm.elements.namedItem("police_certificate").files[0];

    if (picture) { //undefined if no file was selected
      if (((picture.size / 1024) / 1024) > 3) { //if greater than 3 megabytes
        errors.push("Profile picture size should be less than 3 MB");
      }
      else {
        pictures.push(['picture', picture]); //pushing file handles
      }
    }
    if (cnic_front) {
      if (((cnic_front.size / 1024) / 1024) > 3) {
        errors.push("CNIC Front size should be less than 3 MB");
      }
      else {
        pictures.push(['cnic_front', cnic_front]);
      }
    }
    if (cnic_back) {
      if (((cnic_back.size / 1024) / 1024) > 3) {
        errors.push("CNIC Back size should be less than 3 MB");
      }
      else {
        pictures.push(['cnic_back', cnic_back]);
      }
    }
    if (resume) {
      if (((resume.size / 1024) / 1024) > 3) {
        errors.push("Resume size should be less than 3 MB");
      }
      else {
        pictures.push(['resume', resume]);
      }
    }
    if (police_certificate) {
      if (((police_certificate.size / 1024) / 1024) > 3) {
        errors.push("Police certificate size should be less than 3 MB");
      }
      else {
        pictures.push(['police_certificate', police_certificate]);
      }
    }

    try {
      let numberOfFiles = pictures.length;
      if (numberOfFiles > 0) {
        let results = await convertManyFilesToBase64(pictures, numberOfFiles);
        for (let i = 0; i < numberOfFiles; i++) {
          payload[pictures[i][0]] = results[i];
        }
      }
    }
    catch (error) {
      console.log(error);
      return;
    }

    payload.full_name = full_name;
    payload.father_name = father_name;
    payload.email = email;
    payload.employment_status = employment_status;
    payload.gender = gender;
    payload.birthdate = birthdate;
    payload.joining_date = joining_date;
    payload.cnic_number = cnic_number;
    payload.mobile_phone_number = mobile_phone_number;
    payload.referred_by = referred_by;
    payload.permanent_address = permanent_address;
    payload.mailing_address = mailing_address;
    payload.salary = salary;
    payload.company = company;
    payload.department = department;
    payload.role_slug = role_slug;
    payload.workingShift = workingShift;
    payload.degrees = degrees;

    let roleID = roles.find((role) => role.slug === role_slug)._id;
    payload.roleID = roleID;

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit Employee</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let companiesList = [];
  let departmentsList = [];
  let rolesList = [];

  if (companies && companies.length !== 0) {
    companiesList = companies.map((company, index) => {
      return (
        <option key={index} value={company._id}>{company.name}</option>
      );
    });
  }

  if (departments && departments.length !== 0) {
    departmentsList = departments.map((department, index) => {
      return (
        <option key={index} value={department._id}>{department.department_name}</option>
      );
    });
  }

  if (roles && roles.length !== 0) {
    rolesList = roles.map((role, index) => {
      return (
        <option key={index} value={role.slug}>{role.name}</option>
      );
    });
  }

  let ssc_board = null;
  let ssc_passing_year = null;
  let ssc_obtained_marks = null;
  let ssc_total_marks = null;
  let hssc_board = null;
  let hssc_passing_year = null;
  let hssc_obtained_marks = null;
  let hssc_total_marks = null;
  let bachelors_univ = null;
  let bachelors_degree_title = null;
  let bachelors_passing_year = null;
  let bachelors_obtained_marks = null;
  let bachelors_total_marks = null;
  let masters_univ = null;
  let masters_degree_title = null;
  let masters_passing_year = null;
  let masters_obtained_marks = null;
  let masters_total_marks = null;


  for (let i = 0; i < employee?.degrees.length; i++) {
    switch (employee.degrees[i].years_of_education) {
      case 10:
        ssc_board = employee.degrees[i].awarding_org;
        ssc_passing_year = employee.degrees[i].passing_year;
        ssc_obtained_marks = employee.degrees[i].obtained_marks;
        ssc_total_marks = employee.degrees[i].total_marks;
        break;
      case 12:
        hssc_board = employee.degrees[i].awarding_org;
        hssc_passing_year = employee.degrees[i].passing_year;
        hssc_obtained_marks = employee.degrees[i].obtained_marks;
        hssc_total_marks = employee.degrees[i].total_marks;
        break;
      case 16:
        bachelors_univ = employee.degrees[i].awarding_org;
        bachelors_degree_title = employee.degrees[i].degree_title;
        bachelors_passing_year = employee.degrees[i].passing_year;
        bachelors_obtained_marks = employee.degrees[i].obtained_marks;
        bachelors_total_marks = employee.degrees[i].total_marks;
        break;
      case 18:
        masters_univ = employee.degrees[i].awarding_org;
        masters_degree_title = employee.degrees[i].degree_title;
        masters_passing_year = employee.degrees[i].passing_year;
        masters_obtained_marks = employee.degrees[i].obtained_marks;
        masters_total_marks = employee.degrees[i].total_marks;
        break;
      default:
        break;
    }
  }

  console.log("Before EmployeesEdit() rendering");
  //text-[#8E8EA1]

  return (
    <Layout>
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit Employee</h2>
        </div>

        <div className='flex flex-row justify-start px-3'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='flex flex-col px-4 border border-[#2F80ED] rounded-lg'>

          <form id="createEmployeeForm" onSubmit={handleEditEmployeeForm}>

            <div className='flex flex-col md:flex-row my-3'>

              <div
                className='flex flex-col justify-center w-full md:w-2/5'
                onClick={(e) => {
                  document.getElementById('profilePicture').click();
                }}
              >
                {
                  imageSrc ? (
                    <img
                      src={imageSrc}
                      alt='img'
                      id="profile-pic"
                      className='w-[209px] h-[209px] rounded-full mx-auto overflow-hidden cursor-pointer'
                    />
                  ) :
                    employee?.picture ? (
                      <img
                        src={staticFilesURL + "/employees/" + employee?.picture}
                        alt='img'
                        id="profile-pic"
                        className='w-[209px] h-[209px] rounded-full mx-auto overflow-hidden cursor-pointer'
                      />
                    ) : (
                      <img
                        src={Picture}
                        alt='img'
                        id="profile-pic"
                        className='w-[209px] h-[209px] rounded-full mx-auto overflow-hidden cursor-pointer'
                      />
                    )
                }
                <label
                  className='text-center text-[#2F80ED] cursor-pointer'
                  htmlFor='input-file'
                >
                  Upload Photo of Employee
                </label>
                <input
                  type="file"
                  name="picture"
                  accept='image/jpeg,image/png,image/jpg'
                  className='hidden'
                  id='profilePicture'
                  onChange={handleProfilePictureChange}
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-3/5'>

                <label
                  className='text-base font-normal pt-0 md:pt-12 text-left'
                >
                  Full Name <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  name="full_name"
                  placeholder='Employee Name'
                  defaultValue={employee?.full_name}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

                <label
                  className='text-base font-normal text-left'
                >
                  Father's Name <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  name="father_name"
                  placeholder='Employee Father Name'
                  defaultValue={employee?.father_name}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

                <label
                  className='text-base font-normal text-left'
                >
                  Custom ID
                </label>
                <input
                  type="text"
                  name="custom_id"
                  placeholder='Employee ID'
                  disabled="disabled"
                  defaultValue={employee?.custom_id}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

            </div>

            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label
                  className='text-base font-normal text-left'
                >
                  Gender <span className='required'>*</span>
                </label>
                <select
                  name="gender"
                  defaultValue={employee?.gender}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value="0">Male</option>
                  <option value="1">Female</option>
                  <option value="2">Others</option>
                </select>

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label
                  className='text-base font-normal text-left'
                >
                  Date of Birth <span className='required'>*</span>
                </label>
                <input
                  type="date"
                  name="birthdate"
                  defaultValue={employee?.birthdate.split('T')[0]}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label
                  className='text-base font-normal text-left'
                >
                  Date of Enrollment <span className='required'>*</span>
                </label>
                <input
                  type="date"
                  name="joining_date"
                  defaultValue={employee?.joining_date.split('T')[0]}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

            </div>

            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label
                  className='text-base font-normal text-left'
                >
                  CNIC <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  name="cnic_number"
                  placeholder='0000000000000'
                  defaultValue={employee?.cnic_number}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label
                  className='text-base font-normal text-left'
                >
                  Mobile Number <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  name="mobile_phone_number"
                  placeholder='Employee Phone Number'
                  defaultValue={employee?.mobile_phone_number}
                  className='border border-[#8E8EA1]  w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label
                  className='text-base font-normal text-left'
                >
                  Reference
                </label>
                <input
                  type="text"
                  name="referred_by"
                  placeholder='Name of Reference'
                  defaultValue={employee?.referred_by}
                  className='border border-[#8E8EA1]  w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

            </div>

            <div className='flex flex-col md:flex-row  space-y-3 md:space-y-0   my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3 '>

                <label
                  className='text-base font-normal text-left'
                >
                  Email
                </label>
                <input
                  type="text"
                  name="email"
                  placeholder='Email'
                  disabled="disabled"
                  defaultValue={employee?.email}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>
                  Permanent Address <span className='required'>*</span>
                </label>
                <input
                  type="text"
                  name="permanent_address"
                  placeholder='Permanent Address'
                  defaultValue={employee?.permanent_address}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>Mailing Address</label>
                <input
                  type="text"
                  name="mailing_address"
                  placeholder='Mailing Address'
                  defaultValue={employee?.mailing_address}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

            </div>

            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>Pay Decided <span className='required'>*</span></label>
                <input
                  type="number"
                  name="salary"
                  placeholder=''
                  defaultValue={employee?.salary}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>Company Name <span className='required'>*</span></label>
                <select
                  name="company"
                  onChange={handleCompanyChange}
                  defaultValue={employee?.company?._id}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                >
                  {companiesList}
                </select>

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>Employment Status <span className='required'>*</span></label>
                <select
                  name="employment_status"
                  defaultValue={employee?.employment_status}
                  className='border border-[#8E8EA1]  py-2 w-full md:w-11/12 rounded-[40px] px-3 outline-none text-sm'
                >
                  <option value="0">Active</option>
                  <option value="1">On Leave</option>
                  <option value="2">Terminated</option>
                  <option value="3">Resigned</option>
                </select>

              </div>

            </div>

            <div className='flex flex-col justify-start md:flex-row md:justify-center space-y-3 md:space-y-0 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-2/4'>

                <label className='text-base font-normal text-left'>Department <span className='required'>*</span></label>
                {
                  isDepartmentsLoading ? <div className='text-center md:pt-2'><ClipLoader size={17} color="#000" /></div>
                    : (
                      <select
                        name="department"
                        defaultValue={employee?.department?._id}
                        className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                      >
                        {departmentsList}
                      </select>
                    )
                }

              </div>

              <div className='flex flex-col   space-y-3 w-full md:w-2/4'>

                <label className='text-base font-normal text-left'>Designation <span className='required'>*</span></label>
                <select
                  name="role_slug"
                  defaultValue={employee?.role_slug}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                >
                  {rolesList}
                </select>

              </div>

              <div className='flex flex-col gap-y-3 w-full md:w-2/4'>
                <label className='text-base font-normal text-left'>Working Shift <span className='required'>*</span></label>
                <select
                  name="workingShift"
                  defaultValue={employee?.workingShift}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                >
                  {
                    workingShifts && workingShifts.length > 0 && workingShifts.map((shift) => {
                      return (
                        <option key={shift._id} value={shift._id}>{shift.shiftTitle}</option>
                      );
                    })
                  }
                </select>
              </div>

            </div>

            <div className='space-y-3 w-full my-3'>
              <h2 className='text-[#22242C] text-[22px]'>Add Educational Details</h2>
            </div>

            <div className=' space-y-3 w-full my-3'>
              <label className='text-[#22242C] text-[20px]'>SSC:</label>
            </div>

            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 space-x-0 md:space-x-3  my-3 w-full'>

              <div className='w-full md:w-1/4'>
                <input
                  type="text"
                  name="ssc_board"
                  defaultValue={ssc_board}
                  placeholder='Matric Board'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-1/4'>
                <input
                  type="text"
                  name="ssc_passing_year"
                  defaultValue={ssc_passing_year}
                  placeholder='Matric Passing Year'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-1/4' >
                <input
                  type="text"
                  name="ssc_obtained_marks"
                  defaultValue={ssc_obtained_marks}
                  placeholder='Matric Obtained Marks'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-1/4' >
                <input
                  type="text"
                  name="ssc_total_marks"
                  defaultValue={ssc_total_marks}
                  placeholder='Matric Total Marks'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

            </div>

            <div className='space-y-3 w-full my-3'>
              <label className='text-[#22242C] text-[20px]'>HSSC:</label>
            </div>

            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 space-x-0 md:space-x-3  my-3 w-full '>

              <div className='w-full md:w-1/4 '>
                <input
                  type="text"
                  name="hssc_board"
                  defaultValue={hssc_board}
                  placeholder='Inter Board'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-1/4'>
                <input
                  type="text"
                  name="hssc_passing_year"
                  defaultValue={hssc_passing_year}
                  placeholder='Inter Passing Year'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-1/4'>
                <input
                  type="text"
                  name="hssc_obtained_marks"
                  defaultValue={hssc_obtained_marks}
                  placeholder='Inter Obtained Marks'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-1/4'>
                <input
                  type="text"
                  name="hssc_total_marks"
                  defaultValue={hssc_total_marks}
                  placeholder='Inter Total Marks'
                  className='border border-[#8E8EA1] w-full py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

            </div>

            <div className='space-y-3 w-full my-3'>
              <label className='text-[#22242C] text-[20px]'>Bachelors:</label>
            </div>

            <div className='flex flex-col md:flex-wrap md:flex-row  space-y-3 md:space-y-0  my-3'>

              <div className='w-full md:w-2/6'>
                <input
                  type="text"
                  name="bachelors_univ"
                  defaultValue={bachelors_univ} placeholder='University'
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6'>
                <input
                  type="text"
                  name="bachelors_degree_title"
                  placeholder='Bachelors Degree Title'
                  defaultValue={bachelors_degree_title}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6' >
                <input
                  type="text"
                  name="bachelors_passing_year"
                  placeholder='Bachelors Passing Year'
                  defaultValue={bachelors_passing_year}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6 py-0 md:py-3'>
                <input
                  type="text"
                  name="bachelors_obtained_marks"
                  placeholder='Bachelors Obtained Marks or CGPA'
                  defaultValue={bachelors_obtained_marks}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6  py-0 md:py-3'>
                <input
                  type="text"
                  name="bachelors_total_marks" p
                  placeholder='Bachelors Total Marks or CGPA'
                  defaultValue={bachelors_total_marks}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2   rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

            </div>

            <div className=' space-y-3 w-full  my-3'>
              <label className='text-[#22242C] text-[20px]'>Masters: </label>
            </div>

            <div className='flex flex-col md:flex-wrap md:flex-row  space-y-3 md:space-y-0  my-3'>

              <div className=' w-full md:w-2/6'>
                <input
                  type="text"
                  name="masters_univ"
                  placeholder='University'
                  defaultValue={masters_univ}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6'>
                <input
                  type="text"
                  name="masters_degree_title"
                  placeholder='Masters Degree Title'
                  defaultValue={masters_degree_title}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6' >
                <input
                  type="text"
                  name="masters_passing_year"
                  placeholder='Masters Passing Year'
                  defaultValue={masters_passing_year}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

              <div className='w-full md:w-2/6 py-0 md:py-3'>
                <input
                  type="text"
                  name="masters_obtained_marks"
                  placeholder='Masters Obtained Marks or CGPA'
                  defaultValue={masters_obtained_marks}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm '
                />
              </div>

              <div className='w-full md:w-2/6 py-0 md:py-3 ' >
                <input
                  type="text"
                  name="masters_total_marks"
                  placeholder='Masters Total Marks or CGPA'
                  defaultValue={masters_total_marks}
                  className='border border-[#8E8EA1] w-full  md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'
                />
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3 my-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>CNIC Front</label>
                <input
                  type="file"
                  accept='image/*'
                  name="cnic_front"
                  className='border border-[#8E8EA1]  py-2 w-full md:w-11/12 rounded-[40px] px-3 outline-none text-sm'
                />
                {
                  employee?.cnic_front ?
                    <img
                      src={staticFilesURL + "/employees/" + employee.cnic_front}
                      width="200"
                      alt="cnic front"
                    />
                    :
                    null
                }

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>CNIC Back</label>
                <input
                  type="file"
                  accept='image/*'
                  name="cnic_back"
                  className='border border-[#8E8EA1]  py-2 w-full md:w-11/12 rounded-[40px] px-3 outline-none text-sm'
                />
                {
                  employee?.cnic_back ?
                    <img
                      src={staticFilesURL + "/employees/" + employee.cnic_back}
                      width="200"
                      alt="cnic back"
                    />
                    :
                    null
                }

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>Resume</label>
                <input
                  type="file"
                  accept='application/pdf'
                  name="resume"
                  className='border border-[#8E8EA1] py-2 w-full md:w-11/12 rounded-[40px] px-3 outline-none text-sm'
                />
                {
                  employee?.resume ?
                    <a
                      href={staticFilesURL + "/employees/" + employee.resume}
                      download
                      className='btn max-w-min'
                    >
                      Download
                    </a>
                    :
                    null
                }

              </div>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>

                <label className='text-base font-normal text-left'>Police Character Certificate</label>
                <input
                  type="file"
                  accept='image/*'
                  name="police_certificate"
                  className='border border-[#8E8EA1] py-2 w-full md:w-11/12 rounded-[40px] px-3 outline-none text-sm'
                />
                {
                  employee?.police_certificate ?
                    <img
                      src={staticFilesURL + "/employees/" + employee.police_certificate}
                      width="200"
                      alt="police certificate photograph"
                    />
                    :
                    null
                }

              </div>

            </div>

            <div className='flex flex-row justify-center my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                id="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </form>
        </div>
      </div>
    </Layout>
  )
}
