import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

export const MyTasksTableMini = () => {
  const [myTasks, setMyTasks] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let getMyTasksURL = baseURL + "/employeestasks/my-tasks";
      const getMyTasksResponse = await fetch(getMyTasksURL, {
        method: "GET",
        headers: headers
      });
      const getMyTasksJSONData = await getMyTasksResponse.json();
      if (!getMyTasksResponse.ok) {
        toast.error(getMyTasksJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      let numberOfRecords = Math.min(getMyTasksJSONData.data.docsCount, 6);
      let myTasks = [];

      for (let i = 0; i < numberOfRecords; i++) {
        myTasks.push(
          <div key={i} className='flex text-xs border-b border-buttonBackground px-3 py-2'>
            <div className='w-[10%]'>
              <span className='flex justify-center w-4 h-4 bg-buttonBackground rounded-full'>
                {getMyTasksJSONData.data.docs[i].employeeTaskID}
              </span>
            </div>
            <div className='w-[40%]'>
              {getMyTasksJSONData.data.docs[i].taskTitle}
            </div>
            <div className='w-[25%] flex justify-center'>
              {getMyTasksJSONData.data.docs[i].taskStartByDate.split('T')[0]}
            </div>
            <div className='w-[25%] flex justify-center'>
              {getMyTasksJSONData.data.docs[i].taskFinishByDate.split('T')[0]}
            </div>
          </div>
        );
      }

      setMyTasks(myTasks);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      toast.error("An error occurred while communicating with the server.");
    });
  }, [userData]);

  if (isLoading) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            My Tasks
          </div>

          <div>
            <Link
              to="/mytasks"
              className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
            >
              View All
            </Link>
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            My Tasks
          </div>

          <div>
            <Link
              to="/mytasks"
              className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
            >
              View All
            </Link>
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md'>
      <div className='flex flex-row justify-between mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>My Tasks</div>

        <div>
          <Link
            to="/mytasks"
            className="bg-buttonBackground text-xs px-2 py-1 rounded-md"
          >
            View All
          </Link>
        </div>

      </div>

      <div className='flex flex-row text-xs font-medium bg-buttonBackground rounded-md px-3 py-2'>
        <div className='w-[10%]'>
          ID
        </div>
        <div className='w-[40%]'>
          Title
        </div>
        <div className='w-[25%] flex justify-center'>
          Start Date
        </div>
        <div className='w-[25%] flex justify-center'>
          Finish Date
        </div>
      </div>

      {myTasks}

    </div>
  );
}
