import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './quotations.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loader from 'components/global/Loader';
import Pagination from 'components/global/pagination';
import { toast } from 'react-toastify';

export const Quotations = () => {
  const [message, setMessage] = useState(null);
  const [quotations, setQuotations] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);

  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);

  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/quotations?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const quotations = jsonData.data.docs;

    setQuotations(quotations);
    setTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Quotations</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let quotationsList = [];

  if (quotations && quotations.length !== 0) { //on second render
    quotationsList = quotations.map((quotation, index) => {
      let editLink = "/quotations/edit/" + quotation._id;
      const newPOLink = "/purchaseorders/new/of-quotation/" + quotation._id;
      return (
        <tr key={quotation._id}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{quotation.quotationID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{quotation.vendorID.vendorCompanyName}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{quotation.quotationCreator.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/projects/${quotation.projectID._id}`}>
              {quotation.projectID.projectName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{quotation.demandID.demandID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            {
              quotation.selectedForPurchaseOrder ?
                <Link className='text-white bg-primary rounded-[40px] p-2 h-9' to={newPOLink}>
                  New Purchase Order
                </Link>
                : "No approval yet"
            }
          </td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
          <td className='px-6 py-4 whitespace-nowrap text-center'>
            <button onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button>
          </td>
        </tr>
      );
    });
  }

  console.log("Before Quotations() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Quotations</h2>
        </div>
        <div className='flex flex-row justify-between'>
          {message && message}
        </div>
        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className='w-full table-auto overflow-x-auto'>
            <thead className='text-[14px] border border-b-[#6843BE] '>
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Vendor Company Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Quotation Creator</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Demand ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Create Purchase Order</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {quotationsList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='9'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
