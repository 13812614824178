import React from 'react'

export const DocumentsSVG = ({ width, height }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.214 4.88005L12.9032 1.29566C12.4518 1.10102 11.9595 1.00009 11.4612 1C10.9628 0.999905 10.4705 1.10064 10.019 1.29511L1.78658 4.84059C1.55159 4.94178 1.35276 5.10273 1.21343 5.30454C1.0741 5.50636 1.00006 5.74067 1 5.97996C0.999938 6.21925 1.07386 6.45359 1.21308 6.65547C1.3523 6.85735 1.55105 7.01839 1.78599 7.11968L10.0968 10.7041C10.5482 10.8988 11.0404 10.9998 11.5388 11C12.0371 11.0002 12.5294 10.8995 12.981 10.7052L21.2134 7.15913C21.4484 7.05795 21.6472 6.897 21.7866 6.69518C21.9259 6.49337 21.9999 6.25906 22 6.01976C22.0001 5.78047 21.9261 5.54613 21.7869 5.34425C21.6477 5.14237 21.449 4.98134 21.214 4.88005Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.10309 9L1.78719 10.3175C1.55205 10.4108 1.35306 10.5593 1.21361 10.7455C1.07417 10.9318 1.00006 11.148 1 11.3689C0.999938 11.5898 1.07392 11.8061 1.21326 11.9924C1.3526 12.1787 1.5515 12.3272 1.7866 12.4206L10.0971 15.727C10.5486 15.9066 11.0408 15.9998 11.5391 16C12.0374 16.0002 12.5297 15.9073 12.9813 15.728L21.2134 12.457C21.4484 12.3637 21.6473 12.2152 21.7866 12.029C21.9259 11.8428 21.9999 11.6266 22 11.4059C22.0001 11.1851 21.9261 10.9689 21.7869 10.7827C21.6477 10.5964 21.449 10.4478 21.214 10.3544L17.8975 9.03488" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.10309 14L1.78719 15.5058C1.55205 15.6124 1.35306 15.7821 1.21361 15.9949C1.07417 16.2078 1.00006 16.455 1 16.7074C0.999938 16.9598 1.07392 17.207 1.21326 17.42C1.3526 17.6329 1.5515 17.8027 1.7866 17.9094L10.0971 21.6883C10.5486 21.8935 11.0408 21.9999 11.5391 22C12.0375 22.0001 12.5297 21.8939 12.9813 21.6888L21.2134 17.9504C21.4484 17.8438 21.6473 17.674 21.7866 17.4613C21.9259 17.2485 21.9999 17.0014 22 16.7491C22.0001 16.4968 21.9261 16.2497 21.7869 16.0368C21.6477 15.824 21.449 15.6542 21.214 15.5474L17.8975 14.0393" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
    </svg>

  );
}