import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './materialReceiptSlips.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { CiEdit } from "react-icons/ci";
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import Pagination from 'components/global/pagination';

export const MaterialReceiptSlips = () => {
  const [message, setMessage] = useState(null);
  const [materialReceiptSlips, setMaterialReceiptSlips] = useState(null);
  const [category, setCategory] = useState("allCategories");
  const [categories, setCategories] = useState(null);
  const [totalMaterialReceiptSlips, setTotalMaterialReceiptSlips] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/materialreceiptslips?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const materialReceiptSlips = jsonData.data.docs;

    let getCategoriesURL = baseURL + "/stockcategories";
    const getCategoriesResponse = await fetch(getCategoriesURL, {
      method: "GET",
      headers: headers
    });
    const getCategoriesJSONData = await getCategoriesResponse.json();
    if (!getCategoriesResponse.ok) {
      toast.error(getCategoriesJSONData.data.message);
      setMessage(getCategoriesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const categories = getCategoriesJSONData.data.docs;

    setMaterialReceiptSlips(materialReceiptSlips);
    setTotalMaterialReceiptSlips(jsonData.data.docsCount);
    setCategories(categories);
    setIsLoading(false);
  }

  const handleCategoryChange = async (e) => {
    if (!isLoading) {
      setIsLoading(true);
    }

    setCategory(e.target.value);

    let url;
    if (e.target.value === "allCategories")
      url = `${baseURL}/materialreceiptslips?page=${page}`;
    else
      url = `${baseURL}/materialreceiptslips?stockCategoryID=${e.target.value}&page=${page}`;

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    const materialReceiptSlips = jsonData.data.docs;

    setMaterialReceiptSlips(materialReceiptSlips);
    setTotalMaterialReceiptSlips(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = () => {

  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  console.log("Before MaterialReceiptSlips() rendering");

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Material Receipt Slips</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let materialReceiptSlipsList = [];

  if (materialReceiptSlips && materialReceiptSlips.length !== 0) {
    materialReceiptSlipsList = materialReceiptSlips.map((materialReceiptSlip, index) => {
      let editLink = "/materialreceiptslips/edit/" + materialReceiptSlip._id;
      return (
        <tr key={materialReceiptSlip._id}>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{materialReceiptSlip.materialReceiptSlipID}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{materialReceiptSlip.vendorID.vendorCompanyName}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">{materialReceiptSlip.materialReceiptSlipCreator.full_name}</td>
          <td className="text-gray-900 px-6 py-3 whitespace-nowrap">
            <Link className='text-white bg-primary rounded-[40px] p-2' to={`/projects/${materialReceiptSlip.purchaseOrderID.quotationID.projectID._id}`}>
              {materialReceiptSlip.purchaseOrderID.quotationID.projectID.projectName}
            </Link>
          </td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">
            <Link to={editLink} className='text-2xl text-primary'>
              <CiEdit />
            </Link>
          </td>
        </tr>
      );
    });
  }
  if (materialReceiptSlips && materialReceiptSlips.length === 0) {
    materialReceiptSlipsList = (
      <tr>
        <td colspan={6} className="text-gray-900 px-6 py-4 whitespace-nowrap">
          No material receipt slips found!
        </td>
      </tr>
    )
  }

  return (
    <Layout>
      <div className='space-y-4'>

        <div className='flex flex-row justify-between items-center px-3'>

          <h2 className='text-2xl text-primary font-bold'>Material Receipt Slips</h2>

          <div className='flex flex-row gap-3 justify-around'>

            <div className='flex flex-row items-center bg-[#90288F] px-3 py-2 rounded-3xl'>
              <div className='flex flex-row items-center text-white text-sm space-x-1'>
                <select
                  name="category"
                  onChange={handleCategoryChange}
                  value={category}
                  className='bg-[#90288F] rounded-[40px] outline-none text-sm'
                >
                  <option value="allCategories">All Categories</option>
                  {
                    categories && categories.length !== 0 && categories.map((c, index) => {
                      return (
                        <option key={c._id} value={c._id}>{c.stockCategoryName}</option>
                      );
                    })
                  }
                </select>
              </div>
            </div>

          </div>

        </div>

        <div className='flex flex-row justify-between'>
          {message && message}
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr className='bg-lightSlate'>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Vendor Company Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Material Receipt Slip Creator</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Project Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Edit</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {materialReceiptSlipsList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='5'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalMaterialReceiptSlips / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
