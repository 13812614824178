import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import styles from './projectsCategoriesEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';

export const ProjectsCategoriesEdit = () => {
  const [message, setMessage] = useState(null);
  const [projectCategory, setProjectCategory] = useState(null);
  const { userData } = useContext(AuthContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);

  async function fetchData() {
    setLoading(true)
    const getProjectCategoryURL = baseURL + "/projectscategories/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getProjectCategoryResponse = await fetch(getProjectCategoryURL, {
      method: "GET",
      headers: headers
    });
    const getProjectCategoryJSONData = await getProjectCategoryResponse.json();
    const pc = getProjectCategoryJSONData.data.doc;

    setProjectCategory(pc);
    setLoading(false)

  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/projectscategories/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setMessage(jsonData.data.message);
    }
    else {
      //setMessage("An error occurred.");
      setMessage(jsonData.data.message);
    }
  }

  async function handleEditProjectCategoryForm(event) {
    event.preventDefault();
    let editProjectCategoryForm = event.target;
    let payload = {}; //empty object
    let projectCategoryName = editProjectCategoryForm.elements.namedItem("projectCategoryName").value;
    payload.projectCategoryName = projectCategoryName;
    sendAPIRequest(payload);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // if (!projectCategory) { //on first render
  //   return (
  //     <main className={styles["projectsCategories-edit"]}>
  //       <h2>Edit Project Category</h2>
  //       {message && message}
  //       <div>Fetching data from the server. Please wait!</div>
  //     </main>
  //   );
  // }

  console.log("Before ProjectsCategoriesEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Edit Category</h2>
        </div>
        {message && message}
        {loading ? <Loader /> : <form id="editProjectCategoryForm" onSubmit={handleEditProjectCategoryForm}>
          <div className='flex flex-col space-y-3 w-full md:w-2/5'>
            <label className='text-[#8E8EA1] text-base font-normal text-left'>
              Project Category Name <span className='required'>*</span>
            </label>
            <input type="text" name="projectCategoryName" defaultValue={projectCategory?.projectCategoryName} className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' />
          </div>
          {/* <p>Project Category Name: <input type="text" name="projectCategoryName" defaultValue={projectCategory.projectCategoryName}  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' /></p> */}
          <div className='flex flex-row justify-start my-5    '>
            <input type="submit" name="submit" value="Submit" className='text-white bg-[#404194] rounded-[40px] w-[299px] h-9' />
          </div>
          {/* <input type="submit" name="submit" value="Submit" /> */}
        </form>}
      </div>
    </Layout>

  )
}
