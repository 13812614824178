import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
//import styles from './roles.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { FaPlus } from "react-icons/fa6";
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

export const Roles = () => {
  const [message, setMessage] = useState(null);
  const [roles, setRoles] = useState(null);
  const [loading, setLoading] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    setLoading(true);
    let url = baseURL + "/roles/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    setLoading(false);
    if (!response.ok) {
      setMessage(jsonData.data.message);
      return; //early return
    }
    const roles = jsonData.data.docs;
    setRoles(roles);
  }

  const handleDelete = async (e) => {
    let deleteButton = e.target;
    let id = deleteButton.getAttribute("data-id");
    let url = baseURL + "/roles/delete/" + id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers
    });
    const jsonData = await response.json();
    setMessage(jsonData.data.message);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  // if(!roles) { //on first render or when there HTTP response code is not of 200 series
  //   return (
  //     <Layout>
  //     <div className={styles["roles-list"]}>
  //       <h2>Roles</h2>
  //       {message && message}
  //       <div>Fetching data from the server. Please wait!</div>
  //     </div>
  //     </Layout>

  //   );
  // }

  let rolesList = [];

  if (roles && roles.length !== 0) {
    rolesList = roles.map((role, index) => {
      let editLink = "/roles/edit/" + role._id;
      return (
        <tr key={role._id}>
          <td className=" text-gray-900  px-6 py-3 whitespace-nowrap">{role.name}</td>
          <td className=" text-gray-900  px-6 py-3 whitespace-nowrap">{role.slug}</td>
          <td className="   text-primary px-6 py-3 whitespace-nowrap   "><Link to={editLink}><CiEdit className='text-2xl ' /></Link></td>
          <td className="    whitespace-nowrap   "><button data-id={role._id} onClick={handleDelete} className='px-8 py-3'><RiDeleteBin6Line className='text-lg text-[#E30411]' /></button></td>
        </tr>
      );
    });
  }

  console.log("Before Roles() rendering");

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold	'>Roles</h2>
          <NavLink to="/roles/new">
            <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
              <span><FaPlus /></span>   <span>Add Role</span>
            </button>
          </NavLink>
        </div>
        <div className='flex flex-row justify-between'>
          {message && message}
        </div>
        <div className='overflow-auto rounded-lg  border border-[#6843BE] '>
          {loading ? <Loader /> :
            <table className="w-full">
              <thead className="   text-[14px] border border-b-[#6843BE]   ">
                <tr className='bg-lightSlate'>
                  <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Role Name</th>
                  <th scope="col" className="  font-semibold text-primary px-6 py-4 text-left">Role Slug</th>
                  <th scope="col" className="   font-semibold text-primary px-6 py-4 text-left">Edit</th>
                  <th scope="col" className="   font-semibold text-primary px-6 py-4 text-left">Delete</th>
                </tr>
              </thead>
              <tbody className='text-[13px] p-4'>
                {rolesList}
              </tbody>
            </table>


          }

        </div>


      </div>

    </Layout>

  )
}
