import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './employeesTasksView.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { BsCalendar3 } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { toast } from 'react-toastify';
import { PDFViewer } from 'components/PDFViewer/PDFViewer.jsx';
import { TaskTabSubtasksView } from 'components/taskTabSubtasks/TaskTabSubtasksView.jsx';
import { TaskTabAttachmentsView } from 'components/taskTabAttachments/TaskTabAttachmentsView.jsx';
import { TaskTabDiscussion } from 'components/taskTabDiscussion/TaskTabDiscussion.jsx';

export const EmployeesTasksView = () => {
  const [message, setMessage] = useState(null);
  const [task, setTask] = useState(null);
  const [activeTab, setActiveTab] = useState("overview");
  const [attachments, setAttachments] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    const getTaskURL = baseURL + "/employeestasks/my-tasks/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getTaskResponse = await fetch(getTaskURL, {
      method: "GET",
      headers: headers
    });
    const getTaskJSONData = await getTaskResponse.json();
    if (!getTaskResponse.ok) {
      toast.error(getTaskJSONData.data.message);
      setMessage(getTaskJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const task = getTaskJSONData.data.doc;

    setTask(task);
    setAttachments(task.attachments);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employee Task</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let attachedFilesList = [];

  if (attachments && attachments.length > 0) {
    const openWithInWebApp = ["jpeg", "jpg", "png"];

    attachedFilesList = attachments.map((attachment, index) => {
      let link = "#"
      if (attachment.fileNameOnServerDisk)
        link = staticFilesURL + "/employees/" + attachment.fileNameOnServerDisk;

      const tokens = attachment.fileNameOnServerDisk.split('.');

      let content = null;

      if ("pdf".localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
        content = <PDFViewer link={link} />
      }
      else {
        for (const ext of openWithInWebApp) {
          if (ext.localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
            content = <img src={link} alt="attached photograph" />
          }
        }
      }

      if (!content) {
        content = "No preview available";
      }

      return (
        <div key={index} className='flex flex-col gap-y-3'>
          <div>
            {`${index + 1}) ${attachment.clientFileName}`}
          </div>
          <div className='flex flex-row border border-[#8E8EA1] rounded-[10px]'>
            <div className='border border-r-[#8E8EA1] p-3 md:w-5/6'>
              {
                content
              }
            </div>
            <div className='p-3 md:w-1/6'>
              <a
                key={attachment._id}
                className='btn-primary justify-center'
                href={link}
                download
              >
                Download
              </a>
            </div>
          </div>
        </div>
      );
    })
  }
  else {
    attachedFilesList = <div>"No files attached!"</div>;
  }

  console.log("Before EmployeesTasksView() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5 text-gray-900 text-sm'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employee Task</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-primary p-8 space-y-3'>

          <div className='flex flex-row gap-2 mb-6'>
            <div>
              <button
                onClick={(e) => setActiveTab("overview")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Overview
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("subtasks")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Subtasks
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("discussion")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Discussion
              </button>
            </div>

            <div>
              <button
                onClick={(e) => setActiveTab("attachments")}
                className='bg-primary py-1 px-4 text-white border rounded shadow-md'
              >
                Files
              </button>
            </div>
          </div>

          {
            activeTab === "overview" && (
              <div>
                <div className="flex flex-col max-w-screen-sm">

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Title</div>
                    <div className="w-1/2">{task.taskTitle}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Start Date</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsCalendar3 />{task.taskStartByDate.split('T')[0]}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Approx. Finish Date</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsCalendar3 />{task.taskFinishByDate.split('T')[0]}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Actual Finish Date</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsCalendar3 />{task.taskActualFinishDate?.split('T')[0]}</div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Supervisor</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsPeopleFill />
                      {
                        task.supervisor.full_name
                      }
                    </div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Team</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center"><BsPeopleFill />
                      {
                        task.employeeIDs.map(
                          (employee) => {
                            return employee.full_name;
                          }
                        ).join(', ')
                      }
                    </div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Status</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center">
                      {
                        task.taskStatus === 0 ? "Pending" : (task.taskStatus === 1 ? "Finished" : "")
                      }
                    </div>
                  </div>

                  <div className="flex flex-row py-2 my-2 border-b">
                    <div className="w-1/2">Priority</div>
                    <div className="w-1/2 flex flex-row gap-2 items-center">
                      {
                        task.taskPriority === 0 ? "Low"
                          :
                          task.taskPriority === 1 ? "Normal"
                            :
                            task.taskPriority === 2 ? "High"
                              :
                              task.taskPriority === 3 ? "Urgent"
                                : ""
                      }
                    </div>
                  </div>

                  <div className="flex flex-row py-2 my-2">
                    <div className="w-1/2">Description</div>
                    <div className="w-1/2">{task.taskDescription}</div>
                  </div>

                </div>
              </div>
            )
          }

          {
            activeTab === "subtasks" && (
              <TaskTabSubtasksView subtasks={task.subtasks} setTask={setTask} params={params} />
            )
          }

          {
            activeTab === "discussion" && (
              <TaskTabDiscussion comments={task.comments} setTask={setTask} params={params} />
            )
          }

          {
            activeTab === "attachments" && (
              <TaskTabAttachmentsView attachments={task.attachments} setTask={setTask} params={params} />
            )
          }

        </div>

      </div >

    </Layout >
  );
}