import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import './employeesSalaries.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Pagination from 'components/global/pagination';
import Loader from 'components/global/Loader';
import { CiEdit } from "react-icons/ci";
import { toast } from 'react-toastify';

export const EmployeesSalaries = () => {
  const [message, setMessage] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    let url = `${baseURL}/employees?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();

    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }

    const employeesArray = jsonData.data.docs;

    setEmployees(employeesArray);
    setTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      toast.error("An error occurred while communicating with the server.");
      setMessage("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employees Salaries</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesList = [];

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      let editLink = "/employees/edit/" + employee._id;
      let employment_status;

      if (employee.employment_status === 0)
        employment_status = "Active";
      else if (employee.employment_status === 1)
        employment_status = "On Leave";
      else if (employee.employment_status === 2)
        employment_status = "Terminated";
      else if (employee.employment_status === 3)
        employment_status = "Resigned";

      return (
        <tr key={index}>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.employeeID}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.full_name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.custom_id}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.roleID.name}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employment_status}</td>
          <td className="text-gray-900 px-6 py-4 whitespace-nowrap">{employee.salary}</td>
          <td className="px-6 py-4 whitespace-nowrap">
            <Link to={editLink}>
              <CiEdit className='text-2xl text-primary' />
            </Link>
          </td>
        </tr>
      );
    });
  }

  console.log("Before EmployeesSalaries() rendering");

  return (
    <Layout>
      <div className='space-y-5 '>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employees Salaries</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto ">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Full Name</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Employee ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Role</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Salary</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {employeesList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='9'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(totalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}