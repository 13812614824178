import React, { useState, useContext } from 'react';
import { baseURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import { ClipLoader } from 'react-spinners';
import { RiDeleteBin6Line } from "react-icons/ri";

export const TaskTabSubtasks = ({ initialSubtasks, setTask, employees, params }) => {
  const [addNewSubtaskMessage, setAddNewSubtaskMessage] = useState(null);
  const [subtasks, setSubtasks] = useState(initialSubtasks);

  const [newSubtaskTitle, setNewSubtaskTitle] = useState("");
  const [newSubtaskStatus, setNewSubtaskStatus] = useState("");
  const [newSubtaskEmployeeID, setNewSubtaskEmployeeID] = useState("");
  const [newSubtaskDescription, setNewSubtaskDescription] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isNewSubtaskSubmitting, setIsNewSubtaskSubmitting] = useState(false);
  const [isNewSubtaskDisabled, setIsNewSubtaskDisabled] = useState(false);

  const [isSubtaskDeleting, setIsSubtaskDeleting] = useState({
    isDeleting: false,
    subtaskObjectId: '',
  });

  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload, endpoint = "") {
    let url;
    if (endpoint) {
      url = "" + baseURL + endpoint + params.id;
    }
    else {
      url = baseURL + "/employeestasks/update-subtasks/" + params.id;
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setTask(jsonData.data.doc);
      setSubtasks(jsonData.data.doc.subtasks);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleSubtasksEdit(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    for (let subtask of subtasks) {
      delete subtask._id;
      if (typeof subtask.employeeID === "string") {
        // do nothing
      }
      else { // an object
        const employeeID = subtask.employeeID._id;
        subtask.employeeID = employeeID;
      }
    }

    let payload = {
      subtasks
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  const handleAddNewSubtask = async (e) => {
    e.preventDefault();
    let errors = [];

    setIsNewSubtaskSubmitting(true);
    setIsNewSubtaskDisabled(true);

    if (!newSubtaskTitle) {
      errors.push(<div>Subtask title can't be empty</div>);
    }

    if (!newSubtaskEmployeeID) {
      errors.push(<div>Subtask should be assigned to an employee</div>);
    }

    if ((newSubtaskStatus !== 0) && (newSubtaskStatus !== 1)) {
      errors.push(<div>Subtask status should be selected</div>);
    }

    let payload = {
      subtaskTitle: newSubtaskTitle,
      subtaskStatus: newSubtaskStatus,
      employeeID: newSubtaskEmployeeID,
      subtaskDescription: newSubtaskDescription
    }

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setAddNewSubtaskMessage(errors);
      setIsNewSubtaskSubmitting(false);
      setIsNewSubtaskDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload, "/employeestasks/add-new-subtask/");
        setIsNewSubtaskSubmitting(false);
        setIsNewSubtaskDisabled(false);
        setNewSubtaskTitle("");
        setNewSubtaskStatus("");
        setNewSubtaskEmployeeID("");
        setNewSubtaskDescription("");
        setAddNewSubtaskMessage("");
      }
      catch (error) {
        setIsNewSubtaskSubmitting(false);
        setIsNewSubtaskDisabled(false);
      }
    }
  }

  console.log("Before TaskTabSubtasks() rendering");

  return (
    <React.Fragment>
      {
        subtasks && subtasks.length > 0 ? subtasks.map((subtask, index) => {

          return (
            <div key={subtask._id} className='space-y-3 text-sm bg-darkSlate p-4 rounded'>

              <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2">

                <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
                  <div>
                    <label
                      className='font-normal text-left'
                    >
                      Title <span className="required">*</span>
                    </label>
                  </div>
                  <div>
                    <input
                      type="text"
                      name="subtaskTitle"
                      value={subtask.subtaskTitle}
                      onChange={(e) => {
                        subtask.subtaskTitle = e.target.value;
                        subtasks[index] = subtask;
                        setSubtasks([...subtasks]);
                      }
                      }
                      className='border border-[#8E8EA1] rounded w-full md:w-11/12 py-2 px-3 outline-none'
                    />
                  </div>
                </div>

                <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
                  <div>
                    <label
                      className='font-normal text-left'
                    >
                      Status <span className="required">*</span>
                    </label>
                  </div>
                  <div className='w-full md:w-11/12'>
                    <SelectBox
                      name="subtaskStatus"
                      handleChange={(selectedOption) => {
                        subtask.subtaskStatus = selectedOption.value;
                        subtasks[index] = subtask;
                        setSubtasks([...subtasks]);
                      }
                      }
                      defaultValue={subtask.subtaskStatus}
                      options=
                      {
                        [
                          { val: 0, lab: "Pending" },
                          { val: 1, lab: "Finished" }
                        ].map(
                          (element, index) => {
                            return {
                              value: element.val,
                              label: element.lab
                            }
                          }
                        )
                      }
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: '#8E8EA1',
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
                  <div>
                    <label
                      className='font-normal text-left'
                    >
                      Assigned to <span className="required">*</span>
                    </label>
                  </div>
                  <div className='w-full md:w-11/12'>
                    <SelectBox
                      name="employeeID"
                      handleChange={(selectedOption) => {
                        subtask.employeeID = selectedOption.value;
                        subtasks[index] = subtask;
                        setSubtasks([...subtasks]);
                      }
                      }
                      defaultValue={subtask.employeeID._id ? subtask.employeeID._id : subtask.employeeID}
                      options={employees && employees.length > 0 && employees.map((employee, index) => {
                        return {
                          value: employee._id,
                          label: `${employee.full_name} ${employee.custom_id}`
                        }
                      })}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: '#8E8EA1',
                        }),
                      }}
                    />
                  </div>
                </div>

              </div>

              <div className='flex flex-col gap-y-1'>
                <div>
                  <label
                    className='font-normal text-left'
                  >
                    Description
                  </label>
                </div>
                <div className='w-full'>
                  <textarea
                    name="subtaskDescription"
                    rows="4"
                    value={subtask.subtaskDescription ? subtask.subtaskDescription : ""}
                    onChange={
                      (e) => {
                        subtask.subtaskDescription = e.target.value;
                        subtasks[index] = subtask;
                        setSubtasks([...subtasks]);
                      }
                    }
                    className='border border-[#8E8EA1] w-full py-1 rounded px-2 outline-none'
                  >
                  </textarea>
                </div>
              </div>

              <button
                className='flex flex-row gap-2'
                onClick={
                  async (e) => {
                    e.stopPropagation();
                    try {
                      setIsSubtaskDeleting({
                        isDeleting: true,
                        subtaskObjectId: subtask._id,
                      });
                      let payload = {
                        subTaskObjectId: subtask._id,
                        employeeTaskObjectId: params.id,
                      }
                      let url = `${baseURL}/employeestasks/delete-subtask`;
                      const headers = {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + userData.token
                      };
                      const response = await fetch(url, {
                        method: 'DELETE',
                        headers: headers,
                        body: JSON.stringify(payload)
                      });
                      const jsonData = await response.json();
                      if (jsonData.success) {
                        toast.success(jsonData.data.message);
                        setTask(jsonData.data.doc);
                        setSubtasks(jsonData.data.doc.subtasks);
                        setIsSubtaskDeleting({
                          isDeleting: false,
                          subtaskObjectId: '',
                        });
                      }
                      else {
                        toast.error(jsonData.data.message);
                        setIsSubtaskDeleting({
                          isDeleting: false,
                          subtaskObjectId: '',
                        });
                      }
                    }
                    catch (error) {
                      toast.error("An error occurred while communicating with the server.");
                    }
                  }
                }
                disabled={(isSubtaskDeleting.subtaskObjectId === subtask._id && isSubtaskDeleting.isDeleting)}
              >
                {
                  (isSubtaskDeleting.subtaskObjectId === subtask._id && isSubtaskDeleting.isDeleting) ?
                    (
                      <ClipLoader size={18} color='#000' />
                    ) : (
                      <React.Fragment>
                        <RiDeleteBin6Line className='text-lg text-[#E30411]' />
                        Delete
                      </React.Fragment>
                    )
                }
              </button>

            </div>

          )
        }) : (
          <div>No subtasks!</div>
        )
      }

      {
        subtasks.length > 0 && (
          <div className='flex flex-row gap-3 my-5'>
            <button
              disabled={isSubmitting || isDisabled}
              onClick={handleSubtasksEdit}
              className='bg-primary py-1 px-4 text-white border rounded shadow-md'
            >
              {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Update All"}
            </button>
          </div>
        )
      }

      <div className='space-y-3 text-sm bg-darkSlate p-4 rounded'>

        <div className="flex flex-col md:flex-row md:flex-wrap gap-y-2">

          <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
            <div>
              <label
                className='font-normal text-left'
              >
                Title <span className="required">*</span>
              </label>
            </div>
            <div>
              <input
                type="text"
                name="newSubtaskTitle"
                value={newSubtaskTitle}
                onChange={
                  (e) => {
                    setNewSubtaskTitle(e.target.value);
                  }
                }
                className='border border-[#8E8EA1] rounded w-full md:w-11/12 py-2 px-3 outline-none'
              />
            </div>
          </div>

          <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
            <div>
              <label
                className='font-normal text-left'
              >
                Status <span className="required">*</span>
              </label>
            </div>
            <div className='w-full md:w-11/12'>
              <SelectBox
                name="newSubtaskStatus"
                handleChange={
                  (selectedOption) => {
                    setNewSubtaskStatus(selectedOption.value);
                  }
                }
                options=
                {
                  [
                    { val: 0, lab: "Pending" },
                    { val: 1, lab: "Finished" }
                  ].map(
                    (element, index) => {
                      return {
                        value: element.val,
                        label: element.lab
                      }
                    }
                  )
                }
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#8E8EA1',
                  }),
                }}
              />
            </div>
          </div>

          <div className='flex flex-col gap-y-1 md:basis-1/2 lg:basis-1/3'>
            <div>
              <label
                className='font-normal text-left'
              >
                Assigned to <span className="required">*</span>
              </label>
            </div>
            <div className='w-full md:w-11/12'>
              <SelectBox
                name="employeeID"
                handleChange={
                  (selectedOption) => {
                    setNewSubtaskEmployeeID(selectedOption.value);
                  }
                }
                options={employees && employees.length > 0 && employees.map((employee, index) => {
                  return {
                    value: employee._id,
                    label: `${employee.full_name} ${employee.custom_id}`
                  }
                })}
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderColor: '#8E8EA1',
                  }),
                }}
              />
            </div>
          </div>

        </div>

        <div>

          <div className='flex flex-col gap-y-1'>
            <div>
              <label
                className='font-normal text-left'
              >
                Description
              </label>
            </div>
            <div className='w-full'>
              <textarea
                name="newSubtaskDescription"
                rows="4"
                value={newSubtaskDescription ? newSubtaskDescription : ""}
                onChange={
                  (e) => {
                    setNewSubtaskDescription(e.target.value);
                  }
                }
                className='border border-[#8E8EA1] w-full py-1 rounded px-2 outline-none'
              >
              </textarea>
            </div>
          </div>

        </div>

        <div className='flex flex-row gap-3 my-5 items-center'>
          <button
            disabled={isNewSubtaskSubmitting || isNewSubtaskDisabled}
            onClick={handleAddNewSubtask}
            className='bg-primary py-1 px-4 text-white border rounded shadow-md'
          >
            {isNewSubtaskSubmitting ? <ClipLoader size={17} color="#fff" /> : "Add new subtask"}
          </button>
          {addNewSubtaskMessage}
        </div>

      </div>

    </React.Fragment>
  );
}