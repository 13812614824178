import Footer from "./footer"
import Header from "./header"
import Sidebar from "./sidebar"
import clsx from "clsx";

const Layout = ({ children, isDashboard }) => {

  console.log("Before Layout() rendering");

  return (
    <div className="sm:pb-0 pb-20 space">
      <Sidebar />
      <main className="">
        <Header />
        <div className={clsx('px-3 py-3 md:px-6 md:py-6', isDashboard && 'bg-dashboardBackground')}>
          {children}
        </div>
        <Footer />
      </main>
    </div>
  );
}

export default Layout