import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
//import './employeesTasks.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import { FaPlus } from "react-icons/fa6";
import Loader from 'components/global/Loader';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import Pagination from 'components/global/pagination';
import { toast } from 'react-toastify';

export const EmployeesTasks = () => {
  const [message, setMessage] = useState(null);
  const [employeesTasks, setEmployeesTasks] = useState(null);
  const [employeesTasksTotalCount, setEmployeesTasksTotalCount] = useState(null);
  const [pageSize, setPageSize] = useState(10);
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  let page = parseInt(searchParams.get('page'));
  if (isNaN(page))
    page = 1;

  const handleCurrentPageChange = (page) => {
    searchParams.set('page', page);
    setSearchParams(searchParams);
  }

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    let url = `${baseURL}/employeesTasks?page=${page}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employeesTasks = jsonData.data.docs;
    setEmployeesTasks(employeesTasks);
    setEmployeesTasksTotalCount(jsonData.data.docsCount);
    setIsLoading(false);
  }

  const handleDelete = async (e) => {
    let deleteButton = e.target;
    let id = deleteButton.getAttribute("data-id");
    let url = baseURL + "/employeesTasks/delete/" + id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "DELETE",
      headers: headers
    });
    const jsonData = await response.json();
    setMessage(jsonData.data.message);
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      toast.error("An error occurred while communicating with the server.");
      setMessage("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, [searchParams]);

  console.log("Before EmployeesTasks() rendering");

  const NewDocumentLink =
    (
      userData.doc.isSuperAdmin ||
      userData.doc.isAdmin ||
      userData.role.create.includes("employeetasks")
    ) &&
    <Link to="/employeestasks/new">
      <button className='flex flex-row items-center bg-[#1A8650] text-white px-3 text-sm py-2 space-x-1 rounded-3xl'>
        <span><FaPlus /></span>
        <span>New Employee Task</span>
      </button>
    </Link>

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Employees Tasks</h2>
            {NewDocumentLink}
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let employeesTasksList = [];

  if (employeesTasks && employeesTasks.length !== 0) { //on second render
    employeesTasksList = employeesTasks.map((task, index) => {
      const assignees = []
      for (const employee of task.employeeIDs) {
        assignees.push(employee.full_name);
      }
      const displayAssignees = assignees.join(', ');
      let editLink = "/employeestasks/edit/" + task._id;
      let viewLink = "/employeestasks/" + task._id;
      let numberOfSubtasks = task.subtasks.length;
      let completionPercentage;
      if (numberOfSubtasks > 0) {
        let completeSubtasks = 0;
        for (let i = 0; i < numberOfSubtasks; i++) {
          const subtask = task.subtasks[i];
          if (subtask.subtaskStatus === 1) {
            completeSubtasks++;
          }
        }
        completionPercentage = ((completeSubtasks / numberOfSubtasks) * 100);
        if (isNaN(completionPercentage)) {
          completionPercentage = 0;
        }
        completionPercentage = completionPercentage.toFixed(2);
      }
      else {
        completionPercentage = "No subtasks!";
      }

      let taskStatus, taskPriority;

      if (task.taskStatus === 0)
        taskStatus = "Pending";
      else if (task.taskStatus === 1)
        taskStatus = "Finished";

      if (task.taskPriority === 0)
        taskPriority = "Low";
      else if (task.taskPriority === 1)
        taskPriority = "Normal";
      else if (task.taskPriority === 2)
        taskPriority = "High";
      else if (task.taskPriority === 3)
        taskPriority = "Urgent";

      return (
        <tr key={index}>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{task.employeeTaskID}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">
            <Link
              to={viewLink}
              className='text-white bg-primary rounded-[40px] p-2 px-3'
            >
              {task.taskTitle}
            </Link>
          </td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{task.supervisor.full_name}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{displayAssignees}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{taskStatus}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{completionPercentage}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{taskPriority}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{task.taskStartByDate?.split('T')[0]}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{task.taskFinishByDate?.split('T')[0]}</td>
          <td className=" text-gray-900  px-6 py-4 whitespace-nowrap">{task.taskActualFinishDate?.split('T')[0]}</td>
          <td className='px-6 py-4 whitespace-nowrap'>
            <Link to={editLink}><CiEdit className='text-2xl text-primary' /></Link></td>
          <td className='px-6 py-4 whitespace-nowrap text-center'>
            <button data-id={task._id} onClick={handleDelete}>
              <RiDeleteBin6Line className='text-lg text-[#E30411]' />
            </button></td>
        </tr>
      );
    });
  }

  return (
    <Layout>
      <div className='space-y-5 '>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employees Tasks</h2>
          {NewDocumentLink}
        </div>
        <div className='flex flex-row justify-between'>
          {message && message}
        </div>

        <div className='overflow-x-auto rounded-lg border border-[#6843BE]'>
          <table className="w-full table-auto overflow-x-auto">
            <thead className="bg-lightSlate border-b text-[14px] border border-b-[#6843BE] rounded-lg">
              <tr>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">ID</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Title</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Supervisor</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Assigned to Employees</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Status</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Finished (%)</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Priority</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Start Date</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Approx. Finish Date</th>
                <th scope="col" className="font-medium text-primary px-6 py-4 text-left">Actual Finish Date</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Edit</th>
                <th scope="col" className="font-semibold text-primary px-6 py-4 text-left">Delete</th>
              </tr>
            </thead>
            <tbody className='text-[13px] p-4'>
              {employeesTasksList}
            </tbody>
            <tfoot className='w-full bg-lightSlate border border-b-[#6843BE] border-t-[#6843BE]'>
              <tr>
                <td colSpan='8'>
                  <Pagination
                    currentPage={page}
                    pageCount={Math.ceil(employeesTasksTotalCount / pageSize)}
                    setPage={handleCurrentPageChange}
                  />
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </Layout>
  );
}
