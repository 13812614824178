import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import clsx from "clsx";

export const SalariesChart = ({ marginEnd }) => {
  const [employeesAndSalaries, setEmployeesAndSalaries] = useState(null);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { userData } = useContext(AuthContext);

  useEffect(() => {

    async function fetchData() {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };

      let url = baseURL + "/reports/salariesByEmployees";
      const response = await fetch(url, {
        method: "GET",
        headers: headers
      });
      const jsonData = await response.json();
      if (!response.ok) {
        toast.error(jsonData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }

      setEmployeesAndSalaries(jsonData.data.doc);
      setIsLoading(false);
    }

    fetchData().catch(e => {
      setIsLoading(false);
      setIsLoadingError(true);
      toast.error("An error occurred while communicating with the server.");
    });
  }, []);

  if (isLoading) {
    return (
      <div className={clsx('p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md', marginEnd && 'xl:me-4')}>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Salaries
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          <ClipLoader size={17} color="#000" />
        </div>

      </div>

    );
  }
  if (!isLoading && isLoadingError) {
    return (
      <div className={clsx('p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md', marginEnd && 'xl:me-4')}>
        <div className='flex flex-row justify-between mb-4'>

          <div className='text-lg text-textPrimaryColor font-bold'>
            Salaries
          </div>

        </div>

        <div className='flex flex-row justify-around items-center h-52'>
          An error occurred.
        </div>

      </div>

    );
  }

  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );

  const options = {
    responsive: true,
  };

  const labels = employeesAndSalaries.map((element, index) => {
    return element[0];
  })

  const data = {
    labels,
    datasets: [
      {
        label: 'Salaries',
        data: employeesAndSalaries.map((element, index) => {
          return element[1];
        }),
        backgroundColor: 'rgba(83, 89, 247, 1)',
      },
    ],
  };

  return (
    <div className={clsx('p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md', marginEnd && 'xl:me-4')}>
      <div className='flex flex-row justify-between mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>
          Salaries
        </div>

      </div>

      <div>
        <Bar options={options} data={data} />
      </div>

    </div>
  );
}
