import React from 'react'

export const CurrencyDollar = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_480)">
        <path d="M12 17V17.5V18" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
        <path d="M12 6V6.5V7" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
        <path d="M15 9.5C15 8.11929 13.6569 7 12 7C10.3431 7 9 8.11929 9 9.5C9 10.8807 10.3431 12 12 12C13.6569 12 15 13.1193 15 14.5C15 15.8807 13.6569 17 12 17C10.3431 17 9 15.8807 9 14.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
        <path d="M6.5 2.4716C8.11796 1.53567 9.99646 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 9.99646 1.53567 8.11796 2.4716 6.5" stroke="white" strokeWidth="1.3" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="clip0_1_480">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}