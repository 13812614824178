import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import './rolesLeavesNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import SelectBox from 'components/global/SelectBox.jsx';

export const LeaveRulesNew = () => {
  const [message, setMessage] = useState(null);
  const [roles, setRoles] = useState(null);

  const [role, setRole] = useState("");
  const [casualLeaves, setCasualLeaves] = useState(0);
  const [medicalLeaves, setMedicalLeaves] = useState(0);
  const [halfDayDeduction, setHalfDayDeduction] = useState(0);
  const [offDayDeduction, setOffDayDeduction] = useState(0);
  const [absentDayDeduction, setAbsentDayDeduction] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    if (!isLoading) {
      setIsLoading(true);
    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getRolesURL = baseURL + "/roles";
    const getRolesResponse = await fetch(getRolesURL, {
      method: "GET",
      headers: headers
    });
    const getRolesJSONData = await getRolesResponse.json();
    if (!getRolesResponse.ok) {
      toast.error(getRolesJSONData.data.message);
      setMessage(getRolesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const roles = getRolesJSONData.data.docs;

    setRoles(roles);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/leaverules/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleLeaveRulesForm(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!role) {
      errors.push(<div>Please select a role</div>);
    }

    if (isNaN(casualLeaves)) {
      errors.push(<div>Casual leaves can't be empty</div>);
    }

    if (isNaN(medicalLeaves)) {
      errors.push(<div>Medical leaves can't be empty</div>);
    }

    if (isNaN(halfDayDeduction)) {
      errors.push(<div>Half day deduction can't be empty</div>);
    }

    if (isNaN(offDayDeduction)) {
      errors.push(<div>Off day deduction can't be empty</div>);
    }

    if (isNaN(absentDayDeduction)) {
      errors.push(<div>Absent day deduction can't be empty</div>);
    }

    let payload = {
      roleID: role,
      casualLeaves,
      medicalLeaves,
      halfDayDeduction,
      offDayDeduction,
      absentDayDeduction
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create leave rule</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before LeavesRulesNew() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5 text-gray-900 text-sm'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Create leave rule</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form onSubmit={handleLeaveRulesForm}>

          <div className='rounded-lg border border-primary p-8 space-y-5'>

            <div>Casual leaves includes leaves like recreational leaves, urgent work at home etc.</div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 md:items-end'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Role <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox
                    name="role"
                    options={
                      roles && roles.length !== 0 && roles.map((role, index) => {
                        return {
                          value: role._id,
                          label: role.name
                        }
                      })
                    }
                    handleChange={(selectedOption) => setRole(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Casual leaves per year <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    name="casualLeaves"
                    value={casualLeaves}
                    min={0}
                    onChange={(e) => {
                      setCasualLeaves(parseInt(e.target.value));
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>Medical leaves per year <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    name="medicalLeaves"
                    min={0}
                    value={medicalLeaves}
                    onChange={(e) => {
                      setMedicalLeaves(parseInt(e.target.value));
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div className='w-full md:w-11/12'>
                  <label className='font-normal'>Half day deduction (% of one day salary) <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    name="halfDayDeduction"
                    value={halfDayDeduction}
                    min={0}
                    onChange={(e) => {
                      setHalfDayDeduction(parseInt(e.target.value));
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div className='w-full md:w-11/12'>
                  <label className='font-normal'>Off day deduction (% of one day salary) <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    name="offDayDeduction"
                    value={offDayDeduction}
                    min={0}
                    onChange={(e) => {
                      setOffDayDeduction(parseInt(e.target.value));
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div className='w-full md:w-11/12'>
                  <label className='font-normal'>Absent day deduction (% of one day salary) <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    name="absentDayDeduction"
                    value={absentDayDeduction}
                    min={0}
                    onChange={(e) => {
                      setAbsentDayDeduction(parseInt(e.target.value));
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-row my-5'>
              <button
                disabled={isSubmitting || isDisabled}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </div>

        </form>
      </div >
    </Layout >
  );
}