import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './demandsEdit.css';
import { useParams } from "react-router-dom";
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const DemandsEdit = () => {
  const [message, setMessage] = useState(null);
  const [projects, setProjects] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [demand, setDemand] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userData } = useContext(AuthContext);
  const params = useParams();

  async function fetchData() {
    let url = baseURL + "/projects/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const projects = jsonData.data.docs;

    let employees = null;
    if (userData.doc.isSuperAdmin || userData.doc.isAdmin) {
      let getEmployeesURL = baseURL + "/employees/";
      const getEmployeesResponse = await fetch(getEmployeesURL, {
        method: "GET",
        headers: headers
      });
      const getEmployeesJSONData = await getEmployeesResponse.json();
      if (!getEmployeesResponse.ok) {
        toast.error(getEmployeesJSONData.data.message);
        setMessage(getEmployeesJSONData.data.message);
        setIsLoadingError(true);
        setIsLoading(false);
        return; //early return
      }
      employees = getEmployeesJSONData.data.docs;
    }

    let getDemandURL = baseURL + "/demands/" + params.id;
    const getDemandResponse = await fetch(getDemandURL, {
      method: "GET",
      headers: headers
    });
    const getDemandJSONData = await getDemandResponse.json();
    if (!getDemandResponse.ok) {
      toast.error(getDemandJSONData.data.message);
      setMessage(getDemandJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const demand = getDemandJSONData.data.doc;

    setProjects(projects);
    setEmployees(employees);
    setDemand(demand);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/demands/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleEditDemandForm(event) {
    event.preventDefault();
    let errors = [];
    let editDemandForm = event.target;

    setIsSubmitting(true);

    let projectObjectId = editDemandForm.elements.namedItem("projectObjectId");
    if (projectObjectId) {
      if (!projectObjectId.value) {
        errors.push("Project is required");
      }
      else {
        projectObjectId = projectObjectId.value;
      }
    }

    let demandCreator = editDemandForm.elements.namedItem("demandCreator");
    if (demandCreator) {
      if (!demandCreator.value) {
        errors.push("Demand creator is required");
      }
      else {
        demandCreator = demandCreator.value;
      }
    }

    let cooConsent = editDemandForm.elements.namedItem("cooConsent");
    let cooComment = editDemandForm.elements.namedItem("cooComment");
    if (cooConsent) {
      if (cooConsent.checked) {
        cooConsent = "accept"
      }
      else {
        cooConsent = "pending"
      }
      cooComment = cooComment.value;
    }

    let mdConsent = editDemandForm.elements.namedItem("mdConsent");
    let mdComment = editDemandForm.elements.namedItem("mdComment");
    if (mdConsent) {
      if (mdConsent.checked) {
        mdConsent = "accept"
      }
      else {
        mdConsent = "pending"
      }
      mdComment = mdComment.value;
    }

    let projectManagerConsent = editDemandForm.elements.namedItem("projectManagerConsent");
    let projectManagerComment = editDemandForm.elements.namedItem("projectManagerComment");
    if (projectManagerConsent) {
      if (projectManagerConsent.checked) {
        projectManagerConsent = "accept"
      }
      else {
        projectManagerConsent = "pending"
      }
      projectManagerComment = projectManagerComment.value;
    }

    let materials = [];
    let demandItemsDiv = document.getElementById("demandItemsList");
    const currentNumberOfItems = demandItemsDiv.children.length;

    for (let i = 1; i <= currentNumberOfItems; i++) {
      let itemDiv = demandItemsDiv.querySelector(`#itemCount` + i);
      const stockItemObjectId = itemDiv.getAttribute(`data-stockid`);
      const stockItemName = itemDiv.querySelector(`label`).childNodes[0].nodeValue;
      const quantity = parseFloat(itemDiv.querySelector(`input`).value);

      if (isNaN(quantity)) {
        errors.push("Quantity is not a number");
      }

      if (quantity >= 0) {
        materials.push({
          stockItemObjectId,
          stockItemName,
          quantity
        });
      }
    }

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      let payload = {
        projectObjectId,
        demandCreator: demandCreator ? demandCreator : undefined,
        materials,
        cooConsent: cooConsent ? cooConsent : undefined,
        mdConsent: mdConsent ? mdConsent : undefined,
        projectManagerConsent: projectManagerConsent ? projectManagerConsent : undefined,
        cooComment: cooComment ? cooComment : undefined,
        mdComment: mdComment ? mdComment : undefined,
        projectManagerComment: projectManagerComment ? projectManagerComment : undefined,
      };
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit a demand</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let projectsList = [];
  let employeesList = [];
  let stockItemsList = [];

  if (projects && projects.length !== 0) {
    projectsList = projects.map((project, index) => {
      return (
        <option key={project._id} value={project._id}>{project.projectName}</option>
      );
    });
  }

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option key={employee._id} value={employee._id}>{employee.full_name} {employee.email}</option>
      );
    });
  }

  if (demand.materials && demand.materials.length !== 0) {
    let count = 0;
    stockItemsList = demand.materials.map((material, index) => {
      count++;
      return (
        <div key={material._id} id={`itemCount` + count} data-stockid={material.stockItemObjectId} className='flex flex-col space-y-3 w-full md:w-1/3' >
          <label className='text-base font-normal text-left'>{`${material.stockItemName}`}</label>
          <input type="number" min="0" step="0.01" defaultValue={material.quantity} className='border border-[#8E8EA1] text-gray-900 w-full py-1 rounded-[40px] px-3 outline-none text-sm' />
        </div>
      );
    });
  }

  console.log("Before DemandsEdit() rendering");

  return (
    <Layout>
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit a demand</h2>
        </div>
        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>
        <form id="editDemandForm" onSubmit={handleEditDemandForm}>
          <div className='overflow-x-auto rounded-lg border border-primary p-8 text-gray-900 space-y-5'>
            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-3'>

              <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                <label className='text-gray-900 text-base font-normal text-left'>Project <span className='required'>*</span></label>
                <select name="projectObjectId" className='border border-[#8E8EA1] text-gray-900 w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' defaultValue={demand.projectObjectId._id}>
                  <option value={""}>Select a project</option>
                  {projectsList}
                </select>
              </div>

              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                && (
                  <div className='flex flex-col space-y-3 w-full md:w-1/3'>
                    <label className='text-gray-900 text-base font-normal text-left'>Demand Creator <span className='required'>*</span></label>
                    <select disabled name="demandCreator" className='border border-[#8E8EA1] text-gray-900 w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' defaultValue={demand.demandCreator._id}>
                      <option value={""}>Select an employee</option>
                      {employeesList}
                    </select>
                  </div>
                )
              }

            </div>

            <div id="demandItemsList" className='flex flex-col gap-y-3'>
              {stockItemsList}
            </div>

            <div className='flex flex-col gap-y-3 text-sm text-gray-900 font-normal text-left'>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Managing Director says: <div className='p-2'>{demand.mdComment ? demand.mdComment : <i>No comments yet</i>}</div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Chief Operations Officer says: <div className='p-2'>{demand.cooComment ? demand.cooComment : <i>No comments yet</i>}</div>
              </div>
              <div className='w-full p-2 border border-[#8E8EA1]'>
                Project Manager says: <div className='p-2'>{demand.projectManagerComment ? demand.projectManagerComment : <i>No comments yet</i>}</div>
              </div>

            </div>

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "md")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Managing Director:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="mdConsent" value="accept" defaultChecked={demand.mdConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="mdComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={demand.mdComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                (userData.doc.isAdmin && userData.doc.managementRank === "coo")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Chief Operations Officer:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="cooConsent" value="accept" defaultChecked={demand.cooConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="cooComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={demand.cooComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            {
              (
                userData.doc.isSuperAdmin ||
                userData.role.create.includes("projects")
              ) && (
                <div className='flex flex-col gap-y-3 p-2 text-sm text-gray-900 font-normal text-left border border-[#8E8EA1]'>
                  <div className='w-full'>
                    Project Manager:
                  </div>
                  <div className='w-full'>
                    <input type="checkbox" name="projectManagerConsent" value="accept" defaultChecked={demand.projectManagerConsent === "accept"} className='me-1' />
                    <span>Accept</span>
                  </div>
                  <div className='w-full'>
                    <div className='mb-2'>Comments: </div>
                    <textarea name="projectManagerComment" className='w-full md:w-1/2 p-2 border outline-none' defaultValue={demand.projectManagerComment} placeholder='Please enter your comments here.'></textarea>
                  </div>
                </div>
              )
            }

            <div className='flex flex-row my-5'>
              <button disabled={isSubmitting} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  )
}
