import React from 'react';
import { baseURL } from 'config/api.js';
import { homeTimeZone } from 'config/time.js';
import { useState } from 'react';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import { DateTime } from "luxon";
import { ClipLoader } from 'react-spinners';

export const WorkDayInfoBox = () => {
  const [isCheckInSubmitting, setIsCheckInSubmitting] = useState(false);
  const [isCheckInDisabled, setIsCheckInDisabled] = useState(false);
  const [isCheckOutSubmitting, setIsCheckOutSubmitting] = useState(false);
  const [isCheckOutDisabled, setIsCheckOutDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeesattendances/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  let shiftStart = DateTime.fromObject({
    hour: userData.doc.employee_id?.workingShift.shiftStart.hour,
    minute: userData.doc.employee_id?.workingShift.shiftStart.minute,
  }, { zone: homeTimeZone });

  let shiftEnd = DateTime.fromObject({
    hour: userData.doc.employee_id?.workingShift.shiftEnd.hour,
    minute: userData.doc.employee_id?.workingShift.shiftEnd.minute,
  }, { zone: homeTimeZone });

  let nowMoment = DateTime.now().setZone(homeTimeZone);

  const handleCheckIn = async (e) => {
    setIsCheckInSubmitting(true);
    setIsCheckInDisabled(true);

    //To protect against tempering, we are using setZone
    let now = DateTime.now().setZone(homeTimeZone);

    if ((now < shiftStart) || (now > shiftEnd)) {
      toast.error("Attendance can't be marked outside shift timing.");
      return; //early return
    }

    let payload = {
      employeeID: userData.doc.employee_id._id,
      checkInTime: now.toISO(),
    }

    if (userData.doc.employee_id.workingShift.isLatePolicy) {

      let onTime = DateTime.fromObject(
        {
          hour: userData.doc.employee_id.workingShift.onTime.hour,
          minute: userData.doc.employee_id.workingShift.onTime.minute,
        },
        {
          zone: homeTimeZone
        });

      let halfDay = DateTime.fromObject({
        hour: userData.doc.employee_id.workingShift.halfDay.hour,
        minute: userData.doc.employee_id.workingShift.halfDay.minute,
      },
        {
          zone: homeTimeZone
        });

      let offDay = DateTime.fromObject({
        hour: userData.doc.employee_id.workingShift.offDay.hour,
        minute: userData.doc.employee_id.workingShift.offDay.minute,
      },
        {
          zone: homeTimeZone
        });

      if (now < onTime) {
        payload.status = "On Time";
      }
      else if (now < halfDay) {
        payload.status = "Late";
      }
      else if (now < offDay) {
        payload.status = "Half Day";
      }
      else {
        payload.status = "Off Day";
      }
    }
    else {
      payload.status = "On Time";
    }

    try {
      await sendAPIRequest(payload);
      setIsCheckInSubmitting(false);
    }
    catch (error) {
      setIsCheckInSubmitting(false);
      setIsCheckInDisabled(false);
    }
  }

  const handleCheckOut = async (e) => {
    setIsCheckOutSubmitting(true);
    setIsCheckOutDisabled(true);

    //To protect against tempering, we are using setZone
    let now = DateTime.now().setZone(homeTimeZone);

    if ((now < shiftStart) || (now > shiftEnd)) {
      toast.error("Check out can't be marked outside shift timing.");
      return; //early return
    }

    let payload = {
      employeeID: userData.doc.employee_id._id,
      checkOutTime: now.toISO(),
    }

    try {
      let url = baseURL + "/employeesattendances/check-out";
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };
      const response = await fetch(url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(payload),
      });
      const jsonData = await response.json();
      if (jsonData.success) {
        toast.success(jsonData.data.message);
        setIsCheckOutSubmitting(false);
      }
      else {
        toast.error(jsonData.data.message);
        setIsCheckOutSubmitting(false);
        setIsCheckOutDisabled(false);
      }
    }
    catch (error) {
      setIsCheckOutSubmitting(false);
      setIsCheckOutDisabled(false);
    }
  }

  return (
    <div className='p-4 mb-4 xl:mb-0 overflow-auto bg-white rounded-md shadow-md space-y-4'>

      <div className='flex flex-row justify-between items-center mb-4'>

        <div className='text-lg text-textPrimaryColor font-bold'>
          Mark your attendance
        </div>

      </div>


      {
        (nowMoment >= shiftStart) && (nowMoment <= shiftEnd) ? (
          <div className="p-5 flex justify-around bg-[rgba(249,149,56,0.38)] rounded-md">
            <button
              onClick={handleCheckIn}
              disabled={isCheckInSubmitting || isCheckInDisabled}
              className='text-white text-xs p-2 bg-gradient-to-r from-[#F2A359] to-[rgba(255,123,1,0.84)] rounded-md'
            >
              {isCheckInSubmitting ? <ClipLoader size={17} color="#fff" /> : "Check In"}
            </button>
            <button
              onClick={handleCheckOut}
              disabled={isCheckOutSubmitting || isCheckOutDisabled}
              className='text-white text-xs p-2 bg-gradient-to-r from-[#F2A359] to-[rgba(255,123,1,0.84)] rounded-md'
            >
              {isCheckOutSubmitting ? <ClipLoader size={17} color="#fff" /> : "Check Out"}
            </button>
          </div>
        ) : (
          <div className="flex flex-col bg-[rgba(249,149,56,0.38)] rounded-md">
            <div className="p-5 flex justify-around">
              <button disabled className=' text-white text-xs p-2 bg-gradient-to-r from-[#F2A359] to-[rgba(255,123,1,0.84)] rounded-md'>
                Check In
              </button>
              <button disabled className='text-white text-xs p-2 bg-gradient-to-r from-[#F2A359] to-[rgba(255,123,1,0.84)] rounded-md'>
                Check Out
              </button>
            </div>
          </div>
        )
      }

      <div className="flex justify-around">
        <div className="flex flex-col gap-x-2">
          <div className='text-xs font-semibold text-center'>
            Shift Start
          </div>
          <div className='text-xs'>
            {shiftStart.toLocaleString(DateTime.DATETIME_MED)}
          </div>
        </div>

        <div className="flex flex-col gap-x-2">
          <div className='text-xs font-semibold text-center'>
            Shift End
          </div>
          <div className='text-xs'>
            {shiftEnd.toLocaleString(DateTime.DATETIME_MED)}
          </div>
        </div>

      </div>


    </div>
  );
}
