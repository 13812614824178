import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './employeeReport.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import { ClipLoader } from 'react-spinners';
import { DateTime } from 'luxon';

export const EmployeeReport = () => {
  const [message, setMessage] = useState(null);

  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [report, setReport] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { userData } = useContext(AuthContext);

  async function fetchData() {
    let getEmployeesURL = baseURL + "/employees";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setIsLoading(false)
  }

  async function fetchReport(payload) {
    let url = baseURL + "/reports/employee-report";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setReport(jsonData.data)
      toast.success(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const handleEmployeeChange = async (e) => {
    e.preventDefault();

    let errors = [];

    setIsSubmitting(true);

    if (!employee) {
      errors.push(<div>Employee cannot be empty</div>);
    }

    if (!startDate) {
      errors.push(<div>Start date cannot be empty</div>);
    }

    if (!endDate) {
      errors.push(<div>End date cannot be empty</div>);
    }

    if (new Date(endDate).getTime() < new Date(startDate).getTime()) {
      errors.push(<div>End date can't be earlier than start date.</div>);
    }

    let payload = {
      employeeID: employee,
      startDate,
      endDate,
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
    }
    else {
      try {
        await fetchReport(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);


  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-semibold'>Employee Report</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className='space-y-4'>
        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Employee Report</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='rounded-lg border border-[#6843BE] m-4 md:m-0 p-4 text-sm'>

          <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 items-center'>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='font-normal'>
                  Employee Name <span className='required'>*</span>
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <SelectBox
                  name="employee"
                  options={
                    employees && employees.length !== 0 && employees.map((employee, index) => {
                      return {
                        value: employee._id,
                        label: `${employee.full_name} ${employee.custom_id}`
                      }
                    })
                  }
                  handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                />
              </div>
            </div>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='font-normal'>
                  Start Date <span className='required'>*</span>
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <input
                  type="date"
                  name="startDate"
                  value={startDate}
                  onChange={(e) => {
                    setStartDate(e.target.value);
                  }}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                />
              </div>
            </div>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='font-normal'>
                  End Date <span className='required'>*</span>
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <input
                  type="date"
                  name="endDate"
                  value={endDate}
                  onChange={(e) => {
                    setEndDate(e.target.value);
                  }}
                  className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none'
                />
              </div>
            </div>

          </div>

          <div className='flex flex-row my-5'>
            <button
              disabled={isSubmitting}
              onClick={handleEmployeeChange}
              className='text-white bg-primary rounded-[40px] w-[299px] h-9'
            >
              {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
            </button>
          </div>

          {
            report && (
              <div id="employeeReport" className='overflow-x-auto rounded-lg border border-[#6843BE] m-4 md:m-0 p-4 text-sm space-y-5'>

                <div className='flex flex-col md:flex-row md:flex-wrap gap-y-2'>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Full Name:</span> {report.employee.full_name}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Status: </span>
                      {
                        report.employee.employment_status === 0 ? 'Active' :
                          report.employee.employment_status === 1 ? 'On Leave' :
                            report.employee.employment_status === 2 ? 'Terminated' :
                              report.employee.employment_status === 3 ? 'Resigned' :
                                ""
                      }
                    </div>
                  </div>

                </div>

                <div className='flex flex-col gap-y-5'>

                  <div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Increment ID</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>Creation Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            report.increments && report.increments.length > 0 ? (
                              report.increments.map((increment) => {

                                return <tr key={increment._id}>
                                  <td>
                                    {increment.employeeIncrementID}
                                  </td>
                                  <td>
                                    {increment.incrementAmount}
                                  </td>
                                  <td>
                                    {
                                      increment.incrementType === 'costOfLiving' ? 'Cost of Living' :
                                        increment.incrementType === 'performance' ? 'Performance' :
                                          increment.incrementType === 'promotion' ? 'Promotion' :
                                            ""
                                    }
                                  </td>
                                  <td>
                                    {
                                      new Date(Date.parse(increment.createdAt)).toISOString().split('T')[0]
                                    }
                                  </td>
                                </tr>
                              })) : (
                              <tr>
                                <td colSpan={4}>No increments found!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Bonus ID</th>
                            <th>Amount</th>
                            <th>Type</th>
                            <th>For Month</th>
                            <th>Creation Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            report.bonuses && report.bonuses.length > 0 ? (
                              report.bonuses.map((bonus) => {
                                const dtBonusMonth = DateTime.fromISO(bonus.bonusMonth);
                                const bonusMonthText = `${dtBonusMonth.monthShort} ${dtBonusMonth.year}`;

                                return <tr key={bonus._id}>
                                  <td>
                                    {bonus.employeeBonusID}
                                  </td>
                                  <td>
                                    {bonus.bonusAmount}
                                  </td>
                                  <td>
                                    {
                                      bonus.bonusType === 'yearEnd' ? 'Year end' :
                                        bonus.bonusType === 'eidAlFitr' ? 'Eid-al-Fitr' :
                                          bonus.bonusType === 'eidAlAdha' ? 'Eid-al-Adha' :
                                            bonus.bonusType === 'other' ? 'Other' :
                                              ""
                                    }
                                  </td>
                                  <td>
                                    {
                                      bonusMonthText
                                    }
                                  </td>
                                  <td>
                                    {
                                      new Date(Date.parse(bonus.createdAt)).toISOString().split('T')[0]
                                    }
                                  </td>
                                </tr>
                              })) : (
                              <tr>
                                <td colSpan={4}>No bonus found!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Complaint ID</th>
                            <th>Type</th>
                            <th>Subject</th>
                            <th>Status</th>
                            <th>Creation Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            report.complaints && report.complaints.length > 0 ? (
                              report.complaints.map((complaint) => {

                                return <tr key={complaint._id}>
                                  <td>
                                    {complaint.employeeComplaintID}
                                  </td>
                                  <td>
                                    {
                                      complaint.type === 'complaint' ? 'Complaint' :
                                        complaint.type === 'suggestion' ? 'Suggestion' :
                                          ""
                                    }
                                  </td>
                                  <td>
                                    {complaint.subject}
                                  </td>
                                  <td>
                                    {
                                      complaint.status === 'pending' ? 'Pending' :
                                        complaint.status === 'solved' ? 'Solved' :
                                          complaint.status === 'unsolvable' ? 'Unsolvable' :
                                            ""
                                    }
                                  </td>
                                  <td>
                                    {
                                      new Date(Date.parse(complaint.createdAt)).toISOString().split('T')[0]
                                    }
                                  </td>
                                </tr>
                              })) : (
                              <tr>
                                <td colSpan={5}>No complaints or suggestions found!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Task ID</th>
                            <th>Title</th>
                            <th>Supervisor</th>
                            <th>Assigned to</th>
                            <th>Status</th>
                            <th>Finished (%)</th>
                            <th>Priority</th>
                            <th>Start Date</th>
                            <th>Approx. Finish Date</th>
                            <th>Actual Finish Date</th>
                            <th>Creation Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            report.tasks && report.tasks.length > 0 ? (
                              report.tasks.map((task, index) => {
                                const assignees = []
                                for (const employee of task.employeeIDs) {
                                  assignees.push(employee.full_name);
                                }
                                const displayAssignees = assignees.join(', ');
                                let numberOfSubtasks = task.subtasks.length;
                                let completionPercentage;
                                if (numberOfSubtasks > 0) {
                                  let completeSubtasks = 0;
                                  for (let i = 0; i < numberOfSubtasks; i++) {
                                    const subtask = task.subtasks[i];
                                    if (subtask.subtaskStatus === 1) {
                                      completeSubtasks++;
                                    }
                                  }
                                  completionPercentage = ((completeSubtasks / numberOfSubtasks) * 100);
                                  if (isNaN(completionPercentage)) {
                                    completionPercentage = 0;
                                  }
                                  completionPercentage = completionPercentage.toFixed(2);
                                }
                                else {
                                  completionPercentage = "No subtasks!";
                                }

                                let taskStatus, taskPriority;

                                if (task.taskStatus === 0)
                                  taskStatus = "Pending";
                                else if (task.taskStatus === 1)
                                  taskStatus = "Finished";

                                if (task.taskPriority === 0)
                                  taskPriority = "Low";
                                else if (task.taskPriority === 1)
                                  taskPriority = "Normal";
                                else if (task.taskPriority === 2)
                                  taskPriority = "High";
                                else if (task.taskPriority === 3)
                                  taskPriority = "Urgent";

                                return (
                                  <tr key={index}>
                                    <td>{task.employeeTaskID}</td>
                                    <td>
                                      {task.taskTitle}
                                    </td>
                                    <td>{task.supervisor.full_name}</td>
                                    <td>{displayAssignees}</td>
                                    <td>{taskStatus}</td>
                                    <td>{completionPercentage}</td>
                                    <td>{taskPriority}</td>
                                    <td>{task.taskStartByDate?.split('T')[0]}</td>
                                    <td>{task.taskFinishByDate?.split('T')[0]}</td>
                                    <td>{task.taskActualFinishDate?.split('T')[0]}</td>
                                    <td>
                                      {
                                        new Date(Date.parse(task.createdAt)).toISOString().split('T')[0]
                                      }
                                    </td>
                                  </tr>
                                );
                              })) : (
                              <tr>
                                <td colSpan={11}>No tasks found!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div>
                    <div>
                      <table>
                        <thead>
                          <tr>
                            <th>Meeting ID</th>
                            <th>People</th>
                            <th>Title</th>
                            <th>Status</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            report.events && report.events.length > 0 ? (
                              report.events.map((event, index) => {

                                const people = []
                                for (const person of event.people) {
                                  people.push(person.full_name);
                                }

                                let status;
                                if (event.status === 'onSchedule')
                                  status = "On Schedule";
                                else if (event.status === 'cancelled')
                                  status = "Cancelled";

                                let dtStart = DateTime.fromISO(new Date(event.startDate).toISOString());
                                let dtEnd = DateTime.fromISO(new Date(event.endDate).toISOString());

                                return (
                                  <tr key={event._id}>
                                    <td>{event.eventID}</td>
                                    <td>{people.join(', ')}</td>
                                    <td>{event.title}</td>
                                    <td>{status}</td>
                                    <td>{dtStart.toLocaleString(DateTime.DATETIME_MED)}</td>
                                    <td>{dtEnd.toLocaleString(DateTime.DATETIME_MED)}</td>
                                  </tr>
                                );
                              })) : (
                              <tr>
                                <td colSpan={11}>No meetings found!</td>
                              </tr>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>

              </div>
            )
          }

        </div>

      </div>
    </Layout >
  );
}
