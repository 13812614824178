import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
//import styles from './departmentsNew.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Loader from 'components/global/Loader';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const DepartmentsNew = () => {
  const [message, setMessage] = useState(null);
  const [companies, setCompanies] = useState([]);
  const { userData } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingError, setIsLoadingError] = useState(false);

  async function fetchData() {
    setIsLoading(true);
    let url = baseURL + "/companies/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    setIsLoading(false);
    const jsonData = await response.json();

    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }

    const companies = jsonData.data.docs;
    const docsCount = jsonData.data.docsCount;
    if (docsCount > 0) {
      setCompanies(companies);
    }
    else {
      setMessage("No companies found! Please create a new company before creating its departments.")
    }
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/departments/create";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();

    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleCreateDepartmentForm(event) {
    event.preventDefault();

    setIsSubmitting(true);

    let createDepartmentForm = event.target;
    let department_name = createDepartmentForm.elements.namedItem("department_name").value;
    let company_id = createDepartmentForm.elements.namedItem("company_id").value;
    const payload = { department_name, company_id };
    try {
      await sendAPIRequest(payload);
      setIsSubmitting(false);
    }
    catch (error) {
      setIsSubmitting(false);
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Create a new department</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before DepartmentsNew() rendering");

  let companiesList = [];

  if (companies && companies.length !== 0) {
    companiesList = companies.map((company, index) => {
      return (
        <option key={index} value={company._id}>{company.name}</option>
      );
    });
  }

  return (
    <Layout >
      <div className='w-full space-y-5'>
        <div className='flex flex-row justify-between items-center px-3 '>
          <h2 className='text-2xl text-primary font-bold'>Create a new department</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='flex flex-col px-4 border border-[#2F80ED] rounded-lg py-3'>

          <form id="createDepartmentForm" onSubmit={handleCreateDepartmentForm}>

            <div className='flex flex-col space-y-3 w-full md:w-2/5'>
              <label className='text-base font-normal text-left'>
                Department Name <span className='required'>*</span>
              </label>
              <input type="text" name="department_name" placeholder='Department Name' className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm' />
              <label className='text-base font-normal text-left'>
                Company <span className='required'>*</span>
              </label>
              <select name="company_id" className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-[40px] px-3 outline-none text-sm'>
                {companiesList}
              </select>
            </div>

            <div className='flex flex-row justify-start my-5'>
            <button
                disabled={isSubmitting}
                type="submit"
                className='text-white bg-primary rounded-[40px] w-[299px] h-9'
              >
                {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
              </button>
            </div>

          </form>
        </div>

      </div>
    </Layout>
  );
}
