import React from 'react';
import { baseURL, staticFilesURL } from 'config/api.js';
import { useState, useEffect, useRef } from 'react';
//import './projectsEdit.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import { useParams } from "react-router-dom";
import Loader from 'components/global/Loader';
import Layout from 'components/global/Layout';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import Select from 'react-select';
import SelectBox from 'components/global/SelectBox.jsx';

export const ProjectsEdit = () => {
  const [message, setMessage] = useState(null);
  const projectPicturesRef = useRef(null);
  const projectSoilReportRef = useRef(null);
  const projectBOQDocumentRef = useRef(null);
  const params = useParams();
  const [projectCategories, setProjectCategories] = useState(null);
  const [employees, setEmployees] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [project, setProject] = useState(null);

  const [projectName, setProjectName] = useState("");
  const [projectCategory, setProjectCategory] = useState("");
  const [projectOwnerCompany, setProjectOwnerCompany] = useState("");
  const [projectOwnerEmployee, setProjectOwnerEmployee] = useState("");
  const [projectManager, setProjectManager] = useState("");
  const [siteEngineers, setSiteEngineers] = useState([]);
  const [staffMembers, setStaffMembers] = useState([]);
  const [coveredArea, setCoveredArea] = useState(0);
  const [plotArea, setPlotArea] = useState(0);
  const [numberOfUnits, setNumberOfUnits] = useState(0);
  const [projectPictures, setProjectPictures] = useState(null);
  const [projectSoilReport, setProjectSoilReport] = useState(null);
  const [projectBOQDocument, setProjectBOQDocument] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { userData } = useContext(AuthContext);

  async function fetchData() {
    let url = baseURL + "/projectscategories/";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };

    let getProjectURL = baseURL + "/projects/" + params.id;
    const getProjectResponse = await fetch(getProjectURL, {
      method: "GET",
      headers: headers
    });
    const getProjectJSONData = await getProjectResponse.json();
    if (!getProjectResponse.ok) {
      toast.error(getProjectJSONData.data.message);
      setMessage(getProjectJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const project = getProjectJSONData.data.doc;

    const response = await fetch(url, {
      method: "GET",
      headers: headers
    });
    const jsonData = await response.json();
    if (!response.ok) {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const pcs = jsonData.data.docs;

    let getEmployeesURL = baseURL + "/employees/";
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    let getCompaniesURL = baseURL + "/companies/";
    const getCompaniesResponse = await fetch(getCompaniesURL, {
      method: "GET",
      headers: headers
    });
    const getCompaniesJSONData = await getCompaniesResponse.json();
    if (!getCompaniesResponse.ok) {
      toast.error(getCompaniesJSONData.data.message);
      setMessage(getCompaniesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const companies = getCompaniesJSONData.data.docs;

    let projectPicturesFromServer = [];

    for (let i = 0; i < project.projectPictures.length; i++) {
      let pictureURL = staticFilesURL + "/projects/" + project.projectPictures[i];
      const pictureURLResponse = await fetch(pictureURL, {
        method: "GET",
        headers: headers
      });
      if (!pictureURLResponse.ok) {
        continue;
      }
      const pictureURLBLOB = await pictureURLResponse.blob();
      try {
        let dataURL = await convertToBase64(null, pictureURLBLOB);
        projectPicturesFromServer.push(dataURL);
      }
      catch (error) {
        console.log(error);
      }
    }

    setProjectCategories(pcs);
    setEmployees(employees);
    setCompanies(companies);
    setProject(project);
    setProjectName(project.projectName);
    setProjectCategory(project.projectCategory._id);
    setProjectOwnerCompany(project.projectOwnerCompany ? project.projectOwnerCompany._id : '');
    setProjectOwnerEmployee(project.projectOwnerEmployee ? project.projectOwnerEmployee._id : '');
    setProjectManager(project.projectManager._id);
    setSiteEngineers(project.siteEngineers);
    setStaffMembers(project.staffMembers);
    setCoveredArea(project.coveredArea);
    setPlotArea(project.plotArea);
    setNumberOfUnits(project.numberOfUnits ? project.numberOfUnits : 0);
    setProjectPictures(projectPicturesFromServer);
    setProjectSoilReport(project.projectSoilReport ? project.projectSoilReport : null);
    setProjectBOQDocument(project.projectBOQDocument ? project.projectBOQDocument : null);
    setIsLoading(false);
  }

  async function sendAPIRequest(payload) {
    console.log("Inside sendAPIRequest");
    let url = baseURL + "/projects/update/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PUT',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const convertToBase64 = (file, blob) => {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = (e) => {
        resolve(e.target.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
      if (file)
        fileReader.readAsDataURL(file);
      else if (blob)
        fileReader.readAsDataURL(blob);
    });
  };

  const handleProjectPicturesChange = async (event) => {
    const file = event.target.files[0];
    try {
      if (((file.size / 1024) / 1024) > 4) { //if greater than 4 megabytes
        toast.error(`File size too large: ${file.name} Limit is 4MB per file`);
      }
      else if (!(projectPictures.length < 7)) {
        toast.error(`Limit exceeded, max 7 pictures allowed`);
      }
      else {
        const dataURL = await convertToBase64(file);
        setProjectPictures(prev => {
          return [dataURL, ...prev];
        });
        projectPicturesRef.current.value = null;
      }
    }
    catch (error) {
      console.log(error);
      projectPicturesRef.current.value = null;
    }
  };

  const removeProjectPicture = dataURL => {
    setProjectPictures(prev => prev.filter(storedDataURL => storedDataURL !== dataURL));
  }

  const handleProjectSoilReportChange = async (event) => {
    const file = event.target.files[0];
    try {
      if (((file.size / 1024) / 1024) > 4) { //if greater than 4 megabytes
        toast.error(`File size too large: ${file.name} Limit is 4MB per file`);
      }
      else {
        const dataURL = await convertToBase64(file);
        setProjectSoilReport({
          clientFileName: file.name,
          fileAsBase64: dataURL
        });
        projectSoilReportRef.current.value = null;
      }
    }
    catch (error) {
      console.log(error);
      projectSoilReportRef.current.value = null;
    }
  };

  const removeProjectSoilReport = () => {
    setProjectSoilReport(null);
  }

  const handleProjectBOQDocumentChange = async (event) => {
    const file = event.target.files[0];
    try {
      if (((file.size / 1024) / 1024) > 4) { //if greater than 4 megabytes
        toast.error(`File size too large: ${file.name} Limit is 4MB per file`);
      }
      else {
        const fileAsBase64 = await convertToBase64(file);
        setProjectBOQDocument({
          clientFileName: file.name,
          fileAsBase64: fileAsBase64
        });
        projectBOQDocumentRef.current.value = null;
      }
    }
    catch (error) {
      console.log(error);
      projectBOQDocumentRef.current.value = null;
    }
  };

  const removeProjectBOQDocument = () => {
    setProjectBOQDocument(null);
  }

  const handleTeamChange = (selectedOptions, team) => {
    let employeesList = [];
    if (selectedOptions && selectedOptions.length > 0 ) {
      employeesList = selectedOptions.map((option) => {
        return option.value; //an employee ObjectId
      });
    }
    if (team === "siteEngineers") {
      setSiteEngineers(employeesList);
    }
    else if (team === "staffMembers") {
      setStaffMembers(employeesList);
    }
  }

  async function handleEditProjectForm(event) {
    event.preventDefault();
    let errors = [];
    let editProjectForm = event.target;

    setIsSubmitting(true);

    let projectCreator = editProjectForm.elements.namedItem("projectCreator");
    if (projectCreator) {
      if (!projectCreator.value) {
        errors.push("Project creator is required");
      }
      else {
        projectCreator = projectCreator.value;
      }
    }

    let payload = {
      projectName,
      projectCreator: projectCreator ? projectCreator : undefined,
      projectCategory,
      projectOwnerCompany: projectOwnerCompany ? projectOwnerCompany : undefined,
      projectOwnerEmployee: projectOwnerEmployee ? projectOwnerEmployee : undefined,
      projectManager,
      siteEngineers,
      staffMembers,
      coveredArea,
      plotArea,
      numberOfUnits,
      projectPictures,
      projectSoilReport: projectSoilReport ? projectSoilReport : undefined,
      projectBOQDocument: projectBOQDocument ? projectBOQDocument : undefined,
    };

    if (errors.length > 0) {
      let errorMessage = errors.join(', ');
      toast.error(errorMessage);
      setMessage(errorMessage);
      setIsSubmitting(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
      }
      catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='w-full space-y-5'>
          <div className='flex flex-row justify-between items-center px-3'>
            <h2 className='text-2xl text-primary font-bold'>Edit Project</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  let projectCategoriesList = [];
  let employeesList = [];
  let employeesOptionsList = [];
  let companiesOptionsList = [];
  let siteEngineersDefaultList = [];
  let staffMembersDefaultList = [];

  if (projectCategories && projectCategories.length !== 0) {
    projectCategoriesList = projectCategories.map((projectCategory, index) => {
      return {
        value: projectCategory._id,
        label: projectCategory.projectCategoryName
      }
    });
  }

  if (employees && employees.length !== 0) {
    employeesList = employees.map((employee, index) => {
      return (
        <option
          key={employee._id}
          value={employee._id}
        >
          {employee.full_name} {employee.email}
        </option>
      );
    });
  }

  if (employees && employees.length !== 0) {
    employeesOptionsList = employees.map((employee, index) => {
      return {
        value: employee._id,
        label: employee.full_name
      }
    })
  }

  if (companies && companies.length !== 0) {
    companiesOptionsList = companies.map((company, index) => {
      return {
        value: company._id,
        label: company.name
      }
    });
  }

  if (employees && employees.length !== 0) {
    siteEngineersDefaultList = employeesOptionsList.filter( option => {
      if (siteEngineers.includes(option.value))
        return true;
      else
        return false;
    });
  }

  if (employees && employees.length !== 0) {
    staffMembersDefaultList = employeesOptionsList.filter( option => {
      if (staffMembers.includes(option.value))
        return true;
      else
        return false;
    });
  }

  console.log("Before ProjectsEdit() rendering");

  return (
    <Layout >
      <div className='w-full space-y-5'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Edit Project</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <form id="editProjectForm" onSubmit={handleEditProjectForm}>
          <div className='overflow-x-auto rounded-lg border border-primary p-8 text-gray-900 text-base space-y-5'>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='font-normal'>
                    Title <span className='required'>*</span>
                  </label>
                </div>
                <div>
                  <input
                    type="text"
                    name="projectName"
                    value={projectName}
                    onChange={(e) => setProjectName(e.target.value)}
                    placeholder='Enter Title'
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none '
                  />
                </div>
              </div>

              {
                (
                  userData.doc.isSuperAdmin ||
                  userData.doc.isAdmin
                )
                && (
                  <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                    <div>
                      <label className='font-normal text-left'>Project Creator <span className='required'>*</span> (for admins)</label>
                    </div>
                    <div>
                      <select
                        name="projectCreator"
                        defaultValue={project.projectCreator._id}
                        className='border border-[#8E8EA1] w-full md:w-11/12 rounded-md py-2 px-3 outline-none '
                      >
                        <option value={""}>Select an employee</option>
                        {employeesList}
                      </select>
                    </div>
                  </div>
                )
              }

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Category <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox 
                    name="projectCategory"
                    options={projectCategoriesList}
                    defaultValue={projectCategory}
                    handleChange={(selectedOption) => setProjectCategory(selectedOption.value)}
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Project Owner Company
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox 
                    name="projectOwnerCompany"
                    options={companiesOptionsList}
                    defaultValue={projectOwnerCompany}
                    handleChange={(selectedOption) => setProjectOwnerCompany(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Project Owner Employee
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox 
                    name="projectOwnerEmployee"
                    options={employeesOptionsList}
                    defaultValue={projectOwnerEmployee}
                    handleChange={(selectedOption) => setProjectOwnerEmployee(selectedOption.value)}
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Project Manager <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <SelectBox 
                    name="projectManager"
                    options={employeesOptionsList}
                    defaultValue={projectManager}
                    handleChange={(selectedOption) => setProjectManager(selectedOption.value)}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Site Engineers <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <Select
                    isMulti
                    name="siteEngineers"
                    onChange={(selectedOptions) => handleTeamChange(selectedOptions, 'siteEngineers')}
                    options={employeesOptionsList}
                    defaultValue={siteEngineersDefaultList}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#8E8EA1',
                      }),
                    }}
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>
                    Staff Members <span className='required'>*</span>
                  </label>
                </div>
                <div className='w-full md:w-11/12'>
                  <Select
                    isMulti
                    name="staffMembers"
                    onChange={(selectedOptions) => handleTeamChange(selectedOptions, 'staffMembers')}
                    options={employeesOptionsList}
                    defaultValue={staffMembersDefaultList}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: '#8E8EA1',
                      }),
                    }}
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>Covered Area (sq. ft.) <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    name="coveredArea"
                    value={coveredArea}
                    onChange={(e) => {
                      let enteredValue = parseInt(e.target.value);
                      if (isNaN(enteredValue))
                        setCoveredArea(0);
                      else
                        setCoveredArea(enteredValue);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none '
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>Plot Area (sq. ft.) <span className='required'>*</span></label>
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    name="plotArea"
                    value={plotArea}
                    onChange={(e) => {
                      let enteredValue = parseInt(e.target.value)
                      if (isNaN(enteredValue))
                        setPlotArea(0);
                      else
                        setPlotArea(enteredValue);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none '
                  />
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>No. of Units</label>
                </div>
                <div>
                  <input
                    type="number"
                    min="0"
                    name="numberOfUnits"
                    value={numberOfUnits}
                    onChange={(e) => {
                      let enteredValue = parseInt(e.target.value)
                      if (isNaN(enteredValue))
                        setNumberOfUnits(0);
                      else
                        setNumberOfUnits(enteredValue);
                    }}
                    className='border border-[#8E8EA1] w-full md:w-11/12 py-2 rounded-md px-3 outline-none '
                  />
                </div>
              </div>

            </div>

            <div className='flex flex-col md:flex-row gap-y-5'>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>Pictures</label>
                </div>
                <div>
                  <input
                    type="file"
                    accept="image/<span className='required'>*</span>"
                    name="projectPictures"
                    onChange={handleProjectPicturesChange}
                    ref={projectPicturesRef}
                    hidden
                  />
                  <div
                    className="border-2 border-dashed p-1 rounded-md mb-3 cursor-pointer w-full md:w-11/12 h-[150px] flex items-center justify-center text-2xl font-bold text-gray-700"
                    onClick={() => projectPicturesRef?.current.click()}
                  >
                    +
                  </div>
                  {
                    projectPictures && projectPictures.length > 0 && projectPictures.map((dataURL, i) => (
                      <div
                        key={i}
                        className="w-full md:w-11/12 mb-3 rounded-md relative"
                      >
                        <img
                          src={dataURL}
                          alt="Project images"
                          className="rounded-md object-cover"
                        />
                        <div
                          className="absolute top-1 right-1 text-white hover:text-red-500 rounded-sm px-1 cursor-pointer"
                          onClick={() => removeProjectPicture(dataURL)}
                        >
                          X
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>Soil Report</label>
                </div>
                <div>
                  <input
                    type="file"
                    name="projectSoilReport"
                    onChange={handleProjectSoilReportChange}
                    ref={projectSoilReportRef}
                    hidden
                  />
                  {
                    !projectSoilReport && (
                      <div
                        className="border-2 border-dashed p-1 rounded-md mb-3 cursor-pointer w-full md:w-11/12 h-[30px] flex items-center justify-center text-2xl font-bold text-gray-700"
                        onClick={() => projectSoilReportRef?.current.click()}
                      >
                        +
                      </div>

                    )
                  }
                  {
                    projectSoilReport && (
                      <div
                        className="w-full md:w-11/12 mb-3 rounded-md relative"
                      >
                        <div
                          className="w-full h-[150px]"
                        >
                          <div
                            className="absolute right-1 text-gray-900 hover:text-red-500 rounded-sm px-1 cursor-pointer"
                            onClick={() => removeProjectSoilReport()}
                          >
                            x
                          </div>
                          {
                            projectSoilReport.fileNameOnServerDisk ? (
                              <a
                                href={`${staticFilesURL}/projects/${projectSoilReport.fileNameOnServerDisk}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {projectSoilReport.clientFileName}
                              </a>
                            ) : (
                              <a
                                href={projectSoilReport.fileAsBase64}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {projectSoilReport.clientFileName}
                              </a>
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>

              <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
                <div>
                  <label className='text-[#22242C] font-normal'>BOQ</label>
                </div>
                <div>
                  <input
                    type="file"
                    name="projectBOQDocument"
                    onChange={handleProjectBOQDocumentChange}
                    ref={projectBOQDocumentRef}
                    hidden
                  />
                  {
                    !projectBOQDocument && (
                      <div
                        className="border-2 border-dashed p-1 rounded-md mb-3 cursor-pointer w-full md:w-11/12 h-[30px] flex items-center justify-center text-2xl font-bold text-gray-700"
                        onClick={() => projectBOQDocumentRef?.current.click()}
                      >
                        +
                      </div>

                    )
                  }
                  {
                    projectBOQDocument && (
                      <div
                        className="w-full md:w-11/12 mb-3 rounded-md relative"
                      >
                        <div
                          className="w-full h-[150px]"
                        >
                          <div
                            className="absolute right-1 text-gray-900 hover:text-red-500 rounded-sm px-1 cursor-pointer"
                            onClick={() => removeProjectBOQDocument()}
                          >
                            x
                          </div>
                          {
                            projectBOQDocument.fileNameOnServerDisk ? (
                              <a
                                href={`${staticFilesURL}/projects/${projectBOQDocument.fileNameOnServerDisk}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {projectBOQDocument.clientFileName}
                              </a>
                            ) : (
                              <a
                                href={projectBOQDocument.fileAsBase64}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {projectBOQDocument.clientFileName}
                              </a>
                            )
                          }
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>

          </div>

          <div className='flex flex-row my-5'>
            <button disabled={isSubmitting} type="submit" id="submit" className='text-white bg-primary rounded-[40px] w-[299px] h-9'>
              {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
            </button>
          </div>

        </form>
      </div >
    </Layout >
  );
}