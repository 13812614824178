import React from 'react';
import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import './payChequeCalculator.css';
import { AuthContext } from '../../context/AuthContext.jsx';
import { useContext } from 'react';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import { toast } from 'react-toastify';
import SelectBox from 'components/global/SelectBox.jsx';
import { ClipLoader } from 'react-spinners';
import DatePicker from "react-datepicker";

export const PayChequeCalculator = () => {
  const [message, setMessage] = useState(null);

  const [employees, setEmployees] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [month, setMonth] = useState(new Date());

  const [response, setResponse] = useState(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { userData } = useContext(AuthContext);

  async function fetchData() {
    let getEmployeesURL = baseURL + "/employees";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const getEmployeesResponse = await fetch(getEmployeesURL, {
      method: "GET",
      headers: headers
    });
    const getEmployeesJSONData = await getEmployeesResponse.json();
    if (!getEmployeesResponse.ok) {
      toast.error(getEmployeesJSONData.data.message);
      setMessage(getEmployeesJSONData.data.message);
      setIsLoadingError(true);
      setIsLoading(false);
      return; //early return
    }
    const employees = getEmployeesJSONData.data.docs;

    setEmployees(employees);
    setIsLoading(false)
  }

  async function sendAPIRequest(payload) {
    let url = baseURL + "/reports/calculate-pay";
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(payload)
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      setResponse(jsonData.data.doc);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      setMessage(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    if (!employee) {
      errors.push(<div>Employee cannot be empty</div>);
    }

    month.setUTCHours(0);
    month.setUTCMinutes(0);
    month.setUTCSeconds(0);
    month.setUTCMilliseconds(0);
    month.setDate(1);

    let payload = {
      employeeID: employee,
      month,
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors.";
      toast.error(errorMessage);
      setMessage(errors);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  useEffect(() => {
    fetchData().catch(e => {
      setIsLoading(false);
      setMessage("An error occurred while communicating with the server.");
      toast.error("An error occurred while communicating with the server.");
    });
    // eslint-disable-next-line
  }, []);


  if (isLoading || (!isLoading && isLoadingError)) {
    return (
      <Layout>
        <div className='space-y-5'>
          <div className='flex flex-row justify-between items-center'>
            <h2 className='text-2xl text-primary font-semibold'>Pay Cheque Calculator</h2>
          </div>
          <div className='flex flex-row justify-start'>
            <div>
              {message && message}
            </div>
          </div>
          {isLoading && <Loader />}
        </div>
      </Layout>
    );
  }

  console.log("Before PayChequeCalculator() rendering");

  return (
    <Layout>
      <div className='space-y-4'>

        <div className='flex flex-row justify-between items-center px-3'>
          <h2 className='text-2xl text-primary font-bold'>Pay Cheque Calculator</h2>
        </div>

        <div className='flex flex-row justify-start'>
          <div>
            {message && message}
          </div>
        </div>

        <div className='rounded-lg border border-[#6843BE] p-8 text-sm'>

          <div className='flex flex-col md:flex-row md:flex-wrap gap-y-5 md:items-center'>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='font-normal'>
                  Employee Name <span className='required'>*</span>
                </label>
              </div>
              <div className='w-full md:w-11/12'>
                <SelectBox
                  name="employee"
                  options={
                    employees && employees.length !== 0 && employees.map((employee, index) => {
                      return {
                        value: employee._id,
                        label: `${employee.full_name} ${employee.custom_id}`
                      }
                    })
                  }
                  handleChange={(selectedOption) => setEmployee(selectedOption.value)}
                />
              </div>
            </div>

            <div className='flex flex-col gap-y-3 md:basis-1/2 lg:basis-1/3'>
              <div>
                <label className='font-normal'>Month <span className='required'>*</span></label>
              </div>
              <div className='w-full md:w-11/12'>
                <DatePicker
                  selected={month}
                  onChange={(date) => {
                    setMonth(date)
                  }
                  }
                  dateFormat="MMMM yyyy"
                  showMonthYearPicker
                />
              </div>
            </div>

          </div>

          <div className='flex flex-row my-5'>
            <button
              disabled={isSubmitting || isDisabled}
              onClick={handleSubmit}
              className='text-white bg-primary rounded-[40px] w-[299px] h-9'
            >
              {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Submit"}
            </button>
          </div>

          {
            response && (
              <React.Fragment>
                <div className='flex flex-col md:flex-row md:flex-wrap gap-y-2'>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Employee ID:</span> {response.employee.custom_id}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Full Name:</span> {response.employee.full_name}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Salary:</span> {response.employee.salary}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Month:</span> {response.jsMonthStart.split('T')[0]} -- {response.jsMonthEnd.split('T')[0]}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Days in month:</span> {response.daysInMonth}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Sundays in month:</span> {response.sundaysDates.length}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Public Holidays in month:</span> {response.publicHolidaysDates.length}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Public Holidays on sunday:</span> {response.publicHolidaysOnSunday.length}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Company working days:</span> {
                      response.companyWorkingDays
                    }</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Days on leave:</span> {response.daysOnLeave}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Employee working days:</span> {
                      response.employeeWorkingDays
                    }</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Biometric Attendances:</span> {response.attendances.length}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Absent days:</span> {response.absentDays}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Half days:</span> {response.halfDayAttendances.length}</div>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <div><span className='font-semibold'>Off days:</span> {response.offDayAttendances.length}</div>
                  </div>

                </div>

                <div className='flex flex-col gap-y-2 mt-2'>

                  <div><span className='font-semibold'>One absent day deduction:</span> {
                    response.absentDayDeduction.toFixed(2)
                  } @ {response.leaveRule.absentDayDeduction}% of one day salary</div>

                  <div><span className='font-semibold'>One half day deduction:</span> {
                    response.halfDayDeduction.toFixed(2)
                  } @ {response.leaveRule.halfDayDeduction}% of one day salary</div>

                  <div><span className='font-semibold'>One off day deduction:</span> {
                    response.offDayDeduction.toFixed(2)
                  } @ {response.leaveRule.offDayDeduction}% of one day salary</div>

                  <div><span className='font-semibold'>Absent days deduction:</span> {
                    response.absentDaysDeduction.toFixed(2)
                  }</div>

                  <div><span className='font-semibold'>Half days deduction:</span> {
                    response.halfDaysDeduction.toFixed(2)
                  }</div>

                  <div><span className='font-semibold'>Off days deduction:</span> {
                    response.offDaysDeduction.toFixed(2)
                  }</div>

                  <div><span className='font-semibold'>Bonus:</span> {
                    response.totalBonusAmount.toFixed(2)
                  }</div>

                  <div><span className='font-semibold'>Net Salary:</span> {
                    response.netSalary.toFixed(2)
                  }</div>

                </div>

                <div className='salary-calculations flex flex-col md:flex-row md:flex-wrap gap-y-2 mt-4'>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            Public Holiday
                          </th>
                          <th>
                            Date
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          response.publicHolidays.length > 0 ? response.publicHolidays.map(
                            (holiday, index) => {
                              return (
                                <tr key={index}>
                                  <td>{holiday.title}</td>
                                  <td>{holiday.date.split('T')[0]}</td>
                                </tr>
                              )
                            }
                          ) : (
                            <td colSpan={2}>Not found!</td>
                          )
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            Half days
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          response.halfDayAttendances.length > 0 ? response.halfDayAttendances.map(
                            (halfDay, index) => {
                              return (
                                <tr key={index}>
                                  <td>{new Date(halfDay.checkInTime).toString().split('(')[0]}</td>
                                </tr>
                              )
                            }
                          ) : (
                            <td>Not found!</td>
                          )
                        }
                      </tbody>
                    </table>
                  </div>

                  <div className='md:basis-1/2 lg:basis-1/3'>
                    <table>
                      <thead>
                        <tr>
                          <th>
                            Off days
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          response.offDayAttendances.length > 0 ? response.offDayAttendances.map(
                            (halfDay, index) => {
                              return (
                                <tr key={index}>
                                  <td>{new Date(halfDay.checkInTime).toString().split('(')[0]}</td>
                                </tr>
                              )
                            }
                          ) : (
                            <td>Not found!</td>
                          )
                        }
                      </tbody>
                    </table>
                  </div>

                </div>
              </React.Fragment>
            )
          }

        </div>

      </div>
    </Layout >
  );
}
