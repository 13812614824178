import { baseURL } from 'config/api.js';
import { useState, useEffect } from 'react';
import { AuthContext } from "context/AuthContext";
import { Admins } from "pages/admins/Admins";
import { Companies } from "pages/companies/Companies";
import { CompaniesNew } from "pages/companiesNew/CompaniesNew"
import { Dashboard } from "pages/dashboard/Dashboard";
import { Demands } from "pages/demands/Demands";
import { DemandsNew } from "pages/demandsNew/DemandsNew";
import { Departments } from "pages/departments/Departments"
import { DepartmentsNew } from "pages/departmentsNew/DepartmentsNew"
import { Employees } from "pages/employees/Employees";
import { EmployeesNew } from "pages/employeesNew/EmployeesNew";
import { EmployeesTasks } from "pages/employeesTasks/EmployeesTasks";
import { EmployeesTasksNew } from "pages/employeesTasksNew/EmployeesTasksNew"
import { Login } from "pages/login/Login";
import { MyTasks } from "pages/myTasks/MyTasks";
import { PaymentVouchers } from "pages/paymentVouchers/paymentVouchers";
import { PaymentVouchersNew } from "pages/paymentVouchersNew/PaymentVouchersNew"
import { Projects } from "pages/projects/Projects";
import { ProjectsNew } from "pages/projectsNew/ProjectsNew";
import { ProjectsCategories } from "pages/projectsCategories/ProjectsCategories";
import { ProjectsCategoriesNew } from "pages/projectsCategoriesNew/ProjectsCategoriesNew";
import { PurchaseOrders } from "pages/purchaseOrders/purchaseOrders";
import { PurchaseOrdersNew } from "pages/purchaseOrdersNew/PurchaseOrdersNew"
import { Quotations } from "pages/quotations/Quotations";
import { QuotationsNew } from "pages/quotationsNew/QuotationsNew"
import { Roles } from "pages/roles/Roles";
import { RolesNew } from "pages/rolesNew/RolesNew"
import { Vendors } from "pages/vendors/Vendors";
import { VendorsNew } from "pages/vendorsNew/VendorsNew"
import { useContext } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EmployeesEdit } from "pages/employeesEdit/EmployeesEdit";
import { TasksOfEmployee } from "pages/tasksOfEmployee/TasksOfEmployee";
import { EmployeesTasksEdit } from "pages/employeesTasksEdit/EmployeesTasksEdit";
import { DemandsEdit } from "pages/demandsEdit/DemandsEdit";
import { QuotationsComparison } from "pages/quotationsComparison/QuotationsComparison";
import { QuotationsEdit } from "pages/quotationsEdit/QuotationsEdit";
import { RolesEdit } from "pages/rolesEdit/RolesEdit";
import { AdminNew } from "pages/adminCreate/AdminNew";
import { VendorsEdit } from "pages/vendorsEdit/VendorsEdit";
import { StockItems } from "pages/stockItems/StockItems";
import { StockItemsNew } from "pages/stockItemsNew/StockItemsNew";
import { StockItemsEdit } from "pages/stockItemsEdit/StockItemsEdit";
import { PurchaseOrdersEdit } from "pages/purchaseOrdersEdit/PurchaseOrdersEdit";
import { ProjectsEdit } from "pages/projectsEdit/ProjectsEdit";
import { PaymentVouchersFind } from "pages/paymentVouchersFind/paymentVouchersFind";
import { MaterialIssues } from "pages/materialIssues/MaterialIssues";
import { MaterialIssuesNew } from "pages/materialIssuesNew/materialIssuesNew";
import { ProjectsCategoriesEdit } from "pages/projectsCategoriesEdit/ProjectsCategoriesEdit";
import { CompaniesEdit } from "pages/companiesEdit/CompaniesEdit";
import { DepartmentsEdit } from "pages/departmentsEdit/DepartmentsEdit";
import { StockCategories } from "pages/stockCategories/StockCategories";
import { StocksCategoriesNew } from "pages/stockCategoriesNew/StockCategoriesNew";
import { StocksCategoriesEdit } from "pages/stockCategoriesEdit/StocksCategoriesEdit";
import { PaymentVouchersEdit } from "pages/paymentVouchersEdit/PaymentVouchersEdit";
import { VendorsView } from "pages/vendorsView/VendorsView";
import { MaterialReceiptSlipsNew } from "pages/materialReceiptSlipsNew/MaterialReceiptSlipsNew";
import { MaterialReceiptSlips } from "pages/materialReceiptSlips/materialReceiptSlips";
import { MaterialReceiptSlipsEdit } from "pages/materialReceiptSlipsEdit/MaterialReceiptSlipsEdit";

import useDisableScrollOnNumberInput from "hooks/useDisableScrollOnNumberInput.js";
import { ProjectsView } from "pages/projectsView/ProjectsView";
import { EmployeesTasksView } from "pages/employeesTasksView/EmployeesTasksView";
import { EmployeesSalaries } from "pages/employeesSalaries/EmployeesSalaries";
import { EmployeesIncrements } from "pages/employeesIncrements/EmployeesIncrements";
import { EmployeesIncrementsNew } from "pages/employeesIncrementsNew/EmployeesIncrementsNew";
import { EmployeesLeaves } from "pages/employeesLeaves/EmployeesLeaves";
import { EmployeesLeavesNew } from "pages/employeesLeavesNew/EmployeesLeavesNew";
import { EmployeesLeavesEdit } from "pages/employeesLeavesEdit/EmployeesLeavesEdit";
import { MyLeaves } from "pages/myLeaves/myLeaves";
import { toast } from 'react-toastify';
import { MyLeavesNew } from 'pages/myLeavesNew/MyLeavesNew';
import { EmployeesBonuses } from 'pages/employeesBonuses/EmployeesBonuses';
import { EmployeesComplaints } from 'pages/employeesComplaints/EmployeesComplaints';
import { MyComplaints } from 'pages/myComplaints/MyComplaints';
import { MyComplaintsNew } from 'pages/myComplaintsNew/MyComplaintsNew';
import { EmployeesComplaintsNew } from 'pages/employeesComplaintsNew/EmployeesComplaintsNew';
import { EmployeesComplaintsEdit } from 'pages/employeesComplaintsEdit/EmployeesComplaintsEdit';
import { MyComplaintsEdit } from 'pages/myComplaintsEdit/MyComplaintsEdit';
import { MyLeavesEdit } from 'pages/myLeavesEdit/MyLeavesEdit';
import { EventsNew } from 'pages/eventsNew/eventsNew';
import { Events } from 'pages/events/Events';
import { CompanyCalendar } from 'pages/calendar/Calendar';
import { EventsEdit } from 'pages/eventsEdit/eventsEdit';
import { WorkingHoursNew } from 'pages/workingHoursNew/WorkingHoursNew';
import { WorkingHours } from 'pages/workingHours/WorkingHours';
import { WorkingHoursEdit } from 'pages/workingHoursEdit/WorkingHoursEdit';
import { EmployeesAttendancesNew } from 'pages/employeesAttendancesNew/EmployeesAttendancesNew';
import { MyAttendance } from 'pages/myAttendance/MyAttendance';
import { EmployeesAttendances } from 'pages/employeesAttendances/employeesAttendances';
import { LeaveRules } from 'pages/leaveRules/LeaveRules';
import { LeaveRulesNew } from 'pages/leaveRulesNew/LeaveRulesNew';
import { LeaveRulesEdit } from 'pages/leaveRulesEdit/LeaveRulesEdit';
import { EmployeeReport } from 'pages/employeeReport/EmployeeReport';
import { PayChequeCalculator } from 'pages/payChequeCalculator/PayChequeCalculator';
import { PublicHolidaysNew } from 'pages/publicHolidaysNew/PublicHolidaysNew';
import { PublicHolidays } from 'pages/publicHolidays/PublicHolidays';
import { PublicHolidaysEdit } from 'pages/publicHolidaysEdit/PublicHolidaysEdit';
import { Notifications } from 'pages/notifications/Notifications';
import { NotificationsNew } from 'pages/notificationsNew/NotificationsNew';
import { MyMeetings } from 'pages/myMeetings/MyMeetings';
import { EmployeesProfile } from 'pages/employeesView/EmployeesProfile';
import { HrDashboard } from 'pages/hrDashboard/HrDashboard';
import { CompanyDashboard } from 'pages/companiesDashboard/companiesDashboard';
import { TaskDashboard } from 'pages/taskDashboard/Dashboard';
import { ProjectDashboard } from 'pages/projectDashboard/ProjectDashboard';
import { StockDashboard } from 'pages/stockDashboard/stockDashboard';

const PrivateRoute = ({ children, redirectPath = '/login' }) => {
  const { userData, removeUserData } = useContext(AuthContext);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  console.log("Entering PrivateRoute()");

  //Checking if the token is expired

  useEffect(() => {
    console.log("Entering PrivateRoute useEffect")

    async function fetchData() {
      let url = baseURL + "/isAuthenticated";
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: headers
        });
        const jsonData = await response.json();
        if (!response.ok) {
          toast.error(jsonData.data.message);
          removeUserData();
          setIsAuthenticated(false);
          return; //early return;
        }
        if (jsonData.data.doc.password !== userData.doc.password) {
          toast.error("Your password is changed.");
          removeUserData();
          setIsAuthenticated(false);
        }
      }
      catch (error) {
        setIsAuthenticated(false);
        toast.error("An error occurred while communicating with the server.");
      }
    }

    if (!userData || !userData.token) {
      setIsAuthenticated(false);
    }
    else {
      fetchData();
    }
    // eslint-disable-next-line
  } );

  if (!userData || !userData.token) {
    return <Navigate to={redirectPath} replace />;
  }

  if (isAuthenticated) {
    return children;
  }
  else {
    return <Navigate to={redirectPath} replace />;
  }
};

function App() {
  useDisableScrollOnNumberInput();
  return (
    <div className="">
      <ToastContainer
        style={{ fontSize: 15 }}
        position="top-center"
        autoClose={3000}
        hideProgressBar
        closeOnClick
        pauseOnHover
      />

      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/hr/dashboard"
          element={
            <PrivateRoute>
              <HrDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees"
          element={
            <PrivateRoute>
              <Employees />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/new"
          element={
            <PrivateRoute>
              <EmployeesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees/edit/:id"
          element={
            <PrivateRoute>
              <EmployeesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-profile/:id"
          element={
            <PrivateRoute>
              <EmployeesProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-salaries"
          element={
            <PrivateRoute>
              <EmployeesSalaries />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-increments"
          element={
            <PrivateRoute>
              <EmployeesIncrements />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-increments/new"
          element={
            <PrivateRoute>
              <EmployeesIncrementsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-leaves"
          element={
            <PrivateRoute>
              <EmployeesLeaves />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-leaves/new"
          element={
            <PrivateRoute>
              <EmployeesLeavesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-leaves/edit/:id"
          element={
            <PrivateRoute>
              <EmployeesLeavesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-bonuses"
          element={
            <PrivateRoute>
              <EmployeesBonuses />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-complaints"
          element={
            <PrivateRoute>
              <EmployeesComplaints />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-complaints/new"
          element={
            <PrivateRoute>
              <EmployeesComplaintsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-complaints/edit/:id"
          element={
            <PrivateRoute>
              <EmployeesComplaintsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-attendances"
          element={
            <PrivateRoute>
              <EmployeesAttendances />
            </PrivateRoute>
          }
        />
        <Route
          path="/paycheque-calculator"
          element={
            <PrivateRoute>
              <PayChequeCalculator />
            </PrivateRoute>
          }
        />
        <Route
          path="/leave-rules"
          element={
            <PrivateRoute>
              <LeaveRules />
            </PrivateRoute>
          }
        />
        <Route
          path="/leave-rules/new"
          element={
            <PrivateRoute>
              <LeaveRulesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/leave-rules/edit/:id"
          element={
            <PrivateRoute>
              <LeaveRulesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/employees-attendances/new"
          element={
            <PrivateRoute>
              <EmployeesAttendancesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-complaints"
          element={
            <PrivateRoute>
              <MyComplaints />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-complaints/new"
          element={
            <PrivateRoute>
              <MyComplaintsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-complaints/edit/:id"
          element={
            <PrivateRoute>
              <MyComplaintsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-leaves"
          element={
            <PrivateRoute>
              <MyLeaves />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-leaves/new"
          element={
            <PrivateRoute>
              <MyLeavesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-attendance"
          element={
            <PrivateRoute>
              <MyAttendance />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-leaves/edit/:id"
          element={
            <PrivateRoute>
              <MyLeavesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/my-meetings"
          element={
            <PrivateRoute>
              <MyMeetings />
            </PrivateRoute>
          }
        />
        <Route
          path="/mytasks"
          element={
            <PrivateRoute>
              <MyTasks />
            </PrivateRoute>
          }
        />
         <Route
          path="/task/dashboard"
          element={
            <PrivateRoute>
              <TaskDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/employeestasks"
          element={
            <PrivateRoute>
              <EmployeesTasks />
            </PrivateRoute>
          }
        />
        <Route
          path="/employeestasks/:id"
          element={
            <PrivateRoute>
              <EmployeesTasksView />
            </PrivateRoute>
          }
        />
        <Route
          path="/employeestasks/new"
          element={
            <PrivateRoute>
              <EmployeesTasksNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/employeestasks/edit/:id"
          element={
            <PrivateRoute>
              <EmployeesTasksEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/employeestasks/of-employee/:id"
          element={
            <PrivateRoute>
              <TasksOfEmployee />
            </PrivateRoute>
          }
        />
        <Route
          path="/events"
          element={
            <PrivateRoute>
              <Events />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/new"
          element={
            <PrivateRoute>
              <EventsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/events/edit/:id"
          element={
            <PrivateRoute>
              <EventsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/calendar"
          element={
            <PrivateRoute>
              <CompanyCalendar />
            </PrivateRoute>
          }
        />
        <Route
          path="/public-holidays"
          element={
            <PrivateRoute>
              <PublicHolidays />
            </PrivateRoute>
          }
        />
        <Route
          path="/public-holidays/new"
          element={
            <PrivateRoute>
              <PublicHolidaysNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/public-holidays/edit/:id"
          element={
            <PrivateRoute>
              <PublicHolidaysEdit />
            </PrivateRoute>
          }
        />
         <Route
          path="/companies/dashboard"
          element={
            <PrivateRoute>
              <CompanyDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/companies"
          element={
            <PrivateRoute>
              <Companies />
            </PrivateRoute>
          }
        />
        <Route
          path="/companies/new"
          element={
            <PrivateRoute>
              <CompaniesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/companies/edit/:id"
          element={
            <PrivateRoute>
              <CompaniesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/departments"
          element={
            <PrivateRoute>
              <Departments />
            </PrivateRoute>
          }
        />
        <Route
          path="/departments/new"
          element={
            <PrivateRoute>
              <DepartmentsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/departments/edit/:id"
          element={
            <PrivateRoute>
              <DepartmentsEdit />
            </PrivateRoute>
          }
        />
         <Route
          path="/projects/dashboard"
          element={
            <PrivateRoute>
              <ProjectDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects"
          element={
            <PrivateRoute>
              <Projects />
            </PrivateRoute>
          }
        />
        <Route
          path="/projects/:id"
          element={
            <PrivateRoute>
              <ProjectsView />
            </PrivateRoute>
          }
        />
        <Route
          path="projects/new"
          element={
            <PrivateRoute>
              <ProjectsNew />
            </PrivateRoute>
          }
        />
        <Route
          path='projects/edit/:id'
          element={
            <PrivateRoute>
              <ProjectsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/projectscategories"
          element={
            <PrivateRoute>
              <ProjectsCategories />
            </PrivateRoute>
          }
        />
        <Route
          path="/projectscategories/new"
          element={
            <PrivateRoute>
              <ProjectsCategoriesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/projectscategories/edit/:id"
          element={
            <PrivateRoute>
              <ProjectsCategoriesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/demands"
          element={
            <PrivateRoute>
              <Demands />
            </PrivateRoute>
          }
        />
        <Route
          path="/demands/new"
          element={
            <PrivateRoute>
              <DemandsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/demands/edit/:id"
          element={
            <PrivateRoute>
              <DemandsEdit />
            </PrivateRoute>
          }
        />

        <Route
          path="/quotations"
          element={
            <PrivateRoute>
              <Quotations />
            </PrivateRoute>
          }
        />
        <Route
          path="/quotations/new/of-demand/:id"
          element={
            <PrivateRoute>
              <QuotationsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/quotations/edit/:id"
          element={
            <PrivateRoute>
              <QuotationsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/compareQuotations/of-demand/:id"
          element={
            <PrivateRoute>
              <QuotationsComparison />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchaseorders"
          element={
            <PrivateRoute>
              <PurchaseOrders />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchaseorders/edit/:id"
          element={
            <PrivateRoute>
              <PurchaseOrdersEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/purchaseorders/new/of-quotation/:id"
          element={
            <PrivateRoute>
              <PurchaseOrdersNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/materialreceiptslips/"
          element={
            <PrivateRoute>
              <MaterialReceiptSlips />
            </PrivateRoute>
          }
        />
        <Route
          path="/materialreceiptslips/new/of-purchaseorder/:id"
          element={
            <PrivateRoute>
              <MaterialReceiptSlipsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/materialreceiptslips/edit/:id"
          element={
            <PrivateRoute>
              <MaterialReceiptSlipsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/paymentvouchers"
          element={
            <PrivateRoute>
              <PaymentVouchers />
            </PrivateRoute>
          }
        />
        <Route
          path="/paymentvouchers/new/of-purchaseorder/:id"
          element={
            <PrivateRoute>
              <PaymentVouchersNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/paymentvouchers/edit/:id"
          element={
            <PrivateRoute>
              <PaymentVouchersEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="paymentvouchers/of-purchaseorder/:id"
          element={
            <PrivateRoute>
              <PaymentVouchersFind />
            </PrivateRoute>
          }
        />
         <Route
          path="/stocks/dashboard"
          element={
            <PrivateRoute>
              <StockDashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/stockitems"
          element={
            <PrivateRoute>
              <StockItems />
            </PrivateRoute>
          }
        />
        <Route
          path="/stockitems/new"
          element={
            <PrivateRoute>
              <StockItemsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/stockitems/edit/:id"
          element={
            <PrivateRoute>
              <StockItemsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/materialissues"
          element={
            <PrivateRoute>
              <MaterialIssues />
            </PrivateRoute>
          }
        />
        <Route
          path="/materialissues/new"
          element={
            <PrivateRoute>
              <MaterialIssuesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/materialissues/edit/:id"
          element={
            <PrivateRoute>
              <MaterialIssuesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/stockcategories"
          element={
            <PrivateRoute>
              <StockCategories />
            </PrivateRoute>
          }
        />
        <Route
          path="/stockcategories/new"
          element={
            <PrivateRoute>
              <StocksCategoriesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/stockcategories/edit/:id"
          element={
            <PrivateRoute>
              <StocksCategoriesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendors"
          element={
            <PrivateRoute>
              <Vendors />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendors/:id"
          element={
            <PrivateRoute>
              <VendorsView />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendors/new"
          element={
            <PrivateRoute>
              <VendorsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/vendors/edit/:id"
          element={
            <PrivateRoute>
              <VendorsEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/employee-report"
          element={
            <PrivateRoute>
              <EmployeeReport />
            </PrivateRoute>
          }
        />
        <Route
          path="/working-hours"
          element={
            <PrivateRoute>
              <WorkingHours />
            </PrivateRoute>
          }
        />
        <Route
          path="/working-hours/new"
          element={
            <PrivateRoute>
              <WorkingHoursNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/working-hours/edit/:id"
          element={
            <PrivateRoute>
              <WorkingHoursEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/roles"
          element={
            <PrivateRoute>
              <Roles />
            </PrivateRoute>
          }
        />
        <Route
          path="/roles/new"
          element={
            <PrivateRoute>
              <RolesNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/roles/edit/:id"
          element={
            <PrivateRoute>
              <RolesEdit />
            </PrivateRoute>
          }
        />
        <Route
          path="/admins"
          element={
            <PrivateRoute>
              <Admins />
            </PrivateRoute>
          }
        />
        <Route
          path="/admins/new"
          element={
            <PrivateRoute>
              <AdminNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <PrivateRoute>
              <NotificationsNew />
            </PrivateRoute>
          }
        />
        <Route
          path="/*"
          element={
            <PrivateRoute>
              <Navigate to="/dashboard" replace={true} />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

export default App;
