import React, { useState, useContext } from 'react';
import { baseURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';
import RichEditor from 'components/global/RichEditor';
import parse from 'html-react-parser';

export const TaskTabDiscussion = ({ comments, setTask, params }) => {

  const [content, setContent] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { userData } = useContext(AuthContext);

  async function sendAPIRequest(payload) {
    let url = baseURL + "/employeestasks/add-comment/" + params.id;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userData.token
    };
    const response = await fetch(url, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(payload),
    });
    const jsonData = await response.json();
    if (jsonData.success) {
      toast.success(jsonData.data.message);
      setTask(jsonData.data.doc);
      return Promise.resolve("success");
    }
    else {
      toast.error(jsonData.data.message);
      return Promise.reject("failure");
    }
  }

  async function handleAddNewComment(event) {
    event.preventDefault();
    let errors = [];

    setIsSubmitting(true);
    setIsDisabled(true);

    let payload = {
      content
    };

    if (errors.length > 0) {
      let errorMessage = "Please correct the errors in the form.";
      toast.error(errorMessage);
      setIsSubmitting(false);
      setIsDisabled(false);
    }
    else {
      try {
        await sendAPIRequest(payload);
        setIsSubmitting(false);
        setIsDisabled(false);
      }
      catch (error) {
        setIsSubmitting(false);
        setIsDisabled(false);
      }
    }
  }

  console.log("Before TaskTabDiscussion() rendering");

  return (
    <React.Fragment>
      {
        comments && comments.length > 0 ? comments.map(
          (comment, index) => {
            return (
              <div key={comment._id} className='p-4 flex flex-col gap-y-2 border-b'>

                <div className='flex flex-row gap-x-2 items-center'>

                  <div className='font-bold text-left'>
                    {comment.employeeID.full_name}
                  </div>

                  <div className='font-normal text-left text-[10px]'>
                    {new Date(comment.createdAt).toString().split('(')[0]}
                  </div>

                </div>

                <div>{parse(comment.content)}</div>

              </div>
            )
          }
        ) : (
          <div className='py-4'>No comments yet!</div>
        )
      }

      <div className='flex flex-col gap-3 my-5'>
        <RichEditor content={content} setContent={setContent} />

        <button
          disabled={isSubmitting || isDisabled}
          onClick={handleAddNewComment}
          className='bg-primary mt-4 py-1 px-4 text-white border rounded shadow-md w-[299px]'
        >
          {isSubmitting ? <ClipLoader size={17} color="#fff" /> : "Add new coment"}
        </button>
      </div>
    </React.Fragment>
  )
}