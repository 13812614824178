import React from 'react'

export const DiamondSVG = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1_490)">
        <path d="M23.9176 10.9125L20.9176 4.9125C20.8552 4.78881 20.7599 4.6848 20.6421 4.61199C20.5242 4.53918 20.3886 4.50042 20.2501 4.5H3.75006C3.61156 4.50042 3.47588 4.53918 3.35806 4.61199C3.24024 4.6848 3.14488 4.78881 3.08256 4.9125L0.0825572 10.9125C0.0857371 10.9349 0.0857371 10.9576 0.0825572 10.98C0.0790042 11.045 0.0790042 11.11 0.0825572 11.175L5.72383e-05 11.25C-0.000670121 11.2625 -0.000670121 11.275 5.72383e-05 11.2875C0.00709232 11.359 0.0221883 11.4294 0.0450572 11.4975C0.0450572 11.4975 0.0450572 11.535 0.0450572 11.5575C0.0757511 11.6335 0.121696 11.7024 0.180057 11.76L11.4301 23.76C11.5002 23.8349 11.585 23.8947 11.6792 23.9355C11.7734 23.9763 11.8749 23.9974 11.9776 23.9974C12.0802 23.9974 12.1818 23.9763 12.2759 23.9355C12.3701 23.8947 12.4549 23.8349 12.5251 23.76L23.7751 11.76C23.8828 11.6504 23.9542 11.5103 23.9797 11.3587C24.0052 11.2071 23.9835 11.0513 23.9176 10.9125ZM12.0001 6.6675L14.4001 10.5H9.60006L12.0001 6.6675ZM8.19006 9.9225L5.25006 6H10.6501L8.19006 9.9225ZM13.3501 6H18.7501L15.8101 9.9225L13.3501 6ZM14.7301 12L12.0001 20.7375L9.27006 12H14.7301ZM10.3126 20.355L2.48256 12H7.73256L10.3126 20.355ZM16.3126 12H21.5626L13.6876 20.355L16.3126 12ZM22.0501 10.5H17.2501L20.1226 6.6675L22.0501 10.5ZM3.87756 6.6675L6.75006 10.5H1.96506L3.87756 6.6675Z" fill="white" />
        <path d="M12 3C12.1989 3 12.3897 2.92098 12.5303 2.78033C12.671 2.63968 12.75 2.44891 12.75 2.25V0.75C12.75 0.551088 12.671 0.360322 12.5303 0.21967C12.3897 0.0790176 12.1989 0 12 0C11.8011 0 11.6103 0.0790176 11.4697 0.21967C11.329 0.360322 11.25 0.551088 11.25 0.75V2.25C11.25 2.44891 11.329 2.63968 11.4697 2.78033C11.6103 2.92098 11.8011 3 12 3Z" fill="white" />
        <path d="M7.71764 2.78264C7.78736 2.85294 7.87031 2.90873 7.96171 2.94681C8.0531 2.98488 8.15113 3.00449 8.25014 3.00449C8.34915 3.00449 8.44718 2.98488 8.53857 2.94681C8.62997 2.90873 8.71292 2.85294 8.78264 2.78264C8.85294 2.71292 8.90873 2.62997 8.94681 2.53857C8.98488 2.44718 9.00449 2.34915 9.00449 2.25014C9.00449 2.15113 8.98488 2.0531 8.94681 1.96171C8.90873 1.87031 8.85294 1.78736 8.78264 1.71764L8.03264 0.967639C7.89141 0.826411 7.69986 0.74707 7.50014 0.74707C7.30041 0.74707 7.10887 0.826411 6.96764 0.967639C6.82641 1.10887 6.74707 1.30041 6.74707 1.50014C6.74707 1.69987 6.82641 1.89141 6.96764 2.03264L7.71764 2.78264Z" fill="white" />
        <path d="M15.75 3.00014C15.8487 3.00071 15.9465 2.98179 16.0379 2.94447C16.1293 2.90714 16.2124 2.85215 16.2825 2.78264L17.0325 2.03264C17.1737 1.89141 17.253 1.69987 17.253 1.50014C17.253 1.30041 17.1737 1.10887 17.0325 0.967639C16.8912 0.826411 16.6997 0.74707 16.5 0.74707C16.3002 0.74707 16.1087 0.826411 15.9675 0.967639L15.2175 1.71764C15.1472 1.78736 15.0914 1.87031 15.0533 1.96171C15.0152 2.0531 14.9956 2.15113 14.9956 2.25014C14.9956 2.34915 15.0152 2.44718 15.0533 2.53857C15.0914 2.62997 15.1472 2.71292 15.2175 2.78264C15.2875 2.85215 15.3706 2.90714 15.462 2.94447C15.5534 2.98179 15.6512 3.00071 15.75 3.00014Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1_490">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}