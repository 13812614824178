import React, { useState, useContext } from 'react';
import { baseURL } from 'config/api.js';
import { AuthContext } from 'context/AuthContext.jsx';
import { toast } from 'react-toastify';
import { ClipLoader } from 'react-spinners';

export const TaskTabSubtasksView = ({ subtasks, setTask, params }) => {

  const [whichSubtask, setWhichSubtask] = useState("");

  const { userData } = useContext(AuthContext);

  const sendUpdateSubtaskStatusRequest = async (subtaskObjectID) => {
    setWhichSubtask({ subtask: subtaskObjectID, isSubmitting: true });
    let payload = {
      subtaskObjectID,
      employeeTaskObjectId: params.id
    }
    try {
      let url = `${baseURL}/employeestasks/update-subtask-status`;
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + userData.token
      };
      const response = await fetch(url, {
        method: 'PATCH',
        headers: headers,
        body: JSON.stringify(payload)
      });
      const jsonData = await response.json();
      if (jsonData.success) {
        toast.success(jsonData.data.message);
        setWhichSubtask({ subtask: subtaskObjectID, isSubmitting: false });
        setTask(jsonData.data.doc);
        return Promise.resolve("success");
      }
      else {
        toast.error(jsonData.data.message);
        setWhichSubtask({ subtask: subtaskObjectID, isSubmitting: false });
        return Promise.reject("failure");
      }
    }
    catch (error) {
      toast.error("An error occurred while communicating with the server");
    }
  }

  console.log("Before TaskTabSubtasksView() rendering");

  return (
    <React.Fragment>
      {
        subtasks && subtasks.length > 0 ? subtasks.map((subtask, index) => {
          return (
            <div key={subtask._id} className='space-y-3 text-sm bg-darkSlate p-4 rounded'>
              <div className="flex flex-col gap-2">
                <div><strong>Title</strong>: {subtask.subtaskTitle}</div>
                <div><strong>Status</strong>: {subtask.subtaskStatus === 0 ? "Pending" : "Finished"}</div>
                <div><strong>Assigned to</strong>: {subtask.employeeID.full_name}</div>
                <div><strong>Description</strong>: {subtask.subtaskDescription}</div>
                <div className='self-end'>
                  <button
                    className='text-white bg-primary rounded py-2 px-2'
                    onClick={
                      async (e) => {
                        e.preventDefault();
                        try {
                          await sendUpdateSubtaskStatusRequest(subtask._id);
                        }
                        catch (error) {
                          console.log(error);
                        }
                      }
                    }
                  >
                    {
                      (
                        whichSubtask.subtask === subtask._id && whichSubtask.isSubmitting
                      ) ?
                        <ClipLoader size={17} color="#fff" />
                        :
                        "Mark as complete"
                    }
                  </button>
                </div>
              </div>
            </div>

          )
        }) : (
          <div>No subtasks!</div>
        )
      }

    </React.Fragment>
  );
}