import React from 'react';
import { staticFilesURL } from 'config/api.js';
import { PDFViewer } from 'components/PDFViewer/PDFViewer.jsx';

export const TaskTabAttachmentsView = ({ attachments, setTask, params }) => {

  console.log("Before TaskTabAttachmentsView() rendering");

  const openWithInWebApp = ["jpeg", "jpg", "png"];

  return (
    <React.Fragment>
      {
        attachments && attachments.length > 0 ? attachments.map(
          (attachment, index) => {
            let link = "#"
            if (attachment.fileNameOnServerDisk)
              link = staticFilesURL + "/employees/" + attachment.fileNameOnServerDisk;

            const tokens = attachment.fileNameOnServerDisk.split('.');

            let content = null;

            if ("pdf".localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
              content = <PDFViewer link={link} />
            }
            else {
              for (const ext of openWithInWebApp) {
                if (ext.localeCompare(tokens[tokens.length - 1], "en", { sensitivity: 'base' }) === 0) {
                  content = <img src={link} alt="attached photograph" />
                }
              }
            }

            if (!content) {
              content = "No preview available";
            }

            return (
              <div key={index} className='flex flex-col gap-y-3'>
                <div>
                  {`${index + 1}) ${attachment.clientFileName}`}
                </div>
                <div className='flex flex-row border border-[#8E8EA1] rounded-[10px]'>
                  <div className='border border-r-[#8E8EA1] p-3 md:w-5/6'>
                    {
                      content
                    }
                  </div>
                  <div className='p-3 md:w-1/6'>
                    <a
                      key={attachment._id}
                      className='btn-primary justify-center'
                      href={link}
                      download
                    >
                      Download
                    </a>
                  </div>
                </div>
              </div>
            );
          }
        ) : (
          <div>No files attached!</div>
        )
      }

    </React.Fragment>
  );
}